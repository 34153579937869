import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import cards from "./cardapi";
import StaycationCard from "./StaycationCard";
import { Container } from "@mui/system";
import { styled } from "@mui/material/styles";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#EF7D24",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    fontFamily: "poppins",
    marginRight: theme.spacing(1),
    color: "#fff",
    backgroundColor: "#EF7D24",
    "&.Mui-selected": {
      color: "#EF7D24",
      backgroundColor: "#FFF",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#000",
      outerWidth: 2,
    },
  })
);

const uniquelist = [
  ...new Set(
    cards.map((curElem) => {
      return curElem.category;
    })
  ),
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StaycationTab() {
  const [value, setValue] = React.useState(0);
  const [CardData, setCardData] = useState([]);
  const [list, setlist] = useState(uniquelist);

  useEffect(() => {
    const updatedList = cards.filter((curElem) => {
      return curElem.category === "Dubai";
    });
    setCardData(updatedList);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filterItem = (category) => {
    const updatedList = cards.filter((curElem) => {
      return curElem.category === category;
    });

    setCardData(updatedList);
  };

  return (
    <Container fixed>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "center",
          flexGrow: 1,
          Width: { sm: 480 },
          bgcolor: "background.paper",
          mt: [0, 0, 2, 4],
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="basic tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          {list.map((curElem) => {
            return (
              <StyledTab
                onClick={() => filterItem(curElem)}
                key={curElem.id}
                label={curElem}
                {...a11yProps(0)}
                sx={{
                  bgcolor: "#EF7D24",
                  m: 2,
                  color: "#fff",
                  textTransform: "uppercase",
                }}
              />
            );
          })}
        </StyledTabs>
      </Box>
      {/* <Box sx={{ bgcolor: "#fff" }}>
        
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
           {list.map((curElem) => {
            return (
              <StyledTab
                onClick={() => filterItem(curElem)}
                key={curElem.id}
                label={curElem}
                {...a11yProps(0)}
                sx={{bgcolor: '#EF7D24',m:2, color:"#fff"}}
              />
              );
          })}
        </StyledTabs>
        <Box sx={{ p: 3 }} />
      </Box> */}

      <Box>
        <StaycationCard CardData={CardData} />
      </Box>
    </Container>
  );
}
