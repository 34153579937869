import React from "react";
import { Box, Container } from "@mui/system";
import { Typography } from "@mui/material";
import image1 from "../../../assets/home/why/why1.jpg";
import image2 from "../../../assets/home/why/why2.jpg";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import GroupsIcon from "@mui/icons-material/Groups";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const data = [
  {
    title: "24/7 Support",
    icon: (
      <SupportAgentIcon sx={{ color: "#EF7D26", fontSize: "10rem", p: 4 }} />
    ),
  },
  {
    title: "Quick Process",
    icon: <TaskAltIcon sx={{ color: "#EF7D26", fontSize: "10rem", p: 4 }} />,
  },
  {
    title: "Trained Team",
    icon: <GroupsIcon sx={{ color: "#EF7D26", fontSize: "10rem", p: 4 }} />,
  },
  {
    title: "Happy Client",
    icon: (
      <InsertEmoticonIcon sx={{ color: "#EF7D26", fontSize: "10rem", p: 4 }} />
    ),
  },
  {
    title: "Excellent Result",
    icon: <ThumbUpAltIcon sx={{ color: "#EF7D26", fontSize: "10rem", p: 4 }} />,
  },
];

function Why() {
  return (
    <>
      <Box sx={{ bgcolor: "#0C2136" }}>
        <Container sx={{ py: 8 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: ["column", "column", "row", "row"],
              //   alignItems:"center",
              gap: 8,
              py: 4,
            }}
          >
            <Box sx={{ width: ["100%", "20rem", "100rem", "100rem"] }}>
              <Typography
                variant="body1"
                component="body1"
                align="left"
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "400",
                  color: "#fff",
                  lineHeight: "3rem",
                  fontSize: "0.8rem",
                }}
              >
                ADVENTURE TRAVEL IN DUBAI - ALWAYS CHOOSE US
              </Typography>
              <Typography
                variant="h3"
                component="h3"
                align="left"
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "600",
                  color: "#fff",
                  fontSize: "2rem",
                  lineHeight: "3rem",
                }}
              >
                Why Choose Us -<br /> Heptavision Tourism?
              </Typography>
              <hr
                style={{
                  height: "4px",
                  width: "60px",
                  margin: " 10px  0",
                  backgroundColor: "#EF7D26",
                  display: "block",
                  border: "none",
                }}
              ></hr>
            </Box>
            <Box>
              <Typography
                variant="h2"
                align="left"
                sx={{
                  fontFamily: "poppins",
                  fontSize: "1rem",
                  lineHeight: "2rem",
                  color: "#f1f1f1",
                  py: 2,
                }}
              >
                We are officially registered as the Best Tours and Travel Agency
                in Dubai UAE. Our main goal is to offer you the best service and
                consultation for all kinds of visa requirements in the United
                Arab Emirates, We are your travel partner for all your tourism
                needs from visas, tours, transportation, rental cars & limo
                service to hotel reservations.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container
        sx={{
          display: "flex",
          flexDirection: ["column", "column","row","row"],
          gap: 4,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <AutoPlaySwipeableViews> */}
        {data.map((curelem) => {
          return (
            <Box
              sx={{
                mt: "-2rem",
                bgcolor: "#fff",
                width: "fit-content",
                border: "1px solid #EF7D26",
                mb:2
              }}
            >
              {curelem.icon}
              {/* <img src={image1} alt="img" width={250} /> */}
              <Typography
                variant="h2"
                align="center"
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  lineHeight: "2rem",
                  color: "#2c4058",
                  py: 2,
                }}
              >
                {curelem.title}
              </Typography>
            </Box>
          );
        })}
        {/* </AutoPlaySwipeableViews> */}
      </Container>
    </>
  );
}

export default Why;
