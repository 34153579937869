import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, CardActions } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
}));

const StaycationCard = ({ CardData }) => {
  return (
    <>
      <Box sx={{ my: 4 }}>
        <Grid
          container
          spacing={{ xs: 1, md: 3 }}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {CardData.map((curElem) => {
            const { id, image,category, title, description, price } = curElem;
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{ px: [0, 0, 4, 4], my: 2 }}
              >
                <Item>
                  <Card
                    sx={{
                      display: { md: "flex" },
                      justifyContent: "center",
                      height: { md: 350 },
                    }}
                    key={id}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: { sm: 1 / 2, md: "1/2" } }}
                      image={image}
                      alt="Img"
                    />
                    <Box
                      sx={{ display: "flex", flexDirection: "column", my: 2 }}
                    >
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography
                          component="div"
                          variant="h6"
                          fontFamily="poppins"
                          sx={{ fontWeight: "bold", mb: 2 }}
                        >
                          {title}
                        </Typography>

                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                          fontFamily="poppins"
                        >
                          {description}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box>
                            <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: "bold" }}
                              fontFamily="poppins"
                            >
                              {price}
                            </Typography>
                          </Box>
                          <Box
                            fontFamily="poppins"
                            sx={{ justifyContent: "flex-end" }}
                          >
                            <Link to={"/staycation/staycationdetails"} state={[title, category]}>
                              <Button
                                size="medium"
                                color="primary"
                                fontFamily="poppins"
                              >
                                MORE
                              </Button>
                            </Link>
                          </Box>
                        </Box>
                      </CardActions>
                    </Box>
                  </Card>
                </Item>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default StaycationCard;
