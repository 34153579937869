import React from "react";
import header from "../../../assets/about/header/about.svg";
import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";

export default function Header() {
  return (
    <>
      <Box sx={{ bgcolor: "#F0F0F4" }}>
        <Container>
          <Box
            sx={{
              height: ["30rem", "30rem", "20rem"],
              display:"flex",
              justifyContent: [
                "center",
                "center",
                "space-between",
                "space-between",
              ],
              flexDirection:["column", "column", "row", "row"],
              alignItems: "center",
              width: "100%",
              
            }}
          >
            <Box
              sx={{
                pt: [25, 25, 10, 15],
              }}
            >
              <Typography
                align="center"
                variant="h4"
                component="h2"
                color="#242F65"
                fontWeight="600"
                fontFamily={"poppins"}
                sx={{ fontSize: ["2rem", "2rem", "2.5rem", "2.5rem"] }}
              >
                About Us
              </Typography>
            </Box>
            <Box
              sx={{ width: ["10rem", "10rem", "10rem"], pt: [6, 6, 16, 16] }}
            >
              <img src={header} alt="img" width={"100%"} />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
