import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import image1 from '../../../assets/home/mustVisit/image1.jpg'
import image2 from '../../../assets/home/mustVisit/image2.jpg'
import image3 from '../../../assets/home/mustVisit/image3.jpg'
import image4 from '../../../assets/home/mustVisit/image4.jpg'
import image5 from '../../../assets/home/mustVisit/image5.jpg'
import image6 from '../../../assets/home/mustVisit/image6.jpg'
import MustVisitImage from "./homeComponent/MustVisitImage";
import axios from "axios";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>({
  root:{
    
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      width: "100%",
      height: "64%",
      bottom: 0,
      zIndex: 1,
      background: "linear-gradient(to top, #000, rgba(0,0,0,0))",
    },
  }
}))

export default function MustVisit() {
  const styles = useStyles()

const [data, setData] = React.useState([]);

React.useEffect(() => {
  (async () => {
    try {
      let response = await axios(
        `${process.env.REACT_APP_API}/api/getmustvisitdetail`,
        {
          method: "GET",
        }
      );
      let tempData = [];
      for (let item of response.data.data) {
        tempData.push({
          ...item,
        });
      }

      setData(tempData);

    } catch (e) {
      alert("Failed to load Data" + e);
    }
  })();
}, []);

  return (
    <Box py={8}>
      <Box m={3} pl={4} pr={4}>
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "36",
            fontFamily:"poppins"
          }}
          variant="h3"
          component="h2"
          align="center"
          color="rgba(0, 0, 0, 0.87);"
        >
          Must Visit Destinations
          <hr
            style={{
              height: "4px",
              width: "55px",
              margin: " 8px auto 0",
              backgroundColor: "#ff3366",
              display: "block",
              border:"none"
            }}
          ></hr>
        </Typography>
      </Box>

      <Box
        sx={{
          margin: "3rem",
          display: {
            xs: "none",
            sm: "flex",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
          justifyContent: "center",
        }}
      >
        <ImageList  sx={{ width: "80%" }} rows={2} cols={5} rowHeight={200}>
          {itemData.map((item) => (
            <MustVisitImage  data={item} forMobile={false}/>
          ))}
        </ImageList>
      </Box>
      {/* For Mobile Devices */}
      <Box
        sx={{
          margin: "2rem",
          display: { xs: "block", sm: "none" },
        }}
      >
        <ImageList
          sx={{
            width: "100%",
            height: "100%",
            display: { xs: "block", sm: "none" },
          }}
          cols={1}
          rowHeight={400}
        >
          {itemData.map((item) => (
            <MustVisitImage data={item} forMobile={true}/>
          ))}
        </ImageList>
      </Box>
    </Box>
  );
}

const itemData = [
  {
    img: image1,
    title: "Desert Safari",
    rows: 1,
    cols: 1,
  },
  {
    img: image2,
    title: "Abu Dhabi",
    rows: 2,
    cols: 2,
  },
  {
    img: image3,
    title: "UAE Attractions",
    rows: 1,
    cols: 1,
  },
  {
    img: image4,
    title: "Burj Khalifa",
    rows: 2,
    cols: 1,
  },
  {
    img: image5,
    title: "ABRA Traditional Boat",
    rows: 1,
    cols: 1,
  },

  {
    img: image6,
    title: "Dubai Frame",
    rows: 1,
    cols: 1,
  },
];
