import React from "react";
import "../../static/css/footer.css";
import { Container } from "@mui/system";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import copyright from "copyright";

export default function Footer() {
  const copyrightText = copyright("Heptavision Tourism All Right Reserved. ");

  const openFacebook = () => {
    console.log("I'm on Facebook");
    window.open(
      "https://www.facebook.com/HeptaVision-Travel-Tourism-LLC-Dubai-106318372125247/"
    );
  };

  const openInstagram = () => {
    console.log("I'm on Instagram");
    window.open("https://instagram.com/heptavisiontours?r=nametag");
  };

  const quickLinkData = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Tour Packages",
      link: "/tours",
    },
    {
      title: "VISA Services",
      link: "/visa",
    },
    {
      title: "Staycation",
      link: "/staycation",
    },
    {
      title: "About Us",
      link: "/aboutus",
    },
    {
      title: "Contact Us",
      link: "/contactus",
    },
  ];

  const mailData = [
    {
      email: "visa@heptavisiontours.com",
    },
    {
      email: "enquiry@heptavisiontours.com",
    },
    {
      email: "info@heptavisiontours.com",
    },
  ];

  return (
    <Box sx={{ bgcolor: "#24262b", py: 6 }}>
      <Container>
        <Grid container spacing={2} columns={20}>
          <Grid item xs={20} md={5} lg={5}>
            <Box
              sx={{ width: "10rem", mr: 12, bgcolor: "white", py: 2, px: 4 }}
            >
              <img sx={{ width: 2 }} src="logo.svg" alt="img" />
            </Box>
            <Box sx={{ pt: 4 }}>
              <Typography
                sx={{ color: "white", fontFamily: "poppins", fontSize: "1rem" }}
              >
                Follow Us
              </Typography>
              <hr
                style={{
                  height: "3px",
                  width: "55px",
                  margin: "8px 0",
                  backgroundColor: "#EF7D26",
                  display: "block",
                  border: "none",
                }}
              ></hr>
              <Box sx={{ display: "flex", gap: 3, my: 2 }}>
                <button
                  onClick={openFacebook}
                  style={{
                    background: "transparent",
                    color: "transparent",
                    border: "none",
                    width: "0px",
                    height: "0px",
                    margin: "8px",
                  }}
                >
                  Facebook
                  <FacebookIcon sx={{ color: "white" }} />
                </button>
                <button
                  onClick={openInstagram}
                  style={{
                    background: "transparent",
                    color: "transparent",
                    border: "none",
                    width: "0px",
                    height: "0px",
                    margin: "8px",
                  }}
                >
                  Instagram
                  <InstagramIcon sx={{ color: "white" }} />
                </button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={20} md={5} lg={3}>
            <Box>
              <Typography
                sx={{ color: "white", fontFamily: "poppins", fontSize: "1rem" }}
              >
                Quick Links
              </Typography>
              <hr
                style={{
                  height: "3px",
                  width: "55px",
                  margin: "8px 0",
                  backgroundColor: "#EF7D26",
                  display: "block",
                  border: "none",
                }}
              ></hr>
              {quickLinkData.map((data, index) => {
                return (
                  <Link
                    underline="none"
                    style={{ textDecoration: "none" }}
                    to={data.link}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "poppins",
                        fontSize: "0.9rem",
                        textDecoration: "none",
                        py: 1,
                      }}
                    >
                      {data.title}
                    </Typography>
                  </Link>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={20} md={5} lg={3}>
            <Typography
              sx={{ color: "white", fontFamily: "poppins", fontSize: "1rem" }}
            >
              Get Help
            </Typography>
            <hr
              style={{
                height: "3px",
                width: "55px",
                margin: "8px 0",
                backgroundColor: "#EF7D26",
                display: "block",
                border: "none",
              }}
            ></hr>
            <Link to={"/"} underline="none" style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "poppins",
                  fontSize: "1rem",
                  py: 1,
                }}
              >
                FAQ
              </Typography>
            </Link>
            <Link
              to={"/contactus"}
              underline="none"
              style={{ textDecoration: "none" }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "poppins",
                  fontSize: "1rem",
                  py: 1,
                }}
              >
                Contact Us
              </Typography>
            </Link>

            <Typography
              sx={{
                color: "white",
                fontFamily: "poppins",
                fontSize: "1rem",
              }}
            >
              Address : Al Fahidi St-Al Souq Al Kabeer-Dubai - S26-Building
              No.R305,Behinde Al Mussala Post Office,Near Dubai Musuem-Bur
              Dubai. - Dubai
            </Typography>
          </Grid>
          <Grid item xs={20} md={5} lg={5}>
            <Typography
              sx={{ color: "white", fontFamily: "poppins", fontSize: "1rem" }}
            >
              Mail Us
            </Typography>
            <hr
              style={{
                height: "3px",
                width: "55px",
                margin: "8px 0",
                backgroundColor: "#EF7D26",
                display: "block",
                border: "none",
              }}
            ></hr>
            {mailData.map((data, index) => {
              return (
                <a
                  key={index}
                  href={`mailto:${data.email}`}
                  style={{
                    textDecoration: "none",
                    margin: "1px !important",
                    fontFamily: "poppins",
                    fontSize: "0.9rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontFamily: "poppins",
                      fontSize: "0.9rem",
                      py: 1,
                    }}
                  >
                    {data.email.toLowerCase()}
                  </Typography>
                </a>
              );
            })}
          </Grid>
          <Grid item xs={20} md={5} lg={4}>
            <Box>
              <Typography
                sx={{ color: "white", fontFamily: "poppins", fontSize: "1rem" }}
              >
                Get Directions
              </Typography>
              <hr
                style={{
                  height: "3px",
                  width: "55px",
                  margin: "8px 0",
                  backgroundColor: "#EF7D26",
                  display: "block",
                  border: "none",
                }}
              ></hr>

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.246688026714!2d55.299418!3d25.2622857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f437a39a39275%3A0x6d4e925ff1e50e5b!2sHeptaVision%20Travel%20and%20Tourism%20LLC%20Dubai!5e0!3m2!1sen!2sus!4v1659635520812!5m2!1sen!2sus"
                width="300"
                height="300"
                style={{ border: 0, marginTop: "2rem" }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Typography
        sx={{
          color: "white",
          fontFamily: "poppins",
          fontSize: "0.8rem",
          fontWeight: "400",
          mt: 4,
          px: 4,
        }}
        align="center"
      >
        {copyrightText}
      </Typography>
    </Box>
  );
}
