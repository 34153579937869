import { React, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, ButtonBase } from "@mui/material";
import { Container } from "@mui/system";
import images from "./tourData/Packages";
import { Link } from "react-router-dom";

export default function TourPackages() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 300,
    [theme.breakpoints.down("sm")]: {
      width: "100% !important", // Overrides inline-style
      height: 300,
    },
    "&:hover, &.Mui-focusVisible": {
      zIndex: 1,
      "& .MuiImageBackdrop-root": {
        opacity: 0.15,
      },
      "& .MuiImageMarked-root": {
        opacity: 0,
      },
      "& .MuiTypography-root": {
        border: "4px solid currentColor",
      },
    },
  }));

  const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  });

  const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  }));

  const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  }));

  const ImageMarked = styled("span")(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  }));

  return (
    <>
      <Container>
        <Box
          pl={4}
          pr={4}
          sx={{
            display: {
              xs: "none",
              sm: "grid",
              md: "grid",
              lg: "grid",
              xl: "grid",
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              my: 4,
            }}
            variant="h3"
            component="h3"
            align="center"
            fontFamily={"poppins"}
            color="rgba(0, 0, 0, 0.87);"
          >
            Choose Your Tour Now
            <hr
              style={{
                height: "4px",
                width: "60px",
                margin: " 8px auto 0",
                backgroundColor: "#EF7D24",
                display: "block",
                border: 0,
              }}
            ></hr>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom={true}
            component="h2"
            align="center"
            fontFamily={"poppins"}
            m={2}
          >
            HABIBI come to Dubai!! Yes you heard right.Our tour packages will
            provide you exclusive tour experience so you never feel to back home
            ever.Our amazing package includes tallest of the super tall skyscraper
            Burj khalifa,tour of beautiful cities of UAE including Dubai ,Abu
            Dhabi.Discover the desert safari wildlife along with an electrifying
            camp journey where you can enjoy adventurous rides.Visit the largest
            indoor theme park on the planet and the first Ferrari-branded theme
            park in the world and many more attractions.We provide exclusive
            vacation packages for families, individual travelers, honeymooners or
            group travelers.
          </Typography>
        </Box>
        <Box ml="1rem" mr="1rem" sx={{ display: { xs: "block", sm: "none" } }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "2rem",
            }}
            variant="h4"
            component="h2"
            align="center"
            fontFamily={"poppins"}
            color="rgba(0, 0, 0, 0.87)"
            my={5}
          >
            Book Now at Lowest Price
            <hr
              style={{
                height: "4px",
                width: "60px",
                margin: " 8px auto 0",
                backgroundColor: "#EF7D24",
                display: "block",
                border: 0,
              }}
            ></hr>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom={true}
            component="h2"
            align="center"
            fontFamily={"poppins"}
            m={2}
          >
            HABIBI come to Dubai!! Yes you heard right.Our tour packages will
            provide you exclusive tour experience so you never feel to back home
            ever.Our amazing package includes tallest of the super tall skyscraper
            Burj khalifa,tour of beautiful cities of UAE including Dubai ,Abu
            Dhabi.Discover the desert safari wildlife along with an electrifying
            camp journey where you can enjoy adventurous rides.Visit the largest
            indoor theme park on the planet and the first Ferrari-branded theme
            park in the world and many more attractions.We provide exclusive
            vacation packages for families, individual travelers, honeymooners or
            group travelers.
          </Typography>
        </Box>
      </Container>

      <Container>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            minWidth: 300,
            width: "100%",
            marginBottom: "3rem",
            marginTop: "3rem",
          }}
        >
          {images.map((image) => (
            <ImageButton
              focusRipple
              key={image.title}
              style={{
                width: image.width,
              }}
            >
              <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <Link to="/tours/packagecards" state={image.title}>
                <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    fontFamily={"poppins"}
                    sx={{
                      position: "relative",
                      p: 4,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                    }}
                  >
                    {image.title}
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Image>
              </Link>
            </ImageButton>
          ))}
        </Box>
      </Container>
    </>
  );
}
