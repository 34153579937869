import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box, Container } from "@mui/system";
import React from "react";
import img from "../../../assets/about/mission.jpg";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import HandshakeIcon from '@mui/icons-material/Handshake';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
const data = [
  {
    icon: <HowToRegIcon sx={{ fontSize: "3.5rem", color: "#EF7D26" }} />,
    title: "Verified Tour Guide",
    info: "5000+ Local Guides Completed",
  },
  {
    icon: <HandshakeIcon sx={{ fontSize: "3.5rem", color: "#EF7D26" }} />,
    title: "Trust",
    info: "100% Trusted Tour Agency of Dubai",
  },
  {
    icon: <EmojiEmotionsIcon sx={{ fontSize: "3.5rem", color: "#EF7D26" }} />,
    title: "Experience",
    info: "10+ Years of Travel Experience",
  },
  {
    icon: <VolunteerActivismIcon sx={{ fontSize: "3.5rem", color: "#EF7D26" }} />,
    title: "Customer Satisfaction",
    info: "98% of our Travelers are Happy",
  },
];

const Experience = () => {
  return (
    <>
      <Container sx={{ py: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Box sx={{ width: "100%", height: "100%" }}>
              <img width="100%" height="100%" src={img} alt="img" />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Typography
                align="left"
                variant="h4"
                component="h2"
                color="#242F65"
                fontWeight="400"
                fontFamily={"poppins"}
                sx={{
                  fontSize: ["1.2rem", "1.2rem", "1.5rem", "1.5rem"],
                  pb: 2,
                }}
              >
                CALL OUR AGENTS TO BOOK!
              </Typography>
              <Typography
                align="left"
                variant="h4"
                component="h2"
                color="#242F65"
                fontWeight="600"
                fontFamily={"poppins"}
                sx={{ fontSize: ["2rem", "2rem", "2.5rem", "2.5rem"], pb: 2 }}
              >
                Experience The Magic Of Heptavision Tourism
              </Typography>
              <Typography
                align="left"
                variant="h4"
                component="h2"
                color="#242F65"
                fontWeight="400"
                fontFamily={"poppins"}
                sx={{
                  fontSize: ["1rem", "1rem", "1rem", "1rem"],
                  pb: 4,
                }}
              >
                Heptavision Tourism is known for providing best in class service
                for our customers. We manage various tourism related services
                such Tours, Visa Services, Packages, Transfers, Staycations, Air
                Tickets and many more.
              </Typography>
              <Box sx={{display:"flex" ,gap:2, flexWrap:"wrap", justifyContent:["center"]}}>
                {data.map((curelem) => {
                  return (
                    <>
                      <Box sx={{width:["17rem"]}}>
                        {curelem.icon}
                        <Typography
                          align="left"
                          variant="h4"
                          component="h2"
                          color="#242F65"
                          fontWeight="600"
                          fontFamily={"poppins"}
                          sx={{
                            fontSize: "1rem",
                            pb: 1,
                          }}
                        >
                          {curelem.title}
                        </Typography>
                        <Typography
                          align="left"
                          variant="h4"
                          component="h2"
                          color="#242F65"
                          fontWeight="400"
                          fontFamily={"poppins"}
                          sx={{
                            fontSize: "1rem",
                          }}
                        >
                          {curelem.info}
                        </Typography>
                      </Box>
                    </>
                  );
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Experience;
