import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Typography from "@mui/material/Typography";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import CottageIcon from "@mui/icons-material/Cottage";
import PublicIcon from "@mui/icons-material/Public";
import { Container } from "@mui/system";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    "&:hover": {
      backgroundColor: "#EEEEEE",
    },
  },
}));

const data = [
  {
    icon: <PublicIcon sx={{ fontSize: "5rem", color: "#EF7D26", p: 2 }} />,
    title: "VISA",
    body: "Specialized in UAE Visas and\n International Visa Services for \n residents of UAE.",
  },
  {
    icon: <LocationOnIcon sx={{ fontSize: "5rem", color: "#EF7D26", p: 2 }} />,
    title: "Tours",
    body: "We arrange all types of \n tours in UAE including \n sightseeing tours, desert\n safari tours, dhow cruise \ntours, and more.",
  },
  {
    icon: (
      <AirplaneTicketIcon sx={{ fontSize: "5rem", color: "#EF7D26", p: 2 }} />
    ),
    title: "Tickets",
    body: "Our experts arrange \n tickets for customers to \n travel across the globe.",
  },
  {
    icon: <CottageIcon sx={{ fontSize: "5rem", color: "#EF7D26", p: 2 }} />,
    title: "Stays",
    body: "We provide special deals \n for vacations in UAE \n Hotels and Resorts.",
  },
];
export default function Cards() {
  const styles = useStyles();
  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row", "row"],
          gap: 4,
          justifyContent: "center",
          alignItems: "center",
          pt: 16,
          pb: [2, 2, 10, 12],
        }}
      >
        {data.map((curelem) => {
          return (
            <Box
            className={styles.root}
              sx={{
                bgcolor: "#fff",
                height: 400,
                width: "fit-content",
                border: "1px solid #EF7D26",
                mb: 2,
                width: "16rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                p: 2,
              }}
            >
              {curelem.icon}
              <Typography
                variant="h2"
                align="center"
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "600",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                  color: "#2c4058",
                  py: 2,
                }}
              >
                {curelem.title}
              </Typography>
              <pre>
                <Typography
                  variant="h2"
                  align="center"
                  sx={{
                    fontFamily: "poppins",
                    fontWeight: "400",
                    fontSize: "1rem",
                    lineHeight: "2rem",
                    color: "#2c4058",
                    py: 1,
                  }}
                >
                  {curelem.body}
                </Typography>
              </pre>
            </Box>
          );
        })}
      </Container>
    </>
  );
}
