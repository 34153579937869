import React from 'react'
import HomeSlider from '../modules/components/home/HomeSlider'
import BookNow from '../modules/components/home/BookNow'
import VisaSection from '../modules/components/home/VisaSection'
import MustVisit from '../modules/components/home/MustVisit'
import Holiday from '../modules/components/home/Holiday'
import Why from '../modules/components/home/Why'
import Review from '../modules/components/home/Review'

export default function Home() {
  return (
    <>
      <HomeSlider/>
      <BookNow/>
      <VisaSection/>
      <Holiday/>
      <Why/>
      <MustVisit/>
      <Review/>
    </>
  )
}
