import { Box, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import img from "../../../assets/about/mission.jpg";

const Mission = () => {
  return (
    <>
      <Box
        style={{
          backgroundImage: `url(${img})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        
      >
        <Box sx={{ bgcolor: "rgba(0,0,0,0.6)" , py:10}}>
          <Container>
            <Typography
              variant="h2"
              align="left"
              sx={{
                fontFamily: "poppins",
                fontWeight: "600",
                fontSize: "3rem",
                lineHeight: "2rem",
                color: "#fff",
                py: 2,
              }}
            >
              Our Mission
            </Typography>
            <hr
              style={{
                height: "4px",
                width: "55px",
                margin: " 8px 0",
                backgroundColor: "#EF7D26",
                display: "block",
                border: "none",
              }}
            ></hr>
            <Typography
              variant="h2"
              align="left"
              sx={{
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "1.2rem",
                lineHeight: "2rem",
                color: "#fff",
                py: 2,
              }}
            >
              Our mission is to provide best fit travelling as well as to
              fulfill customer's vacation dreams with our experienced staff and
              quality service.
            </Typography>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Mission;
