import React from "react";
import Tour from "../../../assets/tours/tour.png";
import "../../../static/css/tours.css";
import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";

export default function Header({title}) {
  return (
    <>
      <Box sx={{ bgcolor: "#F0F0F4" }}>
        <Container>
          <Box
            sx={{
              height: ["40rem", "45rem", "30rem"],
              display: {
                xs: "block",
                sm: "block",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: ["10rem", "20rem", "20rem", "30rem"],
                pt: [15, 15, 8, 8],
                pl: [6, 6, 0, 0],
              }}
            >
              <Typography
                align="left"
                variant="h4"
                component="h2"
                color="#242F65"
                fontWeight="600"
                fontFamily={"poppins"}
                sx={{ fontSize: ["2.4rem", "2rem", "3rem", "3rem"] }}
              >
                {title}
              </Typography>
            </Box>
            <Box
              sx={{ width: ["18rem", "20rem", "22rem"], pt: [4, 6, 10, 20] }}
            >
              <img src={Tour} alt="img" width={"100%"} />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* <Box className="header-container" sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }}>

        <div className="header-image">
          <img src={image1} alt="header" />
        </div>

        <div className="tours-header-content">

          <div className="tours-header-title">
            <p>Enjoy Best Tour Packages in UAE</p>
          </div>

          <div className="tours-header-text">
            <h3>UAE tour packages are the best way to get know about UAE culture and history. If you want to discover the beautiful emirates, then book now your local tour packages. We provide exclusive vacation packages for families, individual travelers, honeymooners or group travelers.</h3>
          </div>

        </div>

      </Box>


      <Box className="header-container" sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }}>

        <div className="header-image">
          <img src={image1} alt="header" />
        </div>

        <div className="mbl-tours-header-content">

          <div className="mbl-tours-header-title">
            <p>Enjoy Best Tour Packages in UAE</p>
          </div>

          <div className="mbl-tours-header-text">
            <h3>UAE tour packages are the best way to get know about UAE culture and history. If you want to discover the beautiful emirates, then book now your local tour packages. We provide exclusive vacation packages for families, individual travelers, honeymooners or group travelers.</h3>
          </div>

        </div>

      </Box> */}
    </>
  );
}
