import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Citytour from "../../../assets/home/packages/Citytour.jpg";
import AbuDhabi from "../../../assets/home/packages/AbuDhabi.jpg";
import BurjKhalifa from "../../../assets/home/packages/BurjKhalifa.jpg";
import dessertdinner from "../../../assets/home/packages/dessertdinner.jpg";
import Helicopterride from "../../../assets/home/packages/Helicopterride.jpg";
import cruise from "../../../assets/home/packages/cruise.jpg";
import ferrariworld from "../../../assets/home/packages/ferrariworld.jpg";
import Aquarium from "../../../assets/home/packages/Aquarium.jpg";
import yatch from "../../../assets/home/packages/yatch.jpg";
import magicalgarden from "../../../assets/home/packages/magicalgarden.jpg";
import { Container } from "@mui/system";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";
import BookNowCard from "./BookNowCard";
import Grid from "@mui/material/Grid";
export default function BookNow() {
  return (
    <>
      {/* <Container>
        <Box
          m={3}
          pl={4}
          pr={4}
          sx={{
            display: {
              xs: "none",
              sm: "grid",
              md: "grid",
              lg: "grid",
              xl: "grid",
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "36",
            }}
            variant="h3"
            component="h2"
            align="center"
            color="rgba(0, 0, 0, 0.87);"
            m={3}
          >
            Book Now at Lowest Price
            <hr
              style={{
                height: "4px",
                width: "55px",
                margin: " 8px auto 0",
                backgroundColor: "#ff3366",
                display: "block",
              }}
            ></hr>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom={true}
            component="h2"
            align="center"
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo
            doloremque molestias quae iure omnis minima facilis placeat
            accusamus eum esse ea praesentium a qui, quam ipsum. Nobis nisi
            dignissimos vitae molestias ex obcaecati nihil doloribus sed.
            Perspiciatis consequatur nulla voluptate.Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Quo doloremque molestias quae iure
            omnis minima facilis placeat accusamus eum esse ea praesentium a
            qui, quam ipsum. Nobis nisi dignissimos vitae molestias ex obcaecati
            nihil doloribus sed. Perspiciatis consequatur nulla voluptate.
          </Typography>
        </Box>
        <Box
          sx={{
            p: 2,
            margin: "2rem",
            display: {
              xs: "none",
              sm: "grid",
              md: "grid",
              lg: "grid",
              xl: "grid",
            },
          }}
        >
          <ImageList sx={{ width: "100%" }} rows={2} cols={5} rowHeight={200}>
            {itemData.map((item) => (
              <ImageListItem
                key={item.img}
                rows={item.rows}
                cols={item.cols}
                sx={{ margin: "10px" }}
              >
                <img
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar title={item.price} position="top" />
                <ImageListItemBar title={item.title} fontWeight={500} />
              </ImageListItem>
            ))}
          </ImageList>
        </Box> */}

      {/* for mobile devices */}
      {/* <Box
          sx={{
            margin: "1rem",
            display: { xs: "block", sm: "none" },
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "36",
            }}
            variant="h4"
            component="h2"
            align="center"
            color="rgba(0, 0, 0, 0.87)"
            mt={5}
            mb={2}
          >
            Book Now at Lowest Price
            <hr
              style={{
                height: "4px",
                width: "55px",
                margin: " 8px auto 0",
                backgroundColor: "#ff3366",
                display: "block",
              }}
            ></hr>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom={true}
            component="h2"
            align="center"
            mb={2}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo
            doloremque molestias quae iure omnis minima facilis placeat
            accusamus eum esse ea praesentium a qui, quam ipsum. Nobis nisi
            dignissimos vitae molestias ex obcaecati nihil doloribus sed.
            Perspiciatis consequatur nulla voluptate.Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Quo doloremque molestias quae iure
            omnis minima facilis placeat accusamus eum esse ea praesentium a
            qui, quam ipsum. Nobis nisi dignissimos vitae molestias ex obcaecati
            nihil doloribus sed. Perspiciatis consequatur nulla voluptate.
          </Typography>
          <ImageList
            sx={{
              width: "100%",
              height: "100%",
              display: { xs: "block", sm: "none" },
            }}
            cols={1}
            rowHeight={400}
          >
            {itemData.map((item) => (
              <ImageListItem
                key={item.img}
                rows={item.rows}
                column={item.cols}
                sx={{ margin: "30px 0px" }}
              >
                <img
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar title={item.price} position="top" />
                <ImageListItemBar title={item.title} />
              </ImageListItem>
            ))}
          </ImageList>
        </Box> 
      </Container>*/}
      <Container>
        <Box
          m={12}
          sx={{
            display: {
              xs: "none",
              sm: "grid",
              md: "grid",
              lg: "grid",
              xl: "grid",
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "36",
            }}
            variant="h3"
            component="h2"
            align="center"
            m={3}
            fontFamily={"poppins"}
            color="#2c4058"

          >
            Book Now at Lowest Price
            <hr
              style={{
                height: "4px",
                width: "55px",
                margin: " 8px auto 0",
                backgroundColor: "#EF7D26",
                display: "block",
                border:"none"

              }}
            ></hr>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom={true}
            component="h2"
            align="center"
            fontFamily={"poppins"}
            color="#313131"

          >
            HABIBI come to Dubai!! Yes you heard right.Our tour packages will
            provide you exclusive tour experience so you never feel to back home
            ever.Our amazing package includes tallest of the super tall
            skyscraper Burj khalifa,tour of beautiful cities of UAE including
            Dubai ,Abu Dhabi.Discover the desert safari wildlife along with an
            electrifying camp journey where you can enjoy adventurous
            rides.Visit the largest indoor theme park on the planet and the
            first Ferrari-branded theme park in the world and many more
            attractions.We provide exclusive vacation packages for families,
            individual travelers, honeymooners or group travelers.
          </Typography>
        </Box>

        {/* for mobile devices*/}
        <Box
          mt={12}
          pl={1}
          pr={1}
          sx={{
            display: {
              xs: "",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "36",
            }}
            variant="h4"
            component="h2"
            align="center"
            color="#2c4058"
            mt={5}
            mb={2}
          >
            Book Now at Lowest Price
            <hr
              style={{
                height: "4px",
                width: "8rem",
                margin: " 8px auto 0",
                backgroundColor: "#EF7D26",
                display: "block",
                border:"none"
              }}
            ></hr>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom={true}
            component="h2"
            align="center"
            mb={2}
            fontFamily="poppins"
            color="#313131"
          >
            HABIBI come to Dubai!! Yes you heard right.Our tour packages will
            provide you exclusive tour experience so you never feel to back home
            ever.Our amazing package includes tallest of the super tall
            skyscraper Burj khalifa,tour of beautiful cities of UAE including
            Dubai ,Abu Dhabi.Discover the desert safari wildlife along with an
            electrifying camp journey where you can enjoy adventurous
            rides.Visit the largest indoor theme park on the planet and the
            first Ferrari-branded theme park in the world and many more
            attractions.We provide exclusive vacation packages for families,
            individual travelers, honeymooners or group travelers.
          </Typography>
        </Box>

        <Container sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <Grid container spacing={12}>
            {itemData.map((curelem) => {
              return (
                <Grid item xs={12} md={4} lg={3}>
                  <BookNowCard data={curelem} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Container>
    </>
  );
}

const itemData = [
  {
    img: Citytour,
    title: "City Tour of Dubai",
    price: "AED 89",
    rows: 1,
    cols: 1,
  },
  {
    img: AbuDhabi,
    title: "City Tour of AbuDhabi",
    rows: 1,
    cols: 1,
    price: "AED 150",
  },
  {
    img: BurjKhalifa,
    title: "BurjKhalifa Ticket",
    price: "AED 149",
    rows: 1,
    cols: 1,
  },
  {
    img: dessertdinner,
    title: "Desert Safari in Dubai",
    price: "AED 125",
    rows: 1,
    cols: 1,
  },
  {
    img: Helicopterride,
    title: "Explore Dubai with Helicopter",
    price: "AED 585",
    rows: 1,
    cols: 1,
  },
  {
    img: cruise,
    title: "Dhow Cruise",
    price: "AED 120",
    rows: 1,
    cols: 1,
  },
  {
    img: ferrariworld,
    title: "Ferrari World",
    price: "AED 260",
    rows: 1,
    cols: 1,
  },
  {
    img: Aquarium,
    title: "Dolphinarium Ticket",
    price: "AED 99",
    rows: 1,
    cols: 1,
  },
  // {
  //   img: yatch,
  //   title: "Dhow Cruise Creek",
  //   price: "AED 70",
  //   rows: 1,
  //   cols: 1,
  // },
  // {
  //   img: magicalgarden,
  //   title: "IMGWORLD",
  //   price: "AED 335",
  //   rows: 1,
  //   cols: 1,
  // },
];

{
  /* <Typography
            sx={{
              fontWeight: "700",
              fontSize: "36",
            }}
            variant="h4"
            component="h2"
            align="center"
            color="rgba(0, 0, 0, 0.87)"
            mt={5}
            mb={2}
          >
            Book Now at Lowest Price
            <hr
              style={{
                height: "4px",
                width: "55px",
                margin: " 8px auto 0",
                backgroundColor: "#ff3366",
                display: "block",
              }}
            ></hr>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom={true}
            component="h2"
            align="center"
            mb={2}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo
            doloremque molestias quae iure omnis minima facilis placeat
            accusamus eum esse ea praesentium a qui, quam ipsum. Nobis nisi
            dignissimos vitae molestias ex obcaecati nihil doloribus sed.
            Perspiciatis consequatur nulla voluptate.Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Quo doloremque molestias quae iure
            omnis minima facilis placeat accusamus eum esse ea praesentium a
            qui, quam ipsum. Nobis nisi dignissimos vitae molestias ex obcaecati
            nihil doloribus sed. Perspiciatis consequatur nulla voluptate.
          </Typography> */
}
