import React from 'react'
import Cards from '../modules/components/contactUs/Cards'
import Form from '../modules/components/contactUs/Form'
import Header from '../modules/components/contactUs/Header'
import Map from '../modules/components/contactUs/Map'

export default function ContactUs() {
  return (
    <>
      <Header/>
      <Cards/>
      <Form/>
      <Map/>
    </>
  )
}
