import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { Collapse, MenuList, Paper } from "@mui/material";
import ReactHover, { Trigger, Hover } from "react-hover";
import { makeStyles } from "@material-ui/styles";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import EmailIcon from "@mui/icons-material/Email";
import HoverButton from "./HoverButton";
import Divider from "@mui/material/Divider";
const useStyles = makeStyles(() => ({
  actionArea: {
    transition: "0.2s",
    "&:hover": {
      color: "black",
    },
    "&:focus": {
      color: "orange",
    },
  },
  menuBox: {
    marginTop: "0",
    marginLeft: "-9rem",
    transition: "0.3sec",
    backgroundColor: "white",
  },
  mobileMenu: {},
}));

const optionsCursorTrueWithMargin = {
  followCursor: false,
  // shiftX: 0,
  // shiftY: 0,
};

const pages = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Tour Packages",
    link: "/tours",
  },
  {
    title: "VISA Services",
    link: "",
    id: "visa",
  },
  {
    title: "Staycation",
    link: "/staycation",
  },
  {
    title: "About Us",
    link: "/aboutus",
  },
  {
    title: "Contact Us",
    link: "/contactus",
  },
];

const visaMenuList = [
  {
    id: 1,
    title: "UAE Visa",
  },
  {
    id: 2,
    title: "International Visa Services in Dubai, United Arab Emirates",
  },
  {
    id: 3,
    title: "Inside Country Visa Change Services In Dubai, UAE",
  },
  {
    id: 4,
    title: "Visa Change Services In Dubai",
  },
];

const AppAppBar = () => {
  const styles = useStyles();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleOpenVisaMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseVisaMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="absolute" color="transparent" sx={{ bgcolor: "white" }}>
      <Box
        sx={{
          bgcolor: "#EF7D26",
        }}
      >
        <Container
          sx={{
            py: 1,
            px: [0, 0, 5, 5],
            display: "flex",
            bgcolor: "#EF7D26",
            justifyContent: "space-between",
            flexDirection: ["column", "column", "row", "row"],
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              bgcolor: "#EF7D26",
              // justifyContent: "center",
              // alignItems: "center",
            }}
          >
            <PhoneCallbackIcon sx={{ color: "#fff", mr: 1 }} />
            <Typography
              textAlign="left"
              style={{
                color: "#fff",
                fontWeight: "400",
                fontFamily: "poppins",
                fontSize: ["1rem", "1rem", "1rem", "1rem"],
                marginBottom: ["2rem", "1rem", "1rem", "1rem"],
              }}
            >
              Call Us : 043271009, +971 52 981 9347
            </Typography>
          </Box>
          <Box
            sx={{
              display: ["flex", "flex", "flex", "flex"],
              bgcolor: "#EF7D26",
            }}
          >
            <EmailIcon sx={{ color: "#fff", mr: 1 }} />
            <Typography
              textAlign="left"
              style={{
                color: "#fff",
                fontWeight: "400",
                fontFamily: "poppins",
                fontSize: ["0.8rem", "1rem", "1rem", "1rem"],
              }}
            >
              Email : info@heptavisiontours.com
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container fixed>
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "space-between",
              py: 2,
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ width: 150, mr: 12 }}>
              <img sx={{ width: 2 }} src="logo.svg" alt="img" />
            </Box>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                mt: 2,
                boxShadow: "none",
                p: 0,
              }}
              style={{ left: 0 }}
              className={styles.mobileMenu}
            >
              <Box sx={{ width: "24rem", height: "100%", boxShadow: "none" }}>
                {pages.map((page) =>
                  page.title === "VISA Services" ? (
                    <Link
                      to={""}
                      underline="none"
                      key={page.title}
                      style={{ textDecoration: "none" }}
                    >
                      <MenuItem onClick={handleClick}>
                        <Typography
                          key={page.title}
                          textAlign="center"
                          style={{
                            color: "#333333",
                            fontWeight: "400",
                            fontFamily: "poppins",
                          }}
                          py={1}
                        >
                          {page.title}
                        </Typography>
                        {open ? (
                          <ExpandMore sx={{ color: "#333333", ml: 1 }} />
                        ) : (
                          <ChevronRightIcon sx={{ color: "#333333", ml: 1 }} />
                        )}
                      </MenuItem>
                      <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        sx={{}}
                        elevation={0}
                        onClick={handleClick}
                      >
                        {visaMenuList.map((curelem) => {
                          return (
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "#555555",
                              }}
                              to="/visa"
                              state={curelem.id}
                              onClick={handleCloseNavMenu}
                            >
                              <Typography
                                key={page.title}
                                textAlign="left"
                                sx={{
                                  color: "#333333",
                                  fontWeight: "400",
                                  fontFamily: "poppins",
                                  bgcolor: "#F6F6F6",
                                }}
                                py={1}
                                pl={2}
                                pr={10}
                                my={"0.1rem"}
                              >
                                {curelem.title}
                              </Typography>
                            </Link>
                          );
                        })}
                      </Collapse>
                    </Link>
                  ) : (
                    <Link
                      to={page.link}
                      underline="none"
                      key={page.title}
                      style={{ textDecoration: "none" }}
                      onClick={() => handleCloseNavMenu()}
                    >
                      <MenuItem>
                        <Typography
                          key={page.title}
                          textAlign="center"
                          style={{
                            color: "#444444",
                            fontWeight: "400",
                            fontFamily: "poppins",
                          }}
                          py={1}
                        >
                          {page.title}
                        </Typography>
                      </MenuItem>
                    </Link>
                  )
                )}
              </Box>
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", alignItems: "center" },
              py: 2,
              bgcolor: "white",
            }}
          >
            <Box sx={{ width: 200, justifyContent: "flex-start" }}>
              <img sx={{ width: 2 }} src="logo.svg" alt="img" />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              {pages.map((page) => (
                <Link
                  to={page.link}
                  underline="none"
                  style={{ textDecoration: "none" }}
                  key={page.title}
                >
                  {page.title === "VISA Services" ? (
                    <ReactHover options={optionsCursorTrueWithMargin}>
                      <Trigger type="trigger">
                        <HoverButton />
                      </Trigger>
                      <Hover type="hover">
                        <Box
                          // id="visamenu"
                          // open={Boolean(anchorEl)}
                          // anchorEl={anchorEl}
                          sx={{ mt: 5, borderBottom: "#000" }}
                          className={styles.menuBox}
                          elevation={0}
                        >
                          {visaMenuList.map((curelem) => {
                            return (
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#555555",
                                }}
                                to="/visa"
                                state={curelem.id}
                              >
                                <MenuItem>{curelem.title}</MenuItem>
                                <Divider/>

                              </Link>
                            );
                          })}
                        </Box>
                      </Hover>
                    </ReactHover>
                  ) : (
                    // <Button
                    //   aria-controls="visamenu"
                    //   onMouseOver={handleOpenVisaMenu}
                    //   // onMouseLeave={handleCloseVisaMenu}
                    //   sx={{
                    //     my: 2,
                    //   }}
                    // >
                    //   <Typography
                    //     className={styles.actionArea}
                    //     key={page.title}
                    //     sx={{
                    //       fontFamily: "poppins",
                    //       fontWeight: "400",
                    //       color: "gray",
                    //       mx: 1,
                    //     }}
                    //   >
                    //     {page.title}
                    //   </Typography>
                    // </Button>
                    <Button
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                      }}
                    >
                      <Typography
                        className={styles.actionArea}
                        key={page.title}
                        sx={{
                          fontFamily: "poppins",
                          fontWeight: "400",
                          color: "gray",
                          mx: 1,
                        }}
                      >
                        {page.title}
                      </Typography>
                    </Button>
                  )}
                </Link>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AppAppBar;
