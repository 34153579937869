import React from "react";
import staycation from "../../../assets/staycation/staycation.svg";
import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";

export default function Header(props) {
  return (
    <>
      <Box sx={{ bgcolor: "#F0F0F4" }}>
        <Container>
          <Box
            sx={{
              height: ["45rem", "45rem", "30rem"],
              display: {
                xs: "block",
                sm: "block",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box sx={{ pt: [20, 20, 20, 8] }}>
              <Typography
                align="center"
                variant="h2"
                color="#242F65"
                fontWeight="700"
                fontFamily="poppins"
              >
                {props.title}
              </Typography>
            </Box>
            <Box sx={{ width: "15rem", pt: 10 }}>
              <img src={staycation} alt="img" width={"100%"} />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Mobile VIew */}
    </>
  );
}
