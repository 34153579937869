import { React, useState, useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import axios from "axios";
import Grid from "@mui/material/Grid";
import img from "../../../assets/contactUs/form.svg";
export default function Form() {
  const [data, setData] = useState({});

  const handleChange = (key, value) => {
    setData((old) => {
      return { ...old, [key]: value };
    });
  };

  const sendEmail = async () => {
    try {
      let response = await axios(`${process.env.REACT_APP_API}/api/sendemail`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data,
      });
      alert("Messege Sent, Our team will contact you soon");

      // if(response.data.message === "Email sent") {
      //   setData({
      //     name: "",
      //     email: "",
      //     contact: "",
      //     emailBody: "",
      //   })
      // }
      //console.log(data)
    } catch (e) {
      alert("Failed to load Data" + e);
    }
  };

  return (
    <>
      <Box sx={{ py: 4 }}>
        <Typography
          sx={{
            fontFamily: "poppins",
            fontWeight: "700",
            fontSize: "36",
          }}
          variant="h4"
          component="h2"
          align="center"
          color="rgba(0, 0, 0, 0.87);"
        >
          Send us a message
          <hr
            style={{
              height: "4px",
              width: "55px",
              margin: " 8px auto 0",
              backgroundColor: "#EF7D26",
              display: "block",
            }}
          ></hr>
        </Typography>
        <Container sx={{ py: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={7}>
              <Box component="form" px={2}>
                <TextField
                  style={{
                    width: "80%",
                    margin: "1rem",
                    fontFamily: "poppins",
                  }}
                  label="Enter Your Name"
                  focused
                  required
                  id="name"
                  value={data.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
                <TextField
                  style={{
                    width: "80%",
                    margin: "1rem",
                    fontFamily: "poppins",
                  }}
                  label="Email"
                  focused
                  id="email"
                  value={data.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                <TextField
                  style={{
                    width: "80%",
                    margin: "1rem",
                    fontFamily: "poppins",
                  }}
                  label="Conact Number"
                  focused
                  id="contact"
                  value={data.contact}
                  onChange={(e) => handleChange("contact", e.target.value)}
                />
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={10}
                  placeholder="How can we help you?"
                  style={{
                    width: "calc(80% - 30px)",
                    margin: "1rem",
                    fontFamily: "poppins",
                    fontSize: "16px",
                    lineHeight: "20px",
                    padding: "15px",
                  }}
                  id="emailBody"
                  value={data.emailBody}
                  onChange={(e) => handleChange("emailBody", e.target.value)}
                />
                <Button
                  variant="contained"
                  // color="#EF7D26"
                  sx={{
                    width: "80%",
                    margin: "0 auto 0 1rem",
                    bgcolor: "#EF7D26",
                    fontFamily: "poppins",
                  }}
                  onClick={sendEmail}
                >
                  SUBMIT
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: 400 }}>
                  <img width={"100%"} src={img} alt="img" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
