const staycationData = [
  {
    title: "Atlantis The Palm",
    info: "Atlantis The Palm Staycation is One of the most famous hotels in Dubai that have waterpark (Atlantis The Palm Staycation) and aquarium (Lost Chamber) inside, were it is free to access those who are staying at the hotel. This accommodation has four kinds of room/suites such as Guest rooms which are king/queen room with a palm or ocean view, Imperial Club rooms, upgraded with a little luxury that have lounge access and benefits, Club suites, ideal for families and couples seeking comfort and luxury, and the Signature suites that includes the underwater suite.Atlantis The Palm Staycation",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "2 Swimming Pools",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Bar",
      }, 
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Spa and Wellness Centre",
      },    
    ],
  },
  {
    title: "Dukes The Palm",
    info: "Located at the famous Palm Jumeirah, elegance and royalty feeling. Offering amazing services and outstanding amenities, Dukes The Palm, a Royal Hideaway Hotel is committed to ensuring that your stay will be memorable. While lodging at this property, you can enjoy 24-hour room service, free Wi-Fi in all rooms, 24-hour security, taxi service, wheelchair accessibility, and many more.",
    Suntitle: "Amenities",
    image:
    "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "1 Swimming Pools",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      
      {
        item: "Tea/coffee maker in all rooms",
      },
      
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
    ],
  },
  {
    title: "Al Habtoor Polo Resort",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "2 Swimming Pools",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Tennis Court",
      },
    ],
  },

  {
    title: "Abu Dhabi",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "1 Swimming Pools",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Tennis Court",
      },
    ],
  },

  {
    title: "Al Ain",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "1 Swimming Pools",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Tennis Court",
      },
    ],
  },

  {
    title: "Ramada Beach Hotel Ajmanm",
    info: "Ramada Beach Hotel located in Ajman will offer you a perfect overlooking view. Starting up with rooms that have a balcony and offers Arabian Views to relax. This property has a private beach, on-site restaurant, fitness room, and much more.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "Swimming Pool",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Wi-Fi",
      },
    ],
  },

  {
    title: "Dukes The Palm",
    info: "Located at the famous Palm Jumeirah, elegance and royalty feeling. Offering amazing services and outstanding amenities, Dukes The Palm, a Royal Hideaway Hotel is committed to ensuring that your stay will be memorable. While lodging at this property, you can enjoy 24-hour room service, free Wi-Fi in all rooms, 24-hour security, taxi service, wheelchair accessibility, and many more.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "1 Swimming Pool",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Wi-Fi",
      },
    ],
  },

  {
    title: "Al Habtoor Polo Resort",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "1 Swimming Pool",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Tennis Court",
      },
    ],
  },

  {
    title: "Anantara The Palm",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "1 Swimming Pool",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Tennis Court",
      },
    ],
  },


  {
    title: "Fairmont Fujairah",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "2 Swimming Pools",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Kids Play",
      },
    ],
  },

  {
    title: "Fujairah Hotel & Resort",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "2 Swimming Pools",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Free Wi-Fi",
      },
    ],
  },

  {
    title: "The Ritz Carlton Ras Al Khaimah Al Wadi Desert",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "100 Villas",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Library",
      },
    ],
  },

  {
    title: "DoubleTree by Hilton Resort & Spa Marjan Island",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "Swimming Pool",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Tennis Court",
      },
    ],
  },


  {
    title: "The Dunes Camping & Safari Rak",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "Free Wi-Fi",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Free Parking",
      },
      {
        item: "Family Rooms",
      },
      {
        item: "Room Service",
      },
    ],
  },

  {
    title: "Waldorf Astoria Ras Al Khaimah",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "Palatial rooms and suites",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Meeting Room",
      },
    ],
  },


  {
    title: "BM Beach Resort",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "2 Swimming Pools",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Children Cot",
      },
    ],
  },

  {
    title: "Palma Beach Resort & Spa",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "Swimming Pool",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Free Wi-Fi",
      },
    ],
  },

  {
    title: "Barracuda Beach Resort",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "Swimming Pool",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Free Wi-Fi",
      },
    ],
  },

  {
    title: "Umm Al Quwain Beach Resort",
    info: "A luxury 5-star property that is inspired by tranquil Andalusian. Spacious polo lawns lap terracotta terraces. Located at Dubailand. Al Habtoor polo resort in Dubailand offers exclusive villas, ideal for family stays, with four bedrooms and a private pool and barbecue terrace. It offers 149 beautifully furnished deluxe rooms and suites with a view of manicured green fields and a spacious living area with a balcony and views of the polo resort fields.",
    Suntitle: "Amenities",
    image:
      "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg",
    list: [
      {
        item: "Swimming Pool",
      },
      {
        item: "Non-Smoking Rooms",
      },
      {
        item: "Very Good Fitness Centre",
      },
      {
        item: "Spa and Wellness Centre",
      },
      {
        item: "Restaurant",
      },
      {
        item: "Room Service",
      },
      {
        item: "Facilities for Disabled Guests",
      },
      {
        item: "Tea/coffee maker in all rooms",
      },
      {
        item: "Bar",
      },
      {
        item: "Fabulous Breakfast",
      },
      {
        item: "Beach Front",
      },
      {
        item: "Free Wi-Fi",
      },
    ],
  },
];

export default staycationData;
