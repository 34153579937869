const CardData = [
    {
        id: "UAE City Tours",
        title: "UAE City Tours",
        CardData: [
            {
                title: "Dubai City Tour",
                description: "Explore the Dubai enjoy this unforgettable Experience, Make your holiday special in Dubai and don’t forget to take a pictures & videos",
                price: "Price: AED 89/- Per Person"
            },
            {
                title: "Abu Dhabi City Tour",
                description: "Join our wonderful Abu Dhabi city tour to the Sheikh Zayed Grand Mosque, Heritage Village and historical places of Emirate.",
                price: "Price: AED 150/- Per Person"
            },
            {
                title: "Sharjah City Tour - Private",
                description: "Sharjah is amazing and wonderful city, Heptavision Tourism offers the best Sharjah city tour package within your budget.",
                price: "Price: AED 399 (Per Vehicle - up to 5-6 pax)"
            }
        ]
    },

    {
        id: "Desert Safari Tours",
        title: "Desert Safari Tours",
        CardData: [
            {
                title: "Dubai Desert Safari",
                description: "Heptavision Tourism gives you the chance to have the matchless desert safari package. The thrill of riding on a 4×4 Land Cruiser in Desert.",
                price: "Price: AED 125/- Per Person"
            },
            {
                title: "Evening Desert Safari",
                description: "Experience a BBQ and international food to enjoy your dinner while watching 3 different shows and this will be a night to remember.",
                price: "Price: AED 125/- Per Person"
            },
            {
                title: "Morning Desert Safari",
                description: "Morning Desert Safari Dubai, you can experience the off-road ride in a 4*4 Land Cruiser and camel ride in Red Desert of Dubai.",
                price: "Price: AED 450/- Per Vehicle ( Up to 5-6 People)"
            },
            {
                title: "VIP Desert Safari With BBQ Dinner",
                description: "VIP Desert safari in Dubai. The red dunes, standing 30 feet high make rise slamming an exciting knowledge.",
                price: "Price: AED 180/- Per Person "
            },
        ]
    },

    {
        id: "Dhow Cruise Tours",
        title: "Dhow Cruise Tours",
        CardData: [
            {
                title: "Dhow Cruise Creek",
                description: "Dubai is an ideal holiday destination for people who love to experience new and thrill experiences, and one of them is Dhow Cruise Dubai Creek.",
                price: "Price Starting @ AED 70/- Per Person"
            },
            {
                title: "Dhow Cruise Marina",
                description: "Fun in dhow cruise marina with us. We are providing the lowest dhow cruise marina packages for travelers in UAE.",
                price: "Price Starting @ AED 120/- Per Person"
            },
            {
                title: "Dubai Canal Cruise",
                description: "We at Heptavision Tourism providing the lowest dhow canal cruise packages. This tour includes 2-hour Dinner Cruise in Dubai Canal.",
                price: "Price: AED 225/- Per Person"
            },
            {
                title: "Musandam Dibba Tour",
                description: "We arrange Musandam sea safari trip packages within a reasonable price and make your trip memorable, enjoyable and hassle-free.",
                price: "Price: AED 199/- Per Person"
            },
        ]
    },


    {
        id: "Sightseeing Tours",
        title: "Sightseeing Tours",
        CardData: [
            {
                title: "6 Emirates Tour",
                description: "Heptavision Tourism gives you the opportunity to enjoy some of the best UAE attractions on a six Emirates trip.",
                price: "Price: AED 859/- Per Vehicle ( Max Up to 5 Pax) "
            },
        ]
    },

    {
        id: "Attraction Tours",
        title: "Attraction Tours",
        CardData: [
            {
                title: "Helicopter Ride Dubai",
                description: "Helicopter Ride Offers stunning view into the historic Dubai Creek, old Dubai and New Dubai in 12, 22 and 40 Minutes trip.",
                price: "Price: Starting @ AED 585/- Per Person"
            },
            {
                title: "Ekart Zabeel Dubai",
                description: "Go-karting is the most famous and world best karting track in Dubai. Dubai Kart dome provides two tracks both indoor and outdoor.",
                price: "Price: AED 75/- Per Person"
            },
        ]
    },

    {
        id: "Attraction Tickets",
        title: "Attraction Tickets",
        CardData: [
            {
                title: "IMG World Ticket",
                price: "AED 325 "
            },
            {
                title: "Dubai Mall Aquarium",
                price: "Starting @ AED 95 "
            },
            {
                title: "Ski Dubai Ticket",
                price: "AED 180"
            },
            {
                title: "Dolphinarium Tickets",
                price: "Starting @ AED 99"
            },
            {
                title: "Lego land Ticket",
                price: "AED 210"
            },
            {
                title: "Burj Khalifa Ticket",
                price: "Starting @ AED 149"
            },
            {
                title: "Wild wadi water park",
                price: "AED 160"
            },
            {
                title: "Atlantis Aquaventure & Lost Chambers",
                price: "AED 295"
            },
            {
                title: "Dubai Parks and Resorts",
                price: "AED 190"
            },
            {
                title: "Ferrari world Abu Dhabi Ticket",
                price: "Starting @ AED 260"
            },
        ]
    },

    {
        id: "Combo Tours",
        title: "Combo Tours",
        CardData: [
            {
                title: "Dubai City Tour + Burj Khalifa",
                description: "Dubai half day tour to traditional culture and cities inside old Dubai sightseeing with entrance ticket to Burj Khalifa.",
                price: "Price: AED 500/- Per Person "
            },
        ]
    },

    {
        id: "Special Tours",
        title: "Special Tours",
        CardData: [
            {
                title: "New Dubai City Tour",
                description: "Trip to modern Dubai like Jumeirah, Marina, JLT, Jebel Ali, Palm Jumeirah. New trip New City in Dubai, UAE",
                price: "Price: AED 499/- Per Person"
            },
        ]
    },
]

export default CardData;