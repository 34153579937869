import { Box } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";

const Map = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          py: 8,
          bgcolor: "#EFEFEF",
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: ["2rem", "2rem", "3rem", "3rem"],
            color: "#2c4058",
          }}
          variant="h3"
          component="h2"
          align="center"
          color="rgba(0, 0, 0, 0.87);"
          m={0}
          fontFamily={"poppins"}
        >
          Get Directions
        </Typography>
        <hr
          style={{
            height: "4px",
            width: "60px",
            margin: " 10px auto 0",
            backgroundColor: "#EF7D26",
            display: "block",
            border: "none",
          }}
        ></hr>
        <Box
          sx={{
            width: ["18rem", "18rem", "70rem", "80rem"],
            height: ["25rem"],
          }}
        >

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.246688026714!2d55.299418!3d25.2622857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f437a39a39275%3A0x6d4e925ff1e50e5b!2sHeptaVision%20Travel%20and%20Tourism%20LLC%20Dubai!5e0!3m2!1sen!2sus!4v1659635520812!5m2!1sen!2sus"
            width="100%"
            height="100%"
            style={{ border: 0, marginTop: "2rem" }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </Box>
      </Box>
    </>
  );
};

export default Map;
