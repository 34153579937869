import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

export default function CheckList({ checkList }) {
  const list = [
    {
      listItem: "Visa Application",
      icon: <DoneIcon />,
    },
    {
      listItem: "Visa Application",
      icon: <DoneIcon />,
    },
    {
      listItem: "Visa Application",
      icon: <DoneIcon />,
    },
    {
      listItem: "Visa Application",
      icon: <DoneIcon />,
    },
  ];

  console.log(checkList);

  return (
    <>
      <Typography
        color={"orange"}
        fontFamily={"poppins"}
        fontWeight={"600"}
        variant={"h5"}
        component="h2"
        py={1}
      >
        {/* Dubai sightseeing tours Itinerary */}
        {checkList.item[0].title}
      </Typography>

      <List disablePadding>
        {checkList.item[0].list.map((curelem) => {
          return (
            <ListItem sx={{ padding: "0px" }}>
              <ListItemIcon>
                <DoneIcon sx={{ fontSize: "2rem", color: "orange" }} />
              </ListItemIcon>
              <ListItemText primary={curelem.listItem} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
