import { React, useEffect, useState } from "react";
import { Box, Button, Typography, MenuItem } from "@mui/material";
import { Container } from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import ContactsIcon from "@mui/icons-material/Contacts";
import ArticleIcon from "@mui/icons-material/Article";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ListSection from "./ListSection";
import QnaSection from "./QnaSection";
import VisaCardDetailsData from "./visaData/VisaCardDetailsData";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import axios from "axios";

function VisaCardDetails({ id }) {
  const data = VisaCardDetailsData.find((e) => e.id === id);

  const [data1, setData1] = useState({});

  const handleChange = (key, value) => {
    setData1((old) => {
      return { ...old, [key]: value };
    });
  };

  const sendEmail = async () => {
    try {
      let response = await axios(`${process.env.REACT_APP_API}/api/sendemail`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data1,
      });
      alert("Messege Sent, Our team will contact you soon");

      // if(response.data.message === "Email sent") {
      //   setData({
      //     name: "",
      //     email: "",
      //     contact: "",
      //     emailBody: "",
      //   })
      // }
      //console.log(data)
    } catch (e) {
      alert("Failed to load Data" + e);
    }
  };

  const optionArray = [
    {
      value: "UAE VISA",
    },
    {
      value: "International VISA",
    },
    {
      value: "UAE Tours",
    },
    {
      value: "Staycations",
    },
    {
      value: "Holiday Packages",
    },
  ];

  const linkData = [
    {
      id: 1,
      title: "UAE Visa",
    },
    {
      id: 2,
      title: "International Visa Services in Dubai, United Arab Emirates",
    },
    {
      id: 3,
      title: "Inside Country Visa Change Services In Dubai, UAE",
    },
    {
      id: 4,
      title: "Visa Change Services In Dubai",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box sx={{ bgcolor: "#F0F0F4" }}>
        <Container>
          <Box
            sx={{
              height: ["15rem", "20rem", "17rem", "17rem"],
              display: {
                xs: "block",
                sm: "block",
                md: "flex",
                lg: "flex",
                xl: "flex",
                width: "100%",
              },
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%", pt: [15, 15, 10, 10] }}>
              <Typography
                align="start"
                variant="h3"
                color="#242F65"
                fontWeight="600"
                fontFamily="poppins"
                fontSize="1.5rem"
              >
                {data.maintitle}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={8}>
              <Typography
                align="center"
                varient="h3"
                color="#242F65"
                fontWeight="600"
                fontFamily="poppins"
                fontSize="1.3rem"
                width="100%"
                mt={4}
              >
                {data.title}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  pt: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", px: 10 }}>
                  <img src={"AbuDhabi.jpg"} alt="img" width={"100%"} />
                </Box>
              </Box>
              <Box p={4}>
                <Typography
                  align="justify"
                  color={"#555555"}
                  fontFamily={"open sans"}
                >
                  {data.info}
                </Typography>
              </Box>
              <Box p={2}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={12} lg={4}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <ContactsIcon
                        sx={{ color: "orange", fontSize: "4rem" }}
                      />
                      <Typography
                        component={"h6"}
                        align={"center"}
                        fontFamily={"poppins"}
                        fontWeight={"600"}
                        fontSize={"1.2rem"}
                        my={2}
                        color={"#333333"}
                      >
                        Contact us To Apply Your Visa
                      </Typography>
                      <Typography
                        align={"center"}
                        color={"#666666"}
                        fontFamily={"open sans"}
                        lineHeight={"2rem"}
                      >
                        Call Us or send us a call back request, we'll connect
                        you and help you out to complete visa application
                        procedures.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} lg={4}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <ArticleIcon sx={{ color: "orange", fontSize: "4rem" }} />
                      <Typography
                        component={"h6"}
                        align={"center"}
                        fontFamily={"poppins"}
                        fontWeight={"600"}
                        fontSize={"1.2rem"}
                        my={2}
                        color={"#333333"}
                      >
                        Submit Your Passport Details
                      </Typography>
                      <Typography
                        align={"center"}
                        fontFamily={"open sans"}
                        fontWeight={400}
                        color={"#666666"}
                        lineHeight={"2rem"}
                      >
                        Submit your details like Passport Copy, Photos, and
                        other documents as per Immigration requirements.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} lg={4}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <HowToRegIcon
                        sx={{ color: "orange", fontSize: "4rem" }}
                      />
                      <Typography
                        component={"h6"}
                        align={"center"}
                        fontFamily={"poppins"}
                        fontWeight={"600"}
                        fontSize={"1.2rem"}
                        my={2}
                        color={"#333333"}
                      >
                        Get Approved Visa Online Visa
                      </Typography>
                      <Typography
                        align={"center"}
                        fontFamily={"open sans"}
                        color={"#666666"}
                        lineHeight={"2rem"}
                      >
                        Once you completed the visa application and submit all
                        required documents if successful you will get your visa.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box p={2}>
                <Typography
                  color={"#222222"}
                  fontFamily={"poppins"}
                  fontWeight={"600"}
                  varient={"h2"}
                  py={2}
                >
                  {data.titleOne}
                </Typography>

                <ListSection list={data.titleOneCheckList} />

                <Typography
                  color={"#222222"}
                  fontFamily={"poppins"}
                  fontWeight={"600"}
                  varient={"h2"}
                  py={2}
                >
                  {data.titleTwo}
                </Typography>
                <ListSection list={data.titleTwoCheckList} />
                <Typography
                  color={"#222222"}
                  fontFamily={"poppins"}
                  fontWeight={"600"}
                  varient={"h2"}
                  py={2}
                >
                  {data.titleThree}
                </Typography>
                <ListSection list={data.titleThreeCheckList} />
                <Typography
                  color={"#222222"}
                  fontFamily={"poppins"}
                  fontWeight={"600"}
                  varient={"h2"}
                  py={2}
                >
                  {data.titleFour}
                </Typography>
                <Typography
                  color={"#666666"}
                  align={"justify"}
                  fontFamily={"open sans"}
                  lineHeight={"2rem"}
                >
                  {data.titleFourInfo}
                </Typography>
                <ListSection list={data.titleFourCheckList} />
                <Typography
                  color={"#666666"}
                  align={"justify"}
                  fontFamily={"open sans"}
                  lineHeight={"2rem"}
                >
                  {data.titleFourAfterInfo}
                </Typography>
                <Typography
                  color={"#222222"}
                  fontFamily={"poppins"}
                  fontWeight={"600"}
                  varient={"h2"}
                  py={2}
                >
                  
                </Typography>

                <Typography
                  color={"#222222"}
                  fontFamily={"poppins"}
                  fontWeight={"600"}
                  fontSize={"1.2rem"}
                  varient={"h2"}
                  py={2}
                >
                  {data.faqTitle}
                </Typography>
                <QnaSection list={data.faqList} />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={4}
              bgcolor={"#EFF2F5"}
              sx={{ mt: 13 }}
            >
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  gap: 2,
                  my: 4,
                  pr: 2,
                }}
              >
                <Box
                  sx={{
                    minHeight: "16rem",
                    backgroundColor: "#145398",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      pt: 2,
                    }}
                    align="center"
                    variant="h5"
                    component="h2"
                  >
                    Contact Us
                  </Typography>

                  <ListItem sx={{ padding: "0px", ml: 2, mt: 2 }}>
                    <ListItemIcon>
                      <CallIcon sx={{ fontSize: "36px", color: "orange" }} />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography="true"
                      align="left"
                      primary={"043271009"}
                      sx={{
                        fontSize: "20px",
                        color: "white",
                        fontFamily: "poppins",
                        fontWeight: "400",
                      }}
                    />
                  </ListItem>

                  <ListItem sx={{ padding: "0px", ml: 2, mt: 2 }}>
                    <ListItemIcon>
                      <WhatsAppIcon
                        sx={{ fontSize: "36px", color: "orange" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography="true"
                      align="left"
                      primary={"+971 52 981 9347"}
                      sx={{
                        fontSize: "20px",
                        color: "white",
                        fontFamily: "poppins",
                        fontWeight: "400",
                      }}
                    />
                  </ListItem>

                  <ListItem sx={{ padding: "0px", ml: 2, mt: 2 }}>
                    <ListItemIcon>
                      <MarkEmailReadIcon
                        sx={{ fontSize: "36px", color: "orange" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography="true"
                      align="left"
                      primary={"info@heptavisiontours.com"}
                      sx={{
                        fontSize: "20px",
                        color: "white",
                        fontFamily: "poppins",
                        fontWeight: "400",
                      }}
                    />
                  </ListItem>
                </Box>
                <Typography
                  align="center"
                  bgcolor="#EF7D24"
                  color="#fff"
                  fontWeight="600"
                  fontFamily="poppins"
                  fontSize="1.5rem"
                  py="1rem"
                >
                  Enquire Now
                </Typography>
                <TextField
                  value={data1.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  sx={{ bgcolor: "#fff" }}
                />
                <TextField
                  value={data1.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  sx={{ bgcolor: "#fff" }}
                />
                <TextField
                  value={data1.phone}
                  onChange={(e) => handleChange("phone", e.target.value)}
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  sx={{ bgcolor: "#fff" }}
                />
                <TextField
                  select
                  value={data1.sub}
                  onChange={(e) => handleChange("sub", e.target.value)}
                  id="outlined-basic"
                  label="Select"
                  helperText="Please select your subject"
                  variant="outlined"
                  sx={{ bgcolor: "#fff" }}
                >
                  {optionArray.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  value={data1.message}
                  onChange={(e) => handleChange("message", e.target.value)}
                  id="outlined-basic"
                  label="Message"
                  variant="outlined"
                  sx={{ bgcolor: "#fff" }}
                />
                <Box sx={{ width: "100%" }}>
                  <Button
                    variant="contained"
                    onClick={sendEmail}
                    sx={{
                      bgcolor: "#242F65",
                      width: "100%",
                      py: 1,
                      borderRadius: "0",
                    }}
                  >
                    Send Message
                  </Button>
                </Box>
                {linkData.map((item, index) => {
                  return (
                    <Link to={"/visa"} title={item.title} state={item.id}>
                      <Typography
                        sx={{
                          bgcolor: "#EF7D26",
                          width: "95%",
                          py: 1,
                          borderRadius: "0",
                          margin: "auto",
                          color: "white",
                          textDecoration: "underline",
                          fontSize: "1rem",
                          fontFamily: "poppins",
                          fontWeight: "600",
                        }}
                        variant="body2"
                        align="center"
                      >
                        {item.title}
                      </Typography>
                    </Link>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default VisaCardDetails;
