import React from 'react'
import Header from '../modules/components/Tours/Header'
import TourPackages from '../modules/components/Tours/TourPackages'

export default function Tours() {
  return (
    <>
        <Header title={"Tour Packages"}/>
        <TourPackages/>
    </>
  )
}