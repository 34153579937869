import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import staycationData from "./stayData/staycationData";
import img from "../../../assets/staycation/staycations_heptavision_tourism_atlantis_the_palm.jpg"

const StaycationInfo = ({ id }) => {
  const data = staycationData.find((e) => e.title === id);
  console.log(id);
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          pt: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%", px: 2 }}>
          <img src={img} alt="img" width={"100%"} />
        </Box>
      </Box>
      <Box px={4}>
        <Typography
          varient="h3"
          color="#242F65"
          fontWeight="600"
          fontFamily="poppins"
          fontSize="1.3rem"
          width="100%"
          pt={2}
        >
          {data.title} - Overview
        </Typography>
        <Typography
          align="justify"
          color={"#555555"}
          fontFamily={"open sans"}
          pt={2}
        >
          {data.info}
        </Typography>
      </Box>
      <Box p={4}>
        <Typography
          varient="h3"
          color="#242F65"
          fontWeight="600"
          fontFamily="poppins"
          fontSize="1.3rem"
          width="100%"
          pb={2}
        >
          Amenities
        </Typography>

        <Box sx={{ display: "flex", gap:1, flexWrap: "wrap" }}>
          {data.list.map((item) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                  width: ["20rem","20rem","13rem","13rem"],
                  p:1,
                  bgcolor:"#EF7D26",
                  borderRadius:"4px"
                }}
              >
                <DoneIcon sx={{ fontSize: "2rem", color: "#fff", mr: 1 , fontWeight:"bold" }} />
                <Typography
                  align="left"
                  color={"#fff"}
                  fontFamily={"open sans"}
                  fontSize="1.2rem"
                  fontWeight={"600"}
                >
                 {item.item}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default StaycationInfo;
