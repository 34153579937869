import { Box } from "@mui/system";
import React from "react";
import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@mui/material/Rating";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "auto",
    background: "#EFEFEF",
    borderRadius: "1rem",
    scrollbarColor: "#000",
    "&::-webkit-scrollbar ":{ width: 0 }
  },
}));

const ReviewCard = () => {

  const review = [
    {
      name: "RUSHIKESH KHARAT",
      review: "Had great time spend in dubai. Thanks ganesh sir for making my trip more enjoyable",
      date: "a week ago"
    },

    {
      name: "Pravin Bodke",
      review: "Very good Experience. Visa application processed very fast and got the visa on time. Very prompt service and helpful. Will definitely approach again whenever service required.",
      date: "a week ago"
    },

    {
      name: "Nikhil Patil",
      review: "Excellent service by Hepta Vision.! Fast reliable and very professional. I appreciate the work and wish them more success 👍🏻",
      date: "a week ago"
    },

    {
      name: "Avinash Choudhari",
      review: "Best company to rely for your travel needs, UAE visa services, these guys are gives very fast response with detail upfront guidance and help for hassel free experience.",
      date: "a month ago"
    },
  ]

  const styles = useStyles();
  return (
    <>
    {review.map((item)=>{
      return(
        <Box sx={{p:2}}>
        <Box
          className={styles.root}
          sx={{
            width: "16rem",
            height: "8rem",
            px: 4,
            py: 1,
          }}
        >
          <Typography
            sx={{
              width: "100%",
              fontWeight: "400",
              fontSize: "0.9rem",
              fontFamily: "poppins",
              py: 2,
              lineHeight:"1.5rem"
            }}
            variant="h3"
            component="h2"
            align="justify"
            color="rgba(0, 0, 0, 0.87);"
          >
            {item.review}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between",p:1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Avatar />
            <Box>
            <Typography
              sx={{
                width: "100%",
                fontWeight: "600",
                fontSize: "1rem",
                fontFamily: "poppins",
                px:2
              }}
              variant="h3"
              component="h2"
              align="justify"
              color="rgba(0, 0, 0, 0.87);"
            >
            
              {item.name}
            </Typography>
            <Typography
              sx={{
                width: "100%",
                fontWeight: "400",
                fontSize: "0.8rem",
                fontFamily: "poppins",
                px:2,
                pt:1
              }}
              variant="h3"
              component="h2"
              align="justify"
              color="#999"
            >
            
              {item.date}
            </Typography>
            </Box>
          </Box>
          <Rating name="read-only" value="4.4" readOnly />
        </Box>
      </Box>
      )
    })}
    </>
  );
};

export default ReviewCard;
