import React from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import axios from "axios";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    transition: "0.2s",
    borderRadius: "1rem",
    "&hover": {
      background: "linear-gradient(to top, #fff, rgba(0,0,0,0))",
      border: "2px solid #000",
    },
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      width: "100%",
      height: "64%",
      bottom: 0,
      zIndex: 1,
      background: "linear-gradient(to top, #000, rgba(0,0,0,0))",
    },
  },
}));

export default function MustVisitImage({ data, forMobile }) {
  const styles = useStyles();

  const [image, setImage] = React.useState();

  // console.log(data)
  // React.useEffect(() => {
  //     (async () => {
  //       try {
  //         let response = await axios(
  //           `${process.env.REACT_APP_API}/api/getmustvisitimage?id=${data.id}`,
  //           {
  //             method: "GET",
  //             responseType: "blob",
  //           }
  //         );
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         setImage(url);
  //         console.log(url)
  //       } catch (e) {
  //         alert("Faiurlled to load Data" + e);
  //       }
  //     })();
  //   }, []);

  //   console.log()

  return (
    <>
      {forMobile && (
        <ImageListItem
          key={data.title}
          rows={1}
          cols={1}
          sx={{ margin: "8px" }}
          className={styles.root}
        >
          <img
            src={data.img}
            srcSet={`${data.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={data.title}
            loading="lazy"
            className={styles.root}
          />

          <ImageListItemBar
            sx={{ color: "#fff", zIndex: "999", fontFamily: "poppins" }}
            title={data.title}
            position="bottom"
          />
        </ImageListItem>
      )}
      {!forMobile && (
        <ImageListItem
          key={data.title}
          rows={data.rows}
          cols={data.cols}
          sx={{ margin: "4px" }}
          className={styles.root}
        >
          <img
            src={data.img}
            srcSet={`${data.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={data.title}
            loading="lazy"
            className={styles.root}
          />

          <ImageListItemBar
            sx={{ color: "#fff", zIndex: "999", fontFamily: "poppins" }}
            title={data.title}
            position="bottom"
          />
        </ImageListItem>
      )}
    </>
  );
}
