import { React, useEffect } from "react";
import Header from "./Header";
import VisaCard from "./VisaCard";
import { Container, Typography, Box } from "@mui/material";
import VisaSerivceData from "./visaData/VisaServicesData";

function VisaService(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const data = VisaSerivceData.find((e) => e.id === props.id);
  return (
    <>
      <Header title={data.title} />
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "2rem 4rem",
        }}
      >
        <Box sx={{ mt: 6 }}>
          <Typography
            align={"center"}
            color={"#555555"}
            fontFamily={"poppins"}
            fontWeight={"400"}
            fontSize={"1rem"}
            varient={"h2"}
          >
            {data.headingOne}
          </Typography>
          <Typography
            align={"center"}
            color={"#555555"}
            fontFamily={"poppins"}
            fontWeight={"600"}
            fontSize={"1.5rem"}
            varient={"h2"}
            py={2}
          >
            {data.headingTwo}
          </Typography>
          <Typography
            align={"center"}
            color={"#66666"}
            fontFamily={"poppins"}
            fontWeight={"400"}
            varient={"h2"}
            lineHeight={"2rem"}
          >
            {data.info}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: [
              "center",
              "center",
              "space-between",
              "space-between",
            ],
            alignItems: "center",
            mb: 12,
          }}
        >
          {data.CardData.map((curEle) => {
            return <VisaCard title={curEle.title} price={curEle.price} />;
          })}
        </Box>
      </Container>
    </>
  );
}

export default VisaService;
