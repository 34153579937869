import React from "react";
import Color from "color";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree";
import visa1 from "../../../assets/home/VisaServices/visa1.jpeg";
import visa2 from "../../../assets/home/VisaServices/visa2.jpeg";
import visa3 from "../../../assets/home/VisaServices/visa3.jpeg";
import visa4 from "../../../assets/home/VisaServices/visa4.jpeg";
import { Box, Zoom } from "@mui/material";

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
}));

const useStyles = makeStyles(() => ({
  actionArea: {
    maxWidth: 250,
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card:{
    maxWidth: 250,
    height: 300,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "0 6px 12px 0 #999"
    },
  },
  content: {
    backgroundColor: "#EF7D24",
    height: "100%",
  },
  title: {
    fontSize: "1.2rem",
    color: "#fff",
    textTransform: "uppercase",
    fontFamily: "poppins",
    textAlign: "center",
  },
  media: {
    height: "70%",
    // animation: zoom-in-zoom-out 2s ease-out infinite,
  

  },
}));

const CustomCard = ({ classes, image, title, subtitle }) => {
  const mediaStyles = useFourThreeCardMediaStyles();
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CardActionArea className={classes.actionArea}>
        <Card className={classes.card}>
          <CardMedia className={classes.media} image={image} />
          <CardContent className={classes.content}>
            <Typography className={classes.title} variant={"h2"}>
              {title}
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    </Box>
  );
};

function VisaSectionCard() {
  const styles = useStyles({ color: "#EF7D24" });

  const cardData = [
    {
      key: 1,
      img: visa1,
      title: "UAE VISA SERVICES",
    },
    {
      key: 2,
      img: visa2,
      title: "INSIDE COUNTRY VISA CHANGE",
    },
    {
      key: 3,
      img: visa3,
      title: "INTERNATIONAL VISA Services",
    },
    {
      key: 4,
      img: visa4,
      title: "SCHENGEN VISA Services",
    },
  ];

  return (
    <>
      <Grid container spacing={4} wrap={"wrap"}>
        <Grid item xs={12} sm={3} lg={3}>
          <CustomCard
            classes={styles}
            title={"UAE VISA SERVICES"}
            image={visa1}
          />
        </Grid>
        <Grid item xs={12} sm={3} lg={3}>
          <CustomCard
            classes={styles}
            title={"INSIDE COUNTRY VISA CHANGE"}
            image={visa2}
          />
        </Grid>
        <Grid item xs={12} sm={3} lg={3}>
          <CustomCard
            classes={styles}
            title={"INTERNATIONAL VISA Services"}
            image={visa3}
          />
        </Grid>
        <Grid item xs={12} sm={3} lg={3}>
          <CustomCard
            classes={styles}
            title={"SCHENGEN VISA Services"}
            image={visa4}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default VisaSectionCard;
