const cards = [
    {
        id: 1,
        image: "AbuDhabi.jpg",
        category: "Dubai",
        title: "Atlantis The Palm",
        description: "One of the most famous hotels in Dubai that have waterpark and aquarium inside, were it is free to access.",
        price: "AED 2120"
    },
    {
        id: 2,
        image: "AbuDhabi.jpg",
        title: "Dukes The Palm",
        category: "Dubai",
        description: "5* Hotel Equipped for Meetings and Weddings in the Incredible Palm Jumeirah. Book Now!",
        price: "AED 695"
    },
    {
        id: 3,
        image: "AbuDhabi.jpg",
        title: "Al Habtoor Polo Resort",
        category: "Dubai",
        description: "5* The Dubai Al Habtoor Polo Resort and Clu offers comfort and convenience whether you’re on business or holiday in Dubai.",
        price: "AED 695"
    },
    {
        id: 4,
        image: "AbuDhabi.jpg",
        title: "Anantara The Palm",
        category: "Dubai",
        description: "Anantara The Palm Dubai Resort is a luxurious 5* resort located on the crescent of the iconic Palm Jumeirah.",
        price: "AED 1260"
    },
    {
        id: 5,
        image: "AbuDhabi.jpg",
        title: "Abu Dhabi",
        category: "Abu bhabi & Al ain",
        description: "Enjoy staycations at Beaches In Abu Dhabi For An Extraordinary Experience.",
        price: "AED"
    },
    {
        id: 6,
        image: "AbuDhabi.jpg",
        title: "Al Ain",
        category: "Abu bhabi & Al ain",
        description: "Al Ain is the Garden city of UAE, an oasis town located next to the Omani town of Buraimi..",
        price: "AED"
    },
    {
        id: 7,
        image: "AbuDhabi.jpg",
        title: "Ramada Beach Hotel Ajmanm",
        category: "Ajman",
        description: "Ramada by Wyndham Beach Hotel Ajman. Located in Ajman Corniche, offering a panoramic view of the Arabian Gulf.",
        price: "AED 355"
    },
    {
        id: 8,
        image: "AbuDhabi.jpg",
        title: "Ajman Beach Hotel",
        category: "Ajman",
        description: "3-star beach hotel in Ajman – Ajman Beach Hotel. Enjoy free WiFi, free parking, and an outdoor pool.",
        price: "AED 325"
    },
    {
        id: 9,
        image: "AbuDhabi.jpg",
        title: "Fairmont Ajman",
        category: "Ajman",
        description: "Fairmont Ajman is a beachfront five star hotel beautifully situated in Ajman, With 650 feet stretch of sandy beach.",
        price: "AED 885"
    },
    {
        id: 10,
        image: "AbuDhabi.jpg",
        title: "Fairmont Fujairah",
        category: "Fujairah",
        description: "Fairmont Fujairah Beach Resort, an idyllic luxury resort in UAE, offers unbelievable service and all the essential amenities to enjoy guests.",
        price: "AED 925"
    },
    {
        id: 11,
        image: "AbuDhabi.jpg",
        title: "Fujairah Hotel & Resort",
        category: "Fujairah",
        description: "Fujairah Hotel & Resort is located in the heart of Fujairah city. Offers services including fully refundable rates with free cancellation.",
        price: "AED 500"
    },
    {
        id: 12,
        image: "AbuDhabi.jpg",
        title: "The Ritz Carlton Ras Al Khaimah Al Wadi Desert",
        category: "Rak",
        description: "Stay at this 5-star luxury hotel in Ras Al Khaimah. Enjoy free breakfast, free WiFi and free parking.",
        price: "AED 4490"
    },
    {
        id: 13,
        image: "AbuDhabi.jpg",
        title: "DoubleTree by Hilton Resort & Spa Marjan Island",
        category: "Rak",
        description: "DoubleTree by Hilton Resort & Spa Marjan Island. 5-star. Luxury Ras Al Khaimah hotel with full-service spa.",
        price: "AED 1080"
    },
    {
        id: 14,
        image: "AbuDhabi.jpg",
        title: "The Dunes Camping & Safari Rak",
        category: "Rak",
        description: "Camp with traditional design to enjoy the ever famous Overnight Desert Camping Experience in RAK",
        price: "AED 620"
    },
    {
        id: 15,
        image: "AbuDhabi.jpg",
        title: "Waldorf Astoria Ras Al Khaimah",
        category: "Rak",
        description: "The Waldorf Astoria Ras Al Khaimah is a five star hotel that is based on an Arabian palace",
        price: "AED 1710"
    },
    {
        id: 16,
        image: "AbuDhabi.jpg",
        title: "BM Beach Resort",
        category: "Rak",
        description: "4-star beach hotel in Ras Al Khaimah. Enjoy free WiFi, free parking and 3 outdoor pools.",
        price: "AED 430"
    },
    {
        id: 17,
        image: "AbuDhabi.jpg",
        title: "Palma Beach Resort & Spa",
        category: "Umm Al Quwain",
        description: "Palma Beach Resort and Spa. 3 star. Beachfront resort in Umm al Quwain with spa and restaurant",
        price: "AED 536"
    },
    {
        id: 18,
        image: "AbuDhabi.jpg",
        title: "Barracuda Beach Resort",
        category: "Umm Al Quwain",
        description: "Barracuda Beach Resort. Located just 60km from Dubai on the edge of the Umm Al Quwain lagoon",
        price: "AED 555"
    },
    {
        id: 19,
        image: "AbuDhabi.jpg",
        title: "Umm Al Quwain Beach Resort",
        category: "Umm Al Quwain",
        description: "The Beach Hotel & Resort Umm Al Quwain is your ideal staycation destination for a quiet weekend in the sun or a summer escape with family and friends.",
        price: "AED 635"
    },
];

export default cards;