import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LanguageIcon from "@mui/icons-material/Language";
import Typography from "@mui/material/Typography";
import "../../../static/css/about.css";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import CottageIcon from "@mui/icons-material/Cottage";
import image1 from "../../../assets/about/header/image1.jpg";
import Grid from "@mui/material/Grid";
import img from "../../../assets/about/mission.jpg";
import { Container } from "@mui/system";

export default function MiddleSection() {
  return (
    <>
      <Box sx={{ backgroundColor: "#eee" }}>
        <Container sx={{ py: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography
                component="h3"
                variant="h3"
                align="left"
                color="#EF7D26"
                fontWeight="600"
                fontFamily="poppins"
              >
                Memorable Outdoor Experiences
              </Typography>
              <Typography
                width="80%"
                component="h3"
                variant="body1"
                align="left"
                fontWeight="400"
                fontFamily="poppins"
                // color="#888"
                marginTop="1rem"
              >
                Heptavision Tourism is here to provide you best quality serices
                in Dubai. You can choose from our wide range of services to make
                your trip a to UAE. We plan trips for both individuals and group
                travellers. Heptavision tourism is here to provide you recliner
                experince in Dubai.
              </Typography>
              <Typography
                width="80%"
                component="h3"
                variant="body1"
                align="left"
                fontWeight="400"
                marginTop="1rem"
                fontFamily="poppins"
              ></Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <img
                src={image1}
                alt="image1"
                width="100%"
                style={{ boxShadow: "2rem 2rem 0 rgb(0 0 0 / 10%)" }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* <Box
        sx={{
          backgroundColor: "#eee",
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "flex",
            xl: "flex",
          },
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: "40%",
            paddingTop: "5rem",
            paddingBottom: "5rem",
            paddingLeft: "1rem",
            backgroundColor: "#eee",
          }}
        >
          <Typography
            component="h3"
            variant="h3"
            align="left"
            color="#EF7D26"
            fontWeight="600"
            fontFamily="poppins"
          >
            Memorable Outdoor Experiences
          </Typography>

          <Typography
            width="80%"
            component="h3"
            variant="body1"
            align="left"
            fontWeight="400"
            fontFamily="poppins"
            // color="#888"
            marginTop="1rem"
          >
            Heptavision Tourism is here to provide you best quality serices in
            Dubai. You can choose from our wide range of services to make your
            trip a to UAE. We plan trips for both individuals and group
            travellers. Heptavision tourism is here to provide you recliner
            experince in Dubai.
          </Typography>

          <Typography
            width="80%"
            component="h3"
            variant="body1"
            align="left"
            fontWeight="400"
            marginTop="1rem"
            fontFamily="poppins"
          ></Typography>
        </Paper>
        
      </Box>

      <Box
        sx={{
          backgroundColor: "#eee",
          display: {
            xs: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
            xl: "none",
          },
          flexDirection: "column",
        }}
      >
        

        <Paper
          elevation={0}
          sx={{
            width: "100%",
            paddingTop: "2rem",
            paddingBottom: "3rem",
            backgroundColor: "#eee",
          }}
        >
          <Typography
            sx={{ margin: "4px" }}
            component="h2"
            variant="h4"
            align="center"
            color="#219150"
            fontWeight="700"
          >
            Memorable Outdoor Experiences
          </Typography>

          <Typography
            component="h3"
            variant="body1"
            align="center"
            fontWeight="400"
            margin="2rem"
          >
            Heptavision Tourism is here to provide you best quality serices in
            Dubai. You can choose from our wide range of services to make your
            trip a to UAE. We plan trips for both individuals and group
            travellers. Heptavision tourism is here to provide you recliner
            experince in Dubai.
          </Typography>

          <Typography
            component="h3"
            variant="body1"
            align="center"
            fontWeight="400"
            margin="2rem"
          ></Typography>

          
        </Paper>
        <Paper
          elevation={0}
          sx={{
            paddingTop: "5rem",
            paddingBottom: "2rem",
            paddingLeft: "1rem",
            backgroundColor: "#eee",
          }}
        >
          <img
            src={image1}
            alt="image1"
            width="90%"
            style={{ boxShadow: "2rem 2rem 0 rgb(0 0 0 / 10%)" }}
          />
        </Paper>
      </Box> */}
    </>
  );
}
