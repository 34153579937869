import React from "react";
import Tour from "../../../assets/contactUs/header/header.svg";
import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";

export default function Header() {
  return (
    <>
      <Box sx={{ bgcolor: "#F0F0F4" }}>
        <Container>
          <Box
            sx={{
              height: ["30rem", "30rem", "20rem"],
              display: {
                xs: "block",
                sm: "block",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              justifyContent: ["center", "center", "space-between", "space-between"],
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: ["18rem", "20rem", "20rem", "30rem"],
                pt: [25, 25, 10, 15]
              }}
            >
              <Typography
                align="center"
                variant="h4"
                component="h2"
                color="#242F65"
                fontWeight="600"
                fontFamily={"poppins"}
                sx={{ fontSize: ["2rem", "2rem", "2.5rem", "2.5rem"] }}
              >
                Contact Us
              </Typography>
            </Box>
            <Box
              sx={{ width: ["20rem", "20rem", "25rem"], pt: [6, 6, 10, 30] }}
            >
              <img src={Tour} alt="img" width={"100%"} />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
