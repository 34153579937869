import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export default function SkeletonColor() {
  return (
    <Box
      sx={{
        bgcolor: '#121212',
        // p: 8,
        width: '100%',
        height: '100vh',
        // display: 'flex',
        // justifyContent: 'center',
      }}
    >
      <Skeleton
        animation="pulse"
        sx={{ bgcolor: 'grey.900', margin: '0 auto'}}
        variant="rectangular"
        width="100%"
        height="100vh"
      />
      
    </Box>
  );
}
