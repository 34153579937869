import Grid from "@mui/material/Grid";
import img from "../../../assets/about/mission.jpg";
import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";

const Career = () => {
  return (
    <>
      <Container sx={{py:10}}>
        <Grid container spacing={0} sx={{bgcolor:"#EF7D26"}}>
          <Grid item xs={12} md={4} lg={4}>
            <Box sx={{ width: "100%", height: "100%" }}>
              <img width="100%" height="100%" src={img} alt="img" />
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Box sx={{p:[2,6,8,8]}}>
                <Typography
                  align="left"
                  variant="h4"
                  component="h2"
                  color="#fff"
                  fontWeight="400"
                  fontFamily={"poppins"}
                  sx={{
                    fontSize: ["1.2rem"],
                    pb: 2,
                  }}
                >
                  Come Work With Us. Together At Heptavision Tourism
                </Typography>
                <Typography
                  align="left"
                  variant="h4"
                  component="h2"
                  color="#fff"
                  fontWeight="600"
                  fontFamily={"poppins"}
                  sx={{ fontSize: ["2rem", "2rem", "2.5rem", "2rem"], pb: 2 }}
                >
                  Explore a career at Heptavision Tourism. Join Our Team
                </Typography>

                <Typography
                  align="left"
                  variant="h4"
                  component="h2"
                  color="#000"
                  fontWeight="400"
                  fontFamily={"poppins"}
                  sx={{
                    fontSize: "1.2rem",
                    pt: 4,
                  }}
                >
                  Send Us updated Resume at info@heptavisiontours.com
                </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Career;
