import React from "react";
import { useLocation } from "react-router-dom";
import VisaCardDetails from "./VisaCardDetails";
import { Location } from "react-router-dom";

function VisaDetails() {
  const location = useLocation()
  
  return (
    <>
      <VisaCardDetails id={location.state}/>
    </>
  );
}

export default VisaDetails;
