import { Routes, Route } from "react-router-dom";
import "./App.css";
import WhatsAppQuery from "./modules/components/WhatsAppQuery";
import AppAppBar from "./modules/components/AppAppBar";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Tours from "./pages/Tours";
import Visa from "./pages/Visa";
import Staycation from "./pages/Staycation";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import VisaCardDetails from "./modules/components/visa/VisaCardDetails";
import VisaDetails from "./modules/components/visa/VisaDetails";
import PackageCards from "./modules/components/Tours/PackageCards";
import Footer from "./modules/components/Footer";
import TourDetails from "./modules/components/Tours/TourDetails";
import StacationCardDetails from "./modules/components/Staycations/StacationCardDetails";

const theme = createTheme({
  typography: {
    fontFamily: ["poopins", "sans-serif"].join(","),
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <AppAppBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/tours" element={<Tours />} />
          <Route path="/staycation/*" element={<Staycation />} />
          <Route path="/staycation/staycationdetails" element={<StacationCardDetails/>} />
          <Route path="/visa/*" element={<Visa />} />
          <Route path="visa/visadetails" element={<VisaDetails />} />
          <Route path="tours/packagecards" element={<PackageCards />} />
          <Route path="tours/packagecards/tourdetails" element={<TourDetails />} />
        </Routes>
        <WhatsAppQuery/>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;

/* <Home/> */
/* <AboutUs/> */
// <ContactUs />

{/* <Route path="packagecards" element={<PackageCards />} /> */}
