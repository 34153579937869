import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Typography } from "@mui/material";

function QnaSection({ list }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      {list.map((curelem) => {
        return (
          <List >
            <ListItem key={curelem.id} disablePadding onClick={() => handleClick()}>
              {open ? (
                <ExpandMore sx={{ color: "orange", mr: 2 }} />
              ) : (
                <ChevronRightIcon sx={{ color: "orange", mr: 2 }} />
              )}
              <Typography
                color={"#444444"}
                fontFamily={"poppins"}
                fontWeight={"600"}
                varient={"h2"}
                py={2}
              >
                {curelem.que}
              </Typography>
            </ListItem>
            <Collapse key={curelem.id} in={open} timeout="auto" unmountOnExit>
              <Typography
                color={"#666666"}
                align={"justify"}
                fontFamily={"open sans"}
                lineHeight={"2rem"}
                pb={2}
              >
                {curelem.ans}
              </Typography>
            </Collapse>
            <hr />
          </List>
        );
      })}
    </>
  );
}

export default QnaSection;
