import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import home1 from "../../../assets/home/homeSlider/home1.jpg";
import home2 from "../../../assets/home/homeSlider/home2.jpg";
import home3 from "../../../assets/home/homeSlider/home3.jpg";
import home4 from "../../../assets/home/homeSlider/home4.jpg";
import { makeStyles } from "@material-ui/core";
import slider1 from "../../../assets/home/packages/AbuDhabi.jpg";
import { bgcolor, Container } from "@mui/system";
import axios from "axios";
import SkeletonColor from "../Skeleton";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "UAE Tour Packages",
    imgPath: home1,
  },
  {
    label: "UAE Visa Services",
    imgPath: home2,
  },
  {
    label: "Explore the History of UAE Services",
    imgPath: home3,
  },
  {
    label: "Exclusive Tour Experience",
    imgPath: home4,
  },
];

const useStyles = makeStyles(() => ({
  sliderbg: {
    transition: "transform 1s",
  },
}));

function HomeSlider() {
  const theme = useTheme();
  const [data, setData] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = data.length;
  const styles = useStyles();
  const [imgUrl, setImgUrl] = React.useState([]);
  const [code, setCode] = React.useState(0);

  React.useEffect(() => {
    (async () => {
      try {
        let response = await axios(
          `${process.env.REACT_APP_API}/api/gethomesliderdetail`,
          {
            method: "GET",
          }
        );
        let tempData = [];
        for (let item of response.data.data) {
          tempData.push({
            ...item,
          });
        }

        setData(tempData);
        //console.log(data)
      } catch (e) {
        alert("Failed to load Data" + e);
      }
    })();
  }, []);

  // let imgUrl = [];

  React.useEffect(() => {
    (async () => {
      try {
        let tempImgData = [];
        for (let i = 0; i < 4; i++) {
          let response = await axios(
            `${process.env.REACT_APP_API}/api/gethomesliderdimage?id=${i + 1}`,
            {
              method: "GET",
              responseType: "blob",
            }
          );

          const url = window.URL.createObjectURL(new Blob([response.data]));
          tempImgData.push(url);
          setCode(response.status)
        }
        setImgUrl(tempImgData);
      } catch (e) {
        alert("Failed to load Data" + e);
      }
    })();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      {code === 200 ? (
        <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {data.map((step, index) =>
          Math.abs(activeStep - index) <= 2 ? (
            <Box
              className={styles.sliderbg}
              style={{
                backgroundImage: `url(${imgUrl[step.id - 1]})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <Box
                key={step.id}
                style={{
                  background:
                    "linear-gradient(to top,  rgba(0,0,0,0.9), rgba(0,0,0,0.3))",
                  // background:
                  //   "linear-gradient(to top,  rgba(0,0,0,1), rgba(0,0,0,0))",
                }}
                sx={{
                  pt: "6rem",
                  height: "87.5vh",
                  display: "flex",
                  justifyContent: ["start", "start", "center", "center"],
                  flexDirection: "column",
                }}
              >
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: [
                      "column",
                      "column",
                      "row-reverse",
                      "row-reverse",
                    ],
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "30",
                    }}
                  >
                    {/* <Box sx={{ width: "30rem" }}>
                      <AutoPlaySwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        sx={{}}
                      >
                        {images.map((step, index) => (
                          <div key={step.label}>
                            {Math.abs(activeStep - index) <= 2 ? (
                              <Box
                                component="img"
                                sx={{
                                  overflow: "hidden",
                                  width: "100%",
                                }}
                                src={step.imgPath}
                                alt={step.label}
                              />
                            ) : null}
                          </div>
                        ))}
                      </AutoPlaySwipeableViews>
                    </Box> */}
                  </Box>
                  <Box
                    className={styles.sliderbg}
                    sx={{
                      p: 8,
                      mt: "2rem",
                      // bgcolor: "black",
                      backgroundBlendMode: "multiply",
                    }}
                  >
                    <Box sx={{}}>
                      <Typography
                        variant="h2"
                        component="h2"
                        sx={{
                          fontWeight: "700",
                          height: 100,
                          color: "#fff",
                          fontFamily: "poppins",
                          fontSize: ["2rem", "2rem", "3rem", "4rem"],
                          // textAlign: ["center","center","justify","justify"],
                        }}
                      >
                        {data[activeStep].title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mt: ["2rem", "2rem", "6rem", "6rem"],
                        width: ["100%", "100%", "80%", "80%"],
                      }}
                    >
                      <Typography
                        component="span"
                        variant="h6"
                        sx={{
                          fontWeight: "400",
                          color: "#fff",
                          fontFamily: "poppins",
                          textAlign: ["center", "center", "justify", "justify"],
                          fontSize: "0.8rem",
                          lineHeight:["1.4rem","2rem","2rem","2rem"]
                        }}
                      >
                        {data[activeStep].description}
                      </Typography>
                    </Box>
                  </Box>
                </Container>
                <Box>
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    sx={{
                      bottom: "0px",
                      width: "100%",
                      position: "absolute",
                      zIndex: 1,
                      opacity: 1,
                      padding: "0px",
                      bgcolor: "transparent",
                    }}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                        sx={{ color: "black", fontWeight: "bold" }}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        sx={{ color: "black", fontWeight: "bold" }}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                      </Button>
                    }
                  />
                </Box>
              </Box>
            </Box>
          ) : null
        )}
      </AutoPlaySwipeableViews>
      ) : (
        <SkeletonColor/>
      )}
    </>
  );

  // return (
  //   <>
  //     <Box
  // style={{
  //   background: "linear-gradient(to right bottom, #D0F8F6, #ADDBFD)",
  // }}
  //       sx={{
  //         width: "100%",
  //         height: ["52rem", "50rem", "48rem", "48rem"],
  //         flexGrow: 1,
  //         marginTop: "0%",
  //         bgcolor: "#F0F0F4",
  //       }}
  //     >
  //       <Container>
  //         <Box
  //           sx={{
  //             width: "45%",
  //             height: 300,
  //             top: "15rem",
  //             color: "black",
  //             position: "absolute",
  //             zIndex: 1,
  //             opacity: [0.0, 0.0, 1, 1],
  //           }}
  //         >
  //           <Typography
  //             variant="h2"
  //             component="h2"
  //             sx={{
  //               fontWeight: "700",
  //               height: 100,
  //               color: "#242F65",
  //               fontFamily: "poppins",
  //             }}
  //           >
  //             {/* color: "#EF7D24"  */}
  //             {images[activeStep].label}
  //           </Typography>
  //           <Box
  //             sx={{
  //               mt: "6rem",
  //               pr: 4,
  //               width: ["2rem", "2rem", "30rem", "40rem"],
  //             }}
  //           >
  //             <Typography
  //               component="span"
  //               variant="h6"
  //               textAlign={"justify"}
  //               sx={{
  //                 fontWeight: "400",
  //                 color: "#424242",
  //                 fontFamily: "poppins",
  //                 textAlign: "justify",
  //               }}
  //             >
  //               Lorem, ipsum dolor sit amet consectetur adipisicing elit.
  //               Laboriosam, quasi molestias, provident iste dolor, culpa sunt
  //               nemo veniam ipsam eaque inventore. Amet eum facere sapiente
  //               eligendi quas accusamus, doloremque quo corporis porro nobis
  //               quia deserunt quos animi impedit iure necessitatibus!
  //             </Typography>
  //           </Box>
  //         </Box>
  // <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
  //   <Box sx={{ width: "42%" }}>
  //     <AutoPlaySwipeableViews
  //       axis={theme.direction === "rtl" ? "x-reverse" : "x"}
  //       index={activeStep}
  //       onChangeIndex={handleStepChange}
  //       enableMouseEvents
  //       sx={{ width: "50%", height: "100vh" }}
  //     >
  //       {images.map((step, index) => (
  //         <div key={step.label}>
  //           {Math.abs(activeStep - index) <= 2 ? (
  //             <Box
  //               component="img"
  //               sx={{
  //                 height: "40rem",
  //                 // display: "block",
  //                 overflow: "hidden",
  //                 width: "100%",
  //                 display: { xs: "none", sm: "block" },
  //                 mt: "4rem",
  //               }}
  //               src={step.imgPath}
  //               alt={step.label}
  //             />
  //           ) : null}
  //         </div>
  //       ))}
  //     </AutoPlaySwipeableViews>
  //   </Box>
  // </Box>
  //       </Container>

  //       {/* Mobile VIew */}
  //       {/* <AutoPlaySwipeableViews
  //         axis={theme.direction === "rtl" ? "x-reverse" : "x"}
  //         index={activeStep}
  //         onChangeIndex={handleStepChange}
  //         enableMouseEvents
  //         sx={{ width: "100%", height: "100vh" }}
  //       >
  //         {images.map((step, index) => (
  //           <div key={step.label}>
  //             {Math.abs(activeStep - index) <= 2 ? (
  //               <Box
  //                 component="img"
  //                 sx={{
  //                   height: "28rem",
  //                   pt: 2,
  //                   overflow: "hidden",
  //                   width: "100%",
  //                   display: { xs: "block", sm: "none" },
  //                 }}
  //                 src={step.imgPath}
  //                 alt={step.label}
  //               />
  //             ) : null}
  //           </div>
  //         ))}
  //       </AutoPlaySwipeableViews> */}

  //       {/* <Container>
  //         <Box
  //           sx={{
  //             // position: "absolute",
  //             zIndex: 1,
  //             bottom: "2rem",
  //             color: "black",
  //             display: { xs: "block", sm: "none" },
  //           }}
  //         >
  //           <Box>
  //             <Box sx={{ height: 70 }}>
  //               <Typography
  //                 variant="h4"
  //                 component="h2"
  //                 sx={{ fontWeight: "700", color: "#242F65" }}
  //               >
  //                 {images[activeStep].label}
  //               </Typography>
  //             </Box>
  //             <Typography
  //               variant="body1"
  //               textAlign={"justify"}
  //               sx={{ fontWeight: "400", color: "#424242", mt: "2rem" }}
  //             >
  //               Lorem, ipsum dolor sit amet consectetur adipisicing elit.
  //               Laboriosam, quasi molestias, provident iste dolor, culpa sunt
  //               nemo veniam ipsam eaque inventore. Amet eum facere sapiente
  //               eligendi quas accusamus, doloremque quo corporis porro nobis
  //               quia deserunt quos animi impedit iure necessitatibus!
  //             </Typography>
  //           </Box>
  //         </Box>
  //       </Container> */}

  // <MobileStepper
  //   steps={maxSteps}
  //   position="static"
  //   activeStep={activeStep}
  //   sx={{
  //     bottom: "0px",
  //     width: "100%",
  //     position: "absolute",
  //     zIndex: 1,
  //     opacity: 1,
  //     padding: "0px",
  //     bgcolor: "transparent",
  //   }}
  //   nextButton={
  //     <Button
  //       size="small"
  //       onClick={handleNext}
  //       disabled={activeStep === maxSteps - 1}
  //       sx={{ color: "black", fontWeight: "bold" }}
  //     >
  //       {theme.direction === "rtl" ? (
  //         <KeyboardArrowLeft />
  //       ) : (
  //         <KeyboardArrowRight />
  //       )}
  //     </Button>
  //   }
  //   backButton={
  //     <Button
  //       size="small"
  //       onClick={handleBack}
  //       disabled={activeStep === 0}
  //       sx={{ color: "black", fontWeight: "bold" }}
  //     >
  //       {theme.direction === "rtl" ? (
  //         <KeyboardArrowRight />
  //       ) : (
  //         <KeyboardArrowLeft />
  //       )}
  //     </Button>
  //   }
  // />
  //     </Box>
  //   </>
  // );
}

export default HomeSlider;
