import React from "react";
import Header from "../modules/components/Staycations/Header";
import StaycationTab from "../modules/components/Staycations/StaycationTab";

export default function Staycation() {
  return (
    <>
      <Header title={"Staycation"} />
      <StaycationTab />
    </>
  );
}
