import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

function ListSection({ list }) {
  return (
    <>
      <List disablePadding>
        {list.map((curelem) => {
          return (
            <ListItem>
              <ListItemIcon>
                <DoneIcon sx={{ fontSize: "2rem", color: "orange" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  curelem.listItem
                }
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

export default ListSection;
