import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import Container from "@mui/material/Container";
import HolidayCard from "./HolidayCard";
import image1 from "../../../assets/home/Holiday/Holiday1.svg";
import image2 from "../../../assets/home/Holiday/Holiday2.svg";
import image3 from "../../../assets/home/Holiday/Holiday3.svg";
import image4 from "../../../assets/home/Holiday/Holiday4.svg";

const cards = [
  {
    id: 1,
    title: "Apply your UAE visa",
    info: "If you are planning to visit the UAE, you may need to apply for a visa. UAE visas are required by all visitors to the UAE, except for Emiratis and nationals of GCC countries.",
    img: image1,
  },
  {
    id: 2,
    title: "Book Air Ticket",
    info: "If you're looking for cheap flights to Dubai or anywhere else, Heptavision tourism is here to help you. We have the best prices for flights. Call us today and book your ticket!",
    img: image2,
  },
  {
    id: 3,
    title: "Book Hotels",
    info: "If you are looking for some of the best hotels in the world, we can help you with the best prices. Our team will help you to choose your preferred hotel and the best price.",
    img: image3,
  },
  {
    id: 4,
    title: "Book Tours & Attractions",
    info: "Want to experience the thrill of Dubai? Book your Dubai tours and attraction tickets with Heptavision Tourism and experience the real thrill of desert city of Dubai.",
    img: image4,
  },
];

function Holiday() {
  return (
    <>
      <Box sx={{ py: 8 ,bgcolor:"#F4F6F8"}}>
        <Container>
          <Typography
           variant="h3"
           component="h2"
            align="center"
            sx={{
              fontFamily: "poppins",
              fontWeight: "600",
              color:"#2c4058"
            }}
          >
            How to Enjoy Holiday in Dubai
          </Typography>
          <hr
            style={{
              height: "4px",
              width: "60px",
              margin: " 10px auto 0",
              backgroundColor: "#EF7D26",
              display: "block",
              border: "none",
            }}
          ></hr>
          <Typography
            variant="h2"
            align="center"
            sx={{
              fontFamily: "poppins",
              fontSize: "1rem",
              py: "2rem",
              lineHeight: "2rem",
              color: "#313131",
            }}
          >
            Dubai is the one of the most popular tourist destination in the
            world. It attracts millions of people annually to enjoy their
            vacation in this city. But, before you think about your trip, you
            need to get an idea about the place and how to enjoy your holiday in
            Dubai. If you are planning to visit dubai then first of need to
            apply your visa, book return air tickets, hotel reservation and
            reserve your slot for real desert adventures like desert safari,
            Dhow Cruise, Helicopter rides, Visit Burj Khalifa etc. And if you
            want all this then just dial our number +971 43889941 and get your
            holiday in Dubai booked easily.
          </Typography>
          <Box
          sx={{
            display:"flex",
            justifyContent:["center","center","space-between","space-between"],
            flexDirection:["column","column","row","row"],
            alignItems:"center",
            // flexWrap:["wrap","wrap","none","none"],
            gap:[8,8,4,4],
            py:2
          }}>
            {cards.map((curelem) => {
              return <HolidayCard title={curelem.title} info={curelem.info} img={curelem.img} />;
            })}
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Holiday;
