const VisaCardDetailsData = [
  {
    id: "14 Days Visa",
    maintitle:
      "Apply For Dubai 14 Days Tourist Visa – Documents Required – Fees",
    title: "14 Days Visa Overview",
    info: "The country UAE has consistently been an amazing destination for travellers. The place is well known for its coastlines, pristine beaches, deserts, and the last but not the least the classy architecture. 14 days Tourist Visa is best recommended for the tourists who wish to plan a short stay in Dubai with family or with friends and for honeymoon couples or for their business tours, Meetings, Conferences or Transit stay etc. There are some requirements that must be fulfilled for obtaining the tourist visa for UAE.Nationality is also one among the reason that depends on obtaining this type of visa. Please note that GCC citizens do not require any visa to enter Dubai. Citizens of 33 countries are eligible for acquiring visa on arrival at Dubai international Airport. GCC residents who are not GCC nationals but having a high status in profession such as company managers, doctors, pharmacists or employees working in the public sector, engineers and sponsored by them are eligible for a non-renewable visa on arrival for 30 days which are at the approved ports of entry to UAE.Regardless of whether you’re a traveller for business or tourism purpose, our UAE visa administration at Heptavision Tourism handles all your stressful procedure of Dubai visa and assists you for Dubai 14 days visa category as per your travel plan.We ensure you fast approval of your Dubai visa without any inconvenience. Along with it we also help in the process of extension or renewal of Dubai visa in case if needed. This will always let you to prolong your visit at Dubai without travelling out of the country or not ever flying.",
    titleOne: "Documents necessary for applying the 14 days visa",
    titleOneInfo:
      "Processing of your Dubai visa process starts once clear scanned copies of your following documents are received: ",
    titleOneCheckList: [
      {
        listItem: "Passport size Photo",
      },
      {
        listItem: "front page of Passport",
      },
      {
        listItem: "last page of Passport",
      },
      {
        listItem:
          "if you’ve visited Dubai before, submit the Passport page with exit stamp",
      },
      {
        listItem: "Confirmed air tickets for returning",
      },
    ],
    titleTwo: "Thing to remember before applying the 14 days visa",
    titleTwoCheckList: [
      {
        listItem:
          "You must have the validity of your passport which must be of minimum 6 months.",
      },
      {
        listItem:
          "It is very much necessary that passport should not be in hand written format.",
      },
      {
        listItem:
          "The documents submitted must be precise, should be very clear not to be blurred.",
      },
      {
        listItem:
          "If you do not have above documents requirements currently then you can contact us directly so that we can have other plans for you.",
      },
    ],
    titleThree: "Key points to note",
    titleThreeCheckList: [
      {
        listItem:
          "You are free to book the return ticket and hotel with us at special prices.",
      },
      {
        listItem:
          "Indian nationality passengers don’t want to submit any guarantee documents once reviewed by our visa team.",
      },
      {
        listItem:
          "Travellers along with families need not submit any guarantee documents.",
      },
      {
        listItem:
          "Passengers who have already reserved excursions with RTT, Hotel etc. Please not that you need not  provide any guarantee documents.",
      },
    ],
    titleFour: "How to Apply Dubai visa?",
    titleFourInfo:
      "Looking for a short trip to UAE in order to catch up with your friends or family? Contact Heptavision Tourism team visa specialists who will arrange a 14-day tourist visa to Dubai that is going to make your trip a hassle-free one in a convenient manner. You need not have any tension regarding this as we provide you best service in the field of tourism.",
    titleFourCheckList: [
      {
        listItem:
          "You must fill the online visa application where you need to fill your name, contact address, nationality, travel date etc.",
      },
      {
        listItem: "Submit all the relevant documents for processing of visa.",
      },
      {
        listItem:
          "Travellers along with families need not submit any guarantee documents.",
      },
      {
        listItem:
          "You are free to use credit card to make payment. You can also opt for bank transfer, PayPal or cash deposit.",
      },
    ],
    titleFourAfterInfo:
      "Once you submit your details, our team will review the application that is submitted, and if necessary, we would get in touch with you for further details like airline ticket, guarantor’s documents, or voucher indicating hotel booking. If guarantee is not a requisite then we assure you fast processing and submission of visa. You can apply here for UAE visa with the Lowest Price Guaranteed. 14 days Dubai visa price is very much affordable. Please note that all prices are inclusive of your travel insurance.",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "Is visa a mandatory one to obtain to enter Dubai as well as UAE?",
        ans: "Visa is very much mandatory for all non-UAE citizens who travel here. Nevertheless, this is not applicable for citizens of the GCC nations, such as Bahrain, Qatar, Saudi Arabia, Kuwait, and Oman.",
      },
      {
        id: "2",
        que: "May I book the ticket before applying for visa?",
        ans: "Yes, off course you can book the tickets to Dubai before processing or applying of visa.",
      },
      {
        id: "3",
        que: "Do UAE visa allows entry and exit from UAE airport?",
        ans: "Yes, if you have valid visa then you are enable for both entry and exit from pasting all UAE’s airports.",
      },
      {
        id: "4",
        que: "How long it will take to get a visa?",
        ans: "Visa processing takes place within 3 to 4 working days. But this also depends on the prompt submission of required documents as well as the fulfilling of eligibility norms. Paper visa is given before the departure; this will help you for an unperturbed entry to UAE.",
      },
      {
        id: "5",
        que: "How much is visa application fee?",
        ans: "To inquire about your visa application fee you can directly call our travel experts.",
      },
    ],
  },


  {
    id: "30 Days Visa",
    maintitle:
      "Apply For Dubai 14 Days Tourist Visa – Documents Required – Fees",
    title: "30 Days Visa Overview",
    info: " UAE is an amazing destination for travellers. The place is  known for its coastlines, pristine beaches, deserts, and best architecture. 30 days Tourist Visa is best recommended for the tourists who wish to plan a short stay in Dubai with family or with friends and for  couples or for  business tours, Meetings, Conferences or Transit stay etc.",
    titleOne: "Documents necessary for applying the 30 days visa",
    titleOneInfo:
      "Processing of your Dubai visa process starts once clear scanned copies of your following documents are received: ",
    titleOneCheckList: [
      {
        listItem: "Passport size Photo",
      },
      {
        listItem: "Scan copy front and last page of Passport",
      },
      {
        listItem: "if you’ve visited Dubai before, submit the Passport page with exit stamp",
      },
      {
        listItem:
          "Confirmed air tickets for returning",
      },
    ],
    titleTwo: "Thing to remember before applying the 30 days visa",
    titleTwoCheckList: [
      {
        listItem:
          "You must have the validity of your passport.",
      },
      {
        listItem:
          "The documents submitted must be  very clear not to be blurred.",
      },
      {
        listItem:
          "If you do not have above documents  currently then you can contact us directly so that we can have other plans for you.",
      },
      {
        listItem:
          "Guarantor’s Documents as mentioned below(Documents that are required for Visitors with Guarantor in UAE:)",
      },
      {
        listItem:
          "Guarantor’s valid Passport copy and visa page copy .",
      },
      {
        listItem:
          "A security cheque of AED 5500 is needed for each visa; this cheque will be kept for the future and used only when the visitor is absconded.",
      },
      {
        listItem:
          "Submit the bank statement that supports the cheque drawn from the same account with all the recent transactions in it.",
      },
      {
        listItem:
          "Documents needed for Visitors who has NO Guarantor in UAE",
      },
      {
        listItem:
          "Tour booking and Airline service with us  is guaranteed for  best prices.",
      },
      {
        listItem:
          "Some visitors are needed to pay specific amount towards the Security Deposit. This  is very much important as to make sure that any visitor does not overstay and leave the country.This amount is  refundable and all the amount is reimbursed after the visitor send his/her scanned copy of passport page with the UAE Exit stamp.",
      },
    ],
    titleThree: "Key points to note",
    titleThreeCheckList: [
      {
        listItem:
          "You are free to book the return ticket and hotel with us .",
      },
      {
        listItem:
          "Indian nationality passengers don’t  submit any guarantee documents once reviewed by our visa team.",
      },
      {
        listItem:
          "Travellers with families dont need submit any guarantee documents.",
      },
    ],
    titleFour: "How to Apply Dubai visa?",
    titleFourInfo:
      " For a short trip to UAE Contact Heptavision tourism team visa specialists who will arrange a 30-day tourist visa to Dubai that is going to make your trip in a convenient manner. ",
    titleFourCheckList: [
      {
        listItem:
          "Filll the online visa application where you need to fill your name, contact address, nationality, travel date etc.",
      },
      {
        listItem: "Submit all the relevant documents for processing of visa.",
      },
      {
        listItem:
          "You are free to use credit card to make payment. You can also opt for bank transfer, PayPal or cash deposit.",
      },
    ],
    titleFourAfterInfo:
      "Once you submit your details, our team will review the application form, and , we would get in touch with you for further details like airline ticket, guarantor’s documents, or voucher indicating hotel booking. You can apply 30 days Dubai visa price is very much affordable. Please note that all prices are inclusive of your travel insurance.",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "What is the validity of a 30-day Dubai tourist visa?",
        ans: "If you have opted for a 30-days Dubai entry visa to the UAE then the validity of your visa is 58 days from the date of issue. ",
      },
      {
        id: "2",
        que: "May I book the ticket before applying for visa?",
        ans: "Yes,  you can book the tickets to Dubai before processing or applying of visa.",
      },
      {
        id: "3",
        que: "How long it will take to get a visa?",
        ans: "Visa processing takes place within 3 to 4 working days.",
      },
      {
        id: "4",
        que: "How much is visa application fee?",
        ans: "To inquire about your visa application fee you can directly call us and drop an email.",
      },
    ],
  },

  {
    id: "90 Days Visa",
    maintitle:
      "Apply For Dubai 90 Days Tourist Visa – Documents Required – Fees",
    title: "90 Days Visa Overview",
    info: "If you are visiting Dubai  for a long business tour, to attend a seminar, to visit a family friend, or just to take break up for sightseeing, you can go for 90 days visit visa for UAE which will allow your stay in the Dubai for duration of 3 months. 90 days Dubai visa price can be extended by paying AED 850 each time. This type of visa is applied for 90 days Dubai tourist visa with single-entry or multiple-entry provision.  You can apply online for a Dubai 90 days visa for an affordable rates and also have provision to get  visa in  5 working days.",
    titleOne: "Documents necessary for 90 days visa",
    titleOneInfo:
      "Processing of your Dubai visa process starts once clear scanned copies of your following documents are received: ",
    titleOneCheckList: [
      {
        listItem: "Passport size Photo",
      },
      {
        listItem: "Scan copy front and last page of Passport",
      },
      {
        listItem: "If you’ve visited Dubai before, submit the Passport page with exit stamp",
      },
      {
        listItem:
          "Confirmed air tickets for returning",
      },
    ],
    titleTwo: "Thing to remember before applying the 90 days visa",
    titleTwoCheckList: [
      {
        listItem:
          "You must have the validity of your passport .",
      },
      {
        listItem:
          "The documents submitted must be  very clear not to be blurred.",
      },
      {
        listItem:
          "If you do not have above documents  currently then you can contact us directly so that we can have other plans for you.",
      },
      {
        listItem:
          "Guarantor’s Documents as mentioned below(Documents that are required for Visitors with Guarantor in UAE:)",
      },
      {
        listItem:
          "Guarantor’s valid Passport copy and visa page copy .",
      },
      {
        listItem:
          "A security cheque of AED 5500 is needed for each visa; this cheque will be kept for the future and used only when the visitor is absconded.",
      },
      {
        listItem:
          "Submit the bank statement that supports the cheque drawn from the same account with all the recent transactions in it.",
      },
      {
        listItem:
          "Documents needed for Visitors who has NO Guarantor in UAE are mentioned below.",
      },
      {
        listItem:
          "Tour booking and Airline service with us  is guaranteed for  best prices.",
      },
      {
        listItem:
          "Some visitors are needed to pay specific amount towards the Security Deposit. This  is very much important as to make sure that any visitor does not overstay and leave the country.This amount is  refundable and all the amount is reimbursed after the visitor send his/her scanned copy of passport page with the UAE Exit stamp.",
      },
    ],
    titleThree: "Key points to note",
    titleThreeCheckList: [
      {
        listItem:
          "You are free to book the return ticket and hotel with us .",
      },
      {
        listItem:
          "Indian nationality passengers don’t  submit any guarantee documents once reviewed by our visa team.",
      },
      {
        listItem:
          "Travellers with families dont need submit any guarantee documents.",
      },
    ],
    titleFour: "How to Apply Dubai visa?",
    titleFourInfo:
      "  For a short trip to UAE Contact Heptavision tourism team visa specialists who will arrange a 90-day tourist visa to Dubai that is going to make your trip in a convenient manner. ",
    titleFourCheckList: [
      {
        listItem:
          "Filll the online visa application where you need to fill your name, contact address, nationality, travel date etc.",
      },
      {
        listItem: "Submit all the relevant documents for processing of visa.",
      },
      {
        listItem:
          "You are free to use credit card to make payment. You can also opt for bank transfer, PayPal or cash deposit.",
      },
    ],
    titleFourAfterInfo:
      "Once you submit your details, our team will review the application form, and , we would get in touch with you for further details like airline ticket, guarantor’s documents, or voucher indicating hotel booking. You can apply 90 days Dubai visa price is very much affordable. Please note that all prices are inclusive of your travel insurance.",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "What is the validity of a 90-day Dubai tourist visa?",
        ans: "If you have opted for a 90-days Dubai entry visa to the UAE then the validity of your visa is 90 days from the date of issue.",
      },
      {
        id: "2",
        que: "May I book the ticket before applying for visa?",
        ans: "Yes,  you can book the tickets to Dubai before processing or applying of visa.",
      },
      {
        id: "3",
        que: "How long it will take to get a visa?",
        ans: "Visa processing takes place within 5 working days.",
      },
      {
        id: "4",
        que: "How much is visa application fee?",
        ans: "To inquire about your visa application fee you can directly call us and drop an email.",
      },
    ],
  },


  {
    id: "30 Days Multi Entry",
    maintitle:
      "Apply For 30 Days Multiple Entry Visa – Documents Required – Fees",
    title: "30 Days Multiple Entry Visa",
    info: "30 day Multiple Entry Tourist Visa are  for those who are planning a short time stay in Dubai or multiple Trips like Meetings, Conferences or Transit stay etc. The main purpose of 30 days Multiple Entry Tourist Visa is to make your travel between UAE and other nearby places easier.",
    titleOne: "Documents necessary for 30 days multiple entry visa ",
    titleOneInfo:
      "Processing of your Dubai visa process starts once clear scanned copies of your following documents are received: ",
    titleOneCheckList: [
      {
        listItem: "Passport size Photo",
      },
      {
        listItem: "Scan copy front and last page of Passport",
      },
      {
        listItem: "If you’ve visited Dubai before, submit the Passport page with exit stamp",
      },
      {
        listItem:
          "Confirmed air tickets for returning",
      },
    ],
    titleTwo: "Thing to remember before applying the 30 days multiple entry visa",
    titleTwoCheckList: [
      {
        listItem:
          "You must have the validity of your passport .",
      },
      {
        listItem:
          "The documents submitted must be  very clear not to be blurred.",
      },
      {
        listItem:
          "If you do not have above documents  currently then you can contact us directly so that we can have other plans for you.",
      },
      {
        listItem:
          "Guarantor’s Documents as mentioned below(Documents that are required for Visitors with Guarantor in UAE:)",
      },
      {
        listItem:
          "Guarantor’s valid Passport copy and visa page copy .",
      },
      {
        listItem:
          "A security cheque of AED 5500 is needed for each visa; this cheque will be kept for the future and used only when the visitor is absconded.",
      },
      {
        listItem:
          "Submit the bank statement that supports the cheque drawn from the same account with all the recent transactions in it.",
      },
      {
        listItem:
          "Documents needed for Visitors who has NO Guarantor in UAE are mentioned below.",
      },
      {
        listItem:
          "Tour booking and Airline service with us  is guaranteed for  best prices.",
      },
      {
        listItem:
          "Some visitors are needed to pay specific amount towards the Security Deposit. This  is very much important as to make sure that any visitor does not overstay and leave the country.This amount is  refundable and all the amount is reimbursed after the visitor send his/her scanned copy of passport page with the UAE Exit stamp.",
      },
    ],
    titleThree: "Key points to note",
    titleThreeCheckList: [
      {
        listItem:
          "You are free to book the return ticket and hotel with us .",
      },
      {
        listItem:
          "Indian nationality passengers don’t  submit any guarantee documents once reviewed by our visa team.",
      },
      {
        listItem:
          "Travellers with families dont need submit any guarantee documents.",
      },
    ],
    titleFour: "How to Apply Dubai visa?",
    titleFourInfo:
      " For a short trip to UAE Contact Heptavision tourism team visa specialists who will arrange a 30 days multiple entry tourist visa to Dubai that is going to make your trip in a convenient manner.",
    titleFourCheckList: [
      {
        listItem:
          "Filll the online visa application where you need to fill your name, contact address, nationality, travel date etc.",
      },
      {
        listItem: "Submit all the relevant documents for processing of visa.",
      },
      {
        listItem:
          "You are free to use credit card to make payment. You can also opt for bank transfer, PayPal or cash deposit.",
      },
    ],
    titleFourAfterInfo:"Once you submit your details, our team will review the application form, and , we would get in touch with you for further details like airline ticket, guarantor’s documents, or voucher indicating hotel booking. You can apply 30 days  multiple entry Dubai visa price is very much affordable. Please note that all prices are inclusive of your travel insurance.",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "May I book the ticket before applying for visa?",
        ans: "Yes,  you can book the tickets to Dubai before processing or applying of visa.",
      },
      {
        id: "2",
        que: "How long it will take to get a visa?",
        ans: "Visa processing takes place within 5 working days. ",
      },
      {
        id: "3",
        que: "How much is visa application fee?",
        ans: "To inquire about your visa application fee you can directly call us and drop an email.",
      },
    ],
  },

  {
    id: "90 Days Multi Entry",
    maintitle:
      "Apply For 90 Days Multiple Entry Visa – Documents Required – Fees",
    title: "90 Days Multiple Entry Visa",
    info: "90 day Multiple Entry Tourist Visa are  for those who are planning a short time stay in Dubai or multiple Trips like Meetings, Conferences or Transit stay etc. The main purpose of 90 days Multiple Entry Tourist Visa is to make your travel between UAE and other nearby places easier.",
    titleOne: "Documents necessary for 90 days multiple entry visa ",
    titleOneInfo:
      "Processing of your Dubai visa process starts once clear scanned copies of your following documents are received: ",
    titleOneCheckList: [
      {
        listItem: "Passport size Photo",
      },
      {
        listItem: "Scan copy front and last page of Passport",
      },
      {
        listItem: "If you’ve visited Dubai before, submit the Passport page with exit stamp",
      },
      {
        listItem:
          "Confirmed air tickets for returning",
      },
    ],
    titleTwo: "Thing to remember before applying the 30 days multiple entry visa",
    titleTwoCheckList: [
      {
        listItem:
          "You must have the validity of your passport .",
      },
      {
        listItem:
          "The documents submitted must be  very clear not to be blurred.",
      },
      {
        listItem:
          "If you do not have above documents  currently then you can contact us directly so that we can have other plans for you.",
      },
      {
        listItem:
          "Guarantor’s Documents as mentioned below(Documents that are required for Visitors with Guarantor in UAE:)",
      },
      {
        listItem:
          "Guarantor’s valid Passport copy and visa page copy .",
      },
      {
        listItem:
          "A security cheque of AED 5500 is needed for each visa; this cheque will be kept for the future and used only when the visitor is absconded.",
      },
      {
        listItem:
          "Submit the bank statement that supports the cheque drawn from the same account with all the recent transactions in it.",
      },
      {
        listItem:
          "Documents needed for Visitors who has NO Guarantor in UAE are mentioned below.",
      },
      {
        listItem:
          "Tour booking and Airline service with us  is guaranteed for  best prices.",
      },
      {
        listItem:
          "Some visitors are needed to pay specific amount towards the Security Deposit. This  is very much important as to make sure that any visitor does not overstay and leave the country.This amount is  refundable and all the amount is reimbursed after the visitor send his/her scanned copy of passport page with the UAE Exit stamp.",
      },
    ],
    titleThree: "Key points to note",
    titleThreeCheckList: [
      {
        listItem:
          "You are free to book the return ticket and hotel with us .",
      },
      {
        listItem:
          "Indian nationality passengers don’t  submit any guarantee documents once reviewed by our visa team.",
      },
      {
        listItem:
          "Travellers with families dont need submit any guarantee documents.",
      },
    ],
    titleFour: "How to Apply Dubai visa?",
    titleFourInfo:
      "  For a short trip to UAE Contact Heptavision tourism team visa specialists who will arrange a 90 days multiple entry tourist visa to Dubai that is going to make your trip in a convenient manner. ",
    titleFourCheckList: [
      {
        listItem:
          "Filll the online visa application where you need to fill your name, contact address, nationality, travel date etc.",
      },
      {
        listItem: "Submit all the relevant documents for processing of visa.",
      },
      {
        listItem:
          "You are free to use credit card to make payment. You can also opt for bank transfer, PayPal or cash deposit.",
      },
    ],
    titleFourAfterInfo:"Once you submit your details, our team will review the application form, and , we would get in touch with you for further details like airline ticket, guarantor’s documents, or voucher indicating hotel booking. You can apply 90 days  multiple entry Dubai visa price is very much affordable. Please note that all prices are inclusive of your travel insurance.",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "May I book the ticket before applying for visa?",
        ans: "Yes, you can book the tickets to Dubai before processing or applying of visa.",
      },
      {
        id: "2",
        que: "How long it will take to get a visa?",
        ans: "Visa processing takes place within 5 working days. ",
      },
      {
        id: "3",
        que: "How much is visa application fee?",
        ans: "To inquire about your visa application fee you can directly call us and drop an email.",
      },
    ],
  },


  {
    id: "Inside Country VISA",
    maintitle:
      "UAE Inside country visa change ",
    title: "UAE Inside country visa change ",
    info: "UAE visa change is  hectic for  who want to change visa inside the country. This is due to the long procedures that need to be followed .Sometimes people who are on vacation with family for 30 days/90 days visa also require changing in the status of their stay at Dubai. This is the main advantage of this type of visa. ",
    titleOne: "Documents required for UAE Inside country visa (from residence to tourist visa, from a visitor who is on visa on arrival to tourist visa, Guarantor’s Document)",
    titleOneInfo:
      "Processing of your Dubai visa process starts once clear scanned copies of your following documents are received: ",
    titleOneCheckList: [
      {
        listItem: "Scanned colored passport copy front and back",
      },
      {
        listItem: "Scanned white background photo",
      },
      {
        listItem: "Last tourist visa copy",
      },
      {
        listItem: " A guarantor can be a relative or friend who is currently working/ resident in UAE who can be the point of contact in case of emergency. ",
      },

    ],
    titleTwo: "Terms & Conditions",
    titleTwoCheckList: [
      {
        listItem:
          "Payments are non-refundable.",
      },
      {
        listItem:
          "Age restrictions are applicable for specific nationalities.",
      },
      {
        listItem:
          "Visa application will be processed only after completing the document and clearing the payment.",
      },
      {
        listItem:
          "Documents submitted are not to be forged, if so strict actions will be taken against the concerned.",
      },
      {
        listItem:
          "If have overstay fines then it must be paid directly at the immigration counter.",
      },
      {
        listItem:
          "If the Tickets is once booked it cannot be cancelled or Changed and it’s non-refundable.",
      },
      {
        listItem:
          " if a person does not leave UAE within the required time then the entire fine will be paid by the guarantor/Applicant. ",
      },
    ],
    titleThree: "Advantages of inside country visa change Dubai",
    titleThreeCheckList: [
      {
        listItem:
          "You can escape from the tiresome procedure that is required in exiting the country and coming back when you can change the status without exiting the country.",
      },
      {
        listItem:
          "No need to wait in the airport for long hours.",
      },
      {
        listItem:
          "No need to depend on other places to visit.",
      },
    ],
    titleFour: "Service applicable for the following:",
    titleFourInfo:
      "",
    titleFourCheckList: [
      {
        listItem:
          "Tourist visa holders",
      },
      {
        listItem: "Resident’s visa cancelled applicants",
      },
      {
        listItem:
          "On arrival visa holders",
      },
    ],
    titleFourAfterInfo:"",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "May I book the ticket before applying for visa?",
        ans: "Yes, you can book the tickets to Dubai before processing or applying of visa.",
      },
      {
        id: "2",
        que: "How long it will take to get a visa?",
        ans: "Visa processing takes place within 5 working days. ",
      },
      {
        id: "3",
        que: "How much is visa application fee?",
        ans: "To inquire about your visa application fee you can directly call us and drop an email.",
      },
    ],
  },

  {
    id: "Singapore Tourist Visa",
    maintitle:
      "Singapore Tourist Visa",
    title: "Singapore Tourist Visa",
    info: "Singapore is one of  the most beautiful destinations located on the southern tip of Malay Peninsula. This thriving land offers an urban environment with shopping malls, parks, infrastructure etc.",
    titleOne: "Below are essential documents that are required for applying Singapore visit visa from Dubai",
    titleOneInfo:
      "",
    titleOneCheckList: [
      {
        listItem: "Valid UAE residence Visa which has got 30 days validity",
      },
      {
        listItem: "Original Passport with 6-month validity and must have at least two blank pages",
      },
      {
        listItem: "Recent Photograph of 2 with White Background",
      },
      {
        listItem: "Invitation letter is required for some countries and Invitation charges will be also deducted extra.",
      },

    ],
    titleTwo: "Supporting documents required for Singapore Visa as per the occupation of individual, type of visit:",
    titleTwoCheckList: [
      {
        listItem:
          "For employed ones – Last 6 months salary slips & Employment ID Card.",
      },
      {
        listItem:
          "For self-employed ones- Business Registration License / MOA / Partnership deed.",
      },
      {
        listItem:
          "For retired ones– Proof of retirement like pension book, statement etc.",
      },
      {
        listItem:
          "For student – school / College / Institute ID Card.",
      },
    ],
    titleThree: "For minor ones- No Objection Certificate from the parents / non-accompanying parent",
    titleThreeCheckList: [
      {
        listItem:
          "If you are visiting relative/friend – Invitation letter, inviter’s ID proof which may be a Passport or Resident Permit and their address proof like any Electricity bill, any Utility bill etc.",
      },
      {
        listItem:
          "If sponsored – Sponsorship letter, sponsor’s national ID proof like Passport, PAN card or Resident permit and updated bank statement of the last 6 months.",
      },
      {
        listItem:
          "For Business Visa – Invitation letter from Company host indicating purpose of trip, business details etc. and a covering letter from company.",
      },
    ],
    titleFour: "Special Note",
    titleFourInfo:
      "",
    titleFourCheckList: [
      {
        listItem:
          "Holiday Packages includes flight, hotel, sightseeing & transfers",
      },
      {
        listItem: "Original Passport will be kept at embassy for the purpose of stamping",
      },
      {
        listItem:
          "In case of rejection, fees like service fees are non-refundable",
      },
      {
        listItem:
          "Confirmed Return air ticket and hotel voucher is must at the airport during your visit to Singapore ",
      },
      {
        listItem:
          "including approval of visa, accommodation, sightseeing, airport transfers etc. Contact us internationalvisa@Heptavisiontourism.com or call us at +971-526661143.",
      },
    ],
    titleFourAfterInfo:"",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "Do I need travel insurance to get a Singapore Visa?",
        ans: "Travelers does not require any insurance to get a Singapore visa.",
      },
      {
        id: "2",
        que: "Do UAE residents must have a Singapore visa to enter the country?",
        ans: "Yes. any individual requires a visa to enter Singapore.",
      },
    ],
  },


  {
    id: "Schengen Visa",
    maintitle:
      "Schengen Visa",
    title: "Schengen Visa",
    info: "UAE is one of the best tourist spots for travellers all over the world. UAE residents also love to visit other countries. UAE is one of the top countries in which resident’s approach Schengen embassies for processing visas.",
    titleOne: "Documents needed by UAE citizen to enter Schengen",
    titleOneInfo:
      "",
    titleOneCheckList: [
      {
        listItem: "Travel document or UAE passport is required with a validity of 3 months beyond the stay.",
      },
      {
        listItem: "Proof for the visit is needed to be submitted.",
      },
      {
        listItem: "Evidence needs to be submitted which explains applicants' financial status to know whether they can stay or not.",
      },
      {
        listItem: "Some other documents are needed which are required by specific states.",
      },
      {
        listItem: "The Schengen area allows UAE citizens to travel without possessing a Schengen visa. UAE residents can visit Czech Republic, Austria, Belgium, Hungary, Iceland, Netherlands, Norway, Poland, Portugal, Slovakia, Spain, Switzerland etc.",
      }

    ],
    titleTwo: "Documents for Schengen visa for UAE residents",
    titleTwoCheckList: [
      {
        listItem:
          "Application form needed to be fulfilled completely with honest details. Needed to take the print twice and get signed on both of the prints at the end.",
      },
      {
        listItem:
          "Valid passport which should possess 10 years validity and needs to be issued before at least 3 months beyond the applicant who plans to leave Schengen.",
      },
      {
        listItem:
          "Two photographs which have been taken within the time period of the last three months according to the standard taken by Schengen authority.",
      },
      {
        listItem:
          "Evidence to prove that the applicant is a resident in the UAE. It must be valid for 3 months after the departure from Schengen.",
      },
      {
        listItem:
          "Travel Flight Itinerary is needed by having a document that permits applicants to reserve a flight ticket from UAE to Schengen and back to UAE which possess the correct dates to enter and leave Schengen area.",
      },
      {
        listItem:
          "Cover letter which allows knowing the need of the applicant to visit Europe by explaining which countries the applicant may wish to visit and the time they wish to spend there.",
      },
      {
        listItem:
          "Evidence of accommodation is needed in a document which proves where the applicant is getting accommodated for the entire visit in Schengen. This may be a booked hotel, rent agreement or it can be an invitation letter by the host where the applicant stays.",
      },
    ],
    titleThree: "Evidence about financial stability to know whether the applicant possesses enough money to support themselves financially till the end of your stay in Schengen. This can be in the form of any which is listed below",
    titleThreeCheckList: [
      {
        listItem:
          "As a bank account statement which shows that the applicant possesses enough bank balance in their account which is needed for the trip. The bank statement should not exceed the validity of 3 months.",
      },
      {
        listItem:
          "A sponsorship letter which explains that another person will financially support the applicant throughout the trip to Schengen. This letter should be valid if it will be submitted with the bank statement of sponsor which doesn’t exceed the time limit of 3 months.",
      },
      {
        listItem:
          "A letter of sponsorship and applicant’s bank account statement is needed.",
      },
    ],
    titleFour: "Schengen visa from Dubai costs are listed below",
    titleFourInfo:
      "",
    titleFourCheckList: [
      {
        listItem:
          "Adult : 340 AED",
      },
      {
        listItem: "Children who is between 6- 12 years :170 AED",
      },
      {
        listItem:
          "Children who is under 6 years :  Free",
      },
      {
        listItem:
          "Applicants who possess diplomatic and service passport : Free",
      },
      {
        listItem:
          "Applicant who is a family member of EU or EEA national : Free",
      },
    ],
    titleFourAfterInfo:"",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: " Is it difficult to get Schengen visa from UAE?",
        ans: "It will take 15 working days for completing the entire process and in some cases it may take more time",
      },
    ],
  },


  {
    id: "Turkey Tourist Visa",
    maintitle:
      "Turkey Tourist Visa",
    title: "Turkey Tourist Visa",
    info: "Turkey is usually visited by business professionals from the UAE, however planning a holiday trip is also the best choice to do in Turkey. There are many tourist spots available in Turkey for exploring. ",
    titleOne: "Remember to fill the application with accurate and correct details based on the website. Below listed are important details which should be filled properly as:",
    titleOneInfo:
      "",
    titleOneCheckList: [
      {
        listItem: "Choose nationality.",
      },
      {
        listItem: "Mention Travel document type.",
      },
      {
        listItem: "Choose passport number.",
      },
      {
        listItem: "Select type of visa",
      },
      {
        listItem: "Mention purpose of visit.",
      },
      {
        listItem: "Fill other entries if requested.",
      }

    ],
    titleTwo: "Submit the required documents",
    titleTwoCheckList: [
      {
        listItem:
          "Passport with a validity of six months.",
      },
      {
        listItem:
          "If the applicant had visited Turkey previously then attach the copy of the entry and exit stamps.",
      },
      {
        listItem:
          "Submit Emirates ID and residence which should be valid for a minimum of six months.",
      },
      {
        listItem:
          "Photograph with white background taken recently is required.",
      },
      {
        listItem:
          "Submit medical insurance for travel with a validity within the time period of visa.",
      },
      {
        listItem:
          "Applicant family registration certificate is needed.",
      },
      {
        listItem:
          "In case of the age group below 18 the copy of birth certificate needed to be required.",
      },
      {
        listItem:
          "Guarantee letter needed for those who are employed in Dubai. For a self employee stamped statement signed by the company letter head which explains about the purpose and time period of visit and financial details need to be submitted.",
      },
      {
        listItem:
          "Last three months bank statements are needed which should not be more than 10 days from the submission date of application.",
      },
    ],
    titleThree: "If the applicant applies for a visit visa, then the inviting person documents needed to be submitted are the following:",
    titleThreeCheckList: [
      {
        listItem:
          "Invitation petition which should possess purpose of visit, time duration, guarantee that the applicant may return to his / her home country, contact details.",
      },
      {
        listItem:
          "Submit an identity register copy which mentions complete details of family members and that of residence also.",
      },
      {
        listItem:
          "Last three months a bank statement is needed.",
      },
      {
        listItem:
          "Submit a document which details the occupation of the inviting person.",
      },
      {
        listItem:
          "If the inviting person is your spouse, then a marriage certificate needs to be attached.",
      },
    ],
    titleFour: "Application needed to be submitted",
    titleFourInfo:
      "",
    titleFourCheckList: [
      {
        listItem:
          "If the applicant is ready with all needed documents and photographs then you can submit the application along with visa fee at VFS Centre. You can take a look to know how the procedure is going by entering the reference number and last name. If the visa is approved, the applicant can receive the passport from the VFS centre itself.",
      },
      {
        listItem:
          "How to apply for a Turkey visa from Dubai, UAE ?",
      },
      {
        listItem:
          "Contact any of the Visa Specialist or expert at Heptavision Tourism on enqiury@heptavision.com ",
      },
    ],
    titleFourAfterInfo:"",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "How long an applicant needed to wait for a Turkey visit visa from Dubai? ",
        ans: "Actually, Turkey tourist / visit visa will be issued within 4 – 7 working days.",
      },
      {
        id: "1",
        que: "Is it difficult to get a Turkey visa? ",
        ans: "It is not at all difficult to get a Turkey visa ",
      },
    ],
  },


  {
    id: "France visa from UAE",
    maintitle:
      "France visa from UAE",
    title: "France visa from UAE",
    info: "Tourism is one of the important industries in France as everyone knows it is the world's topmost favorite tourist spot. France is located in the heart of western Europe which shares borders with Germany, Belgium, Spain, Switzerland, Italy, straits of UK. As a tourist destination it offers wonderful historic heritages and beautiful natural scenery which cannot be seen in any other European country. France is actually a place needed to visit atleast once in a lifetime. France holds the history with human race along the European civilisations as from prehistoric megaliths of Carnac, Provence which remains of Roman castles and cathedrals, Versailles Splendours, Eiffel Tower, modernisation of TGV.",
    titleOne: "Documents Required for France visa from Dubai",
    titleOneInfo:
      "",
    titleOneCheckList: [
      {
        listItem: "Passport which has got a minimum six-month validity with 3 blank pages and old passport if applicant has.",
      },
      {
        listItem: "Fill the application form for visa.",
      },
      {
        listItem: "Two Photographs are needed which have been taken recently.",
      },
      {
        listItem: "Personal covering letter is required.",
      },
      {
        listItem: "Bank statement with six months validity is needed.",
      },
      {
        listItem: "Returns of Income Tax about last three years.",
      },
      {
        listItem: "Prebooked return flight tickets required.",
      },
      {
        listItem: "Prebooked accommodation in hotel must be submitted.",
      },
      {
        listItem: "Travel insurance required.",
      }

    ],
    titleTwo: "Essential documents needed according to the purpose, employment of the applicant",
    titleTwoCheckList: [
      {
        listItem:
          "Employed : Leave sanctioned certificate is needed which has a company seal and an authorised signature along with 3 months salary slip.",
      },
      {
        listItem:
          "Self employed : MOA, Partnership, Business Registration License, any one among these three is a must. Last six months bank statement is needed. Along with it Last three years IT returns of company is required.",
      },
      {
        listItem:
          "Retired Person : Evidence such as pension book is needed to prove the applicant is retired.",
      },
      {
        listItem:
          "Student : ID Card of School/ College /Institute is needed. Bonafide certificate is also essential.",
      },
      {
        listItem:
          "Minor : Applicant’s birth certificate is needed. NOC from   parents is mandatory if they didn’t accompany the Minor during the visit. Hence Parents ID proof also required such as PAN card / passport.",
      },
      {
        listItem:
          "Visit a friend / relative : ID proof such as residence permit / passport of Inviter is needed whether it may be a friend or a relative. Inviters address proof is needed in the form of electricity bill or any utility bill.",
      },
      {
        listItem:
          "Sponsored : Sponsorship letter is needed with ID proof such as passport, residence permit, PAN card. Bank statement of last six months is very much required . Last three years income tax returns needed.",
      },
      {
        listItem:
          "Business visa : Invitation letter is mandatory from the company which explains the need of trip, any other business details.",
      },
      {
        listItem:
          "Last three months bank statements are needed which should not be more than 10 days from the submission date of application.",
      },
    ],
    titleThree: "How to apply France Visa from Dubai",
    titleThreeCheckList: [
      {
        listItem:
          "Contact any Specialist or expert at Heptavision Tourism",
      },
    ],
    titleFour: "",
    titleFourInfo:
      "",
    titleFourCheckList: [
    ],
    titleFourAfterInfo:"",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "Is it difficult to get a France visa? ",
        ans: "By selecting which type of French visa is needed it will be quite an easier process to get a visa issued. Schengen visa from France will allow foreigners to Schengen area country for less than 90 days for a six-month period.",
      },
      {
        id: "1",
        que: "How much it costs for France visa fees from Dubai?",
        ans: "According to VFS it is about 94 dirhams as France visa fees from Dubai are charged for the applicant along with the submission of visa application. However, this fee will not be refunded back if the applicant wishes to call back the application during the middle of processing of the visa before collecting biometric data details.",
      },
    ],
  },


  {
    id: "Canada Tourist Visa",
    maintitle:
      "Canada Tourist Visa",
    title: "Canada Tourist Visa",
    info: "Canada is a large country with lots of different landscapes, each with unique weather conditions. The best part about visiting Canada is the ability to see both summer and winter sights in one trip! If you plan to travel during the summer months of August and September, temperatures are warm enough for most outdoor activities but cool enough that you can still bundle up when it gets cold at night. However, if you are looking to escape the crowded atmosphere of many popular points of interest during this time, try traveling during shoulder season in October or November instead.",
    titleOne: "Documents needed for applying Canada visa from Dubai",
    titleOneInfo:
      "",
    titleOneCheckList: [
      {
        listItem: "First of all, to apply for a Canada visa from the UAE you need to have a valid Dubai residence visa which has a 3-month validity.",
      },
      {
        listItem: "Applicants should submit the original passport to apply for a Canada tourist visa from Dubai and the passport should possess validity of six months. At least two pages needed to be blank in your passport.",
      },
      {
        listItem: "The NOC letter is important which explains salary, joining date, designation and the need of visit.",
      },
      {
        listItem: "If you have business in the UAE then you may need to submit a copy of trade licence, your self introduction letter which includes details of monthly income.",
      },
      {
        listItem: "An Invitation letter can be submitted if the applicant has friends or relatives who live in Canada. If you are applying for a business visa, an invitation letter is mandatory.",
      },
    ],
    titleTwo: "Canada visa requirements for Dubai citizens and residents",
    titleTwoCheckList: [
      {
        listItem:
          "Copy of passport needed which should possess 6 months validity.",
      },
      {
        listItem:
          "Copy of UAE residence visa which is of 6-month validity.",
      },
      {
        listItem:
          "A NOC letter which is issued by the company is needed to submit a trade license copy.",
      },
      {
        listItem:
          "Bank statement within the time period of 6 months is required.",
      },
      {
        listItem:
          "2 passport size photographs are needed which should be taken on white background.",
      },
      {
        listItem:
          "A Copy of Emirates ID is needed.",
      },
      {
        listItem:
          "After submitting the essential documents, it will take 100 working days by the Embassy for processing. The visa should be for a 30 days stay or may be according to the decision of the Embassy.",
      },
    ],
    titleThree: "Types of visit visas in Canada",
    titleThreeCheckList: [
      {
        listItem:
          "A single-entry visa permits applicants to move from a foreign country to enter Canada for once only.",
      },
      {
        listItem:
          "A multiple entry visitor visa allows the holder to move to Canada within the time period of 6 months which will be valid for 10 years.",
      },
    ],
    titleFour: "Other requirements to apply for Canadian visa",
    titleFourInfo:
      "Attach the details of the applicant's hotel pre booking which is not essential but may be beneficial. In some cases you may need to exhibit the confirmed return ticket, hotel voucher at the airport during the trip to Canada. A cover letter will be needed which explains clearly about your personal, financial details, purpose of visit, duration of visit, travel itinerary, accommodation details, assurance that applicant may return to UAE once the purpose and duration of visit get ended. Hence this letter may support the documents required for applying for the Canada visa from the UAE. Canada Visa from Dubai cost is very less, so you can go for it.",
    titleFourCheckList: [
      {
        listItem:
          "Three passport size photographs with white backgrounds which have been taken recently are needed.",
      },
      {
        listItem:
          "The Last six months bank statement is to be submitted.",
      },
      {
        listItem:
          "If you are married then a marriage certificate is also required.",
      },
    ],
    titleFourAfterInfo:"",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "Who is eligible for getting a Canada visit visa from Dubai?",
        ans: "Visitors from UAE including residents need a visa for travelling to Canada. The criteria for applying for a Canada visa from Dubai totally depends on the nationality of applicants. So, it is clear that even though you live in Dubai, your nationality is the one main point that determines whether you are eligible for a Canadian visa or not.",
      },
      {
        id: "2",
        que: "How can a person get Canada visa from UAE? ",
        ans: "The applicant must possess a passport with a validity period of 6 months at time of moving. Two recent photographs of 35 mm x 45 mm taken with a white background are needed.",
      },
      {
        id: "3",
        que: "How long it will take to get a Canada visa processed?",
        ans: "It will take processing time of 15 days for a Canada tourist visa if the documentation is done correctly. For a business visa it may take 1 – 6 weeks as processing time. In the case of a temporary resident visa, it may take 16- 20 days to get processed.",
      },
    ],
  },


  {
    id: "USA Visa from UAE",
    maintitle:
      "USA Visa from UAE",
    title: "USA Visa from UAE",
    info: "People often love to explore the fabulous country USA for its unique offerings. You can have a snow filled experience wandering around the incredible national parks on your relaxed tropical holidays. But people often think it is very hard to reach the USA and it's sort of not their choice. This is because the US visa application process is frequently thought to be daunting due to the lack of the knowledge among the individuals regarding where to begin from and how to continue with the visa application. ",
    titleOne: "Main categories of USA visas that you can apply from Dubai are the following:",
    titleOneInfo:
      "",
    titleOneCheckList: [
      {
        listItem: "USA non immigrant visas",
      },
      {
        listItem: "USA immigrant visas",
      },
      {
        listItem: "Each of the above mentioned categories have further subcategories that match with various types of applicants and their purpose for travelling. You must know what type of visa you are applying for, because the visa application process from Dubai depends on that.",
      },
    ],
    titleTwo: "USA visit visas from Dubai also come with two major subcategories:",
    titleTwoCheckList: [
      {
        listItem:
          "Business visa",
      },
      {
        listItem:
          "US tourist visa.",
      },
      {
        listItem:
          "The US Business Visa from Dubai is for those individuals who wish to visit the USA for business purposes. The US Tourist Visa from Dubai is for general purposes like travelling. Once the visa is approved, you are allowed to enter and stay in the US for designated periods of time, which is subject to a variety of factors of one’s choice.",
      },
    ],
    titleThree: "Need to submit the proof of your travel:",
    titleThreeCheckList: [
      {
        listItem:
          "If it is for tourism purposes, then you must show your travel itinerary, tickets, etc.",
      },
      {
        listItem:
          "If the purpose is visiting a family or friends, then you must show an invitation letter.",
      },
      {
        listItem:"If the purpose is medical, then you must submit necessary medical documents.",
      },
      {
        listItem:
          "If the purpose is business, then:",
      },
      {
        listItem:
          "You must submit your business invitations.",
      },
      {
        listItem:
          "You must display the proof that you are a foreigner.",
      },
      {
        listItem:
          "You must give a reason to leave the country to the immigration officer.",
      },
      {
        listItem:
          "You must not have any paid performances.",
      },
      {
        listItem:
          "You must submit the sufficient proof of funds.",
      },
      {
        listItem:
          "You should have a residence in your home country.",
      },
    ],
    titleFour: "Documents required for US Tourist visa",
    titleFourInfo:
      "",
    titleFourCheckList: [
      {
        listItem:
          "UAE residence Visa which is valid for 30 days.",
      },
      {
        listItem:
          "Passport need to be valid for 6 months",
      },
      {
        listItem:
          "Original NOC letter stating designation, purpose of travel addressing China Consulate, Joining date, salary.",
      },
      {
        listItem:
          "If a Traveller is Self Employed then he/she needs to submit a Valid Trade License copy, Self-Introduction letter stating monthly income, passport details for Partners, Investors & Owners.",
      },
      {
        listItem:
          "3 Recent Photograph with White Background",
      },
      {
        listItem:
          "Latest transaction of online bank statement with bank stamp or original bank statement",
      },
      {
        listItem:
          "Invitation letter from USA friend or relative is definitely an additional advantage for USA visa application",
      },
      {
        listItem:
          "Invitation letter needed for USA Business visa",
      },
      {
        listItem:
          "Fast track Service requires extra additional charges",
      },
    ],
    titleFourAfterInfo:"",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "Who is eligible to apply for a USA visa from Dubai?",
        ans: "All applicants having UAE passports and are residents, aside from those with passports from countries on the US Visa Waiver Program, need to apply for a US visa from Dubai to be permitted entry into the United States of America. As a UAE citizen or resident, you must carry a US visa whether you are travelling to the country for immigration or for other purposes.",
      },
      {
        id: "2",
        que: "What are the different types of US tourist visa?",
        ans: "Two different types of US tourist visa are business visa and tourist visa.",
      },
      {
        id: "3",
        que: "When Should I Apply For My USA Visa From Dubai?",
        ans: "It is best to apply for your US tourist visa from Dubai at least four months prior to your date of travel. You can get the visa sooner, but it is better to plan well ahead, given the varying length of visa processing time.",
      },
    ],
  },

  {
    id: "UAE Inside the Country Visa - 30 Days",
    maintitle:
      "UAE Inside the Country Visa - 30 Days",
    title: "UAE Inside the Country Visa - 30 Days",
    info: "Heptavision Tourism provides 30 days inside visa change in UAE that allows you to extend your visa inside UAE for 30 days, allowing you to stay in the country without going through that long process of exiting and entering again.",
    titleOne: "Criterias needed for UAE inside country visa",
    titleOneInfo:
      "Processing of your Dubai visa process starts once clear scanned copies of your following documents are received: ",
    titleOneCheckList: [
      {
        listItem: "Make sure that you are entering Dubai legally.",
      },
      {
        listItem: "You needed a visa as an on -arrival visa, 30 days inside country tourist visa or 30 days multiple entry visa",
      },
      {
        listItem: "Residence visa",
      },
      {
        listItem:
          "Visa is valid",
      },
      {
        listItem: "Be sure that you haven’t violated any rules and conditions based on your present visa.",
      },
      {
        listItem: "Make sure that you are not involved in any crimes which may deny or reject the visa extension.",
      },
    ],
    titleTwo: "Step by step procedures to get UAE inside country visa",
    titleTwoCheckList: [
      {
        listItem:
          "Contact the visa specialist and you can brief your requirements.",
      },
      {
        listItem:
          "Submit essential documents before your visa expires.",
      },
      {
        listItem:
          "Fill up the visa application form.",
      },
      {
        listItem:
          "You can send the documents either by email or by walking directly to the authority.",
      },
      {
        listItem:
          "Share your personal details as phone number, address, email id etc.",
      },
      {
        listItem:
          "After verifying the documents visa will be forwarded for processing.",
      },
      {
        listItem:
          "Need to pay AED 1899 in order to process the visa extension.",
      },
      {
        listItem:
          "If your visa gets ready you will be informed through email id and call.",
      },
    ],
    titleThree: "Applicants whose Residence visa or UAE visa got cancelled",
    titleThreeCheckList: [
      {
        listItem:
          "Copy of the cancelled residence or employment visa.",
      },
      {
        listItem:
          "copy of back and front pages of passport.",
      },
      {
        listItem:
          "Passport size photographs should be submitted which have been taken recently on white background.",
      },
      {
        listItem:
          "Submit Residence visa, Emirates id and passport copies of the guarantor.",
      },
    ],
    titleFour: "Other needed documents",
    titleFourInfo:
      "",
    titleFourCheckList: [
      {
        listItem:
          "Valid passport .",
      },
      {
        listItem: "The documents such as passport and residence visa by guarantor should have three months validity.",
      },
      {
        listItem:
          "If the applicant has a guarantor then he / she should need to submit the previous month bank statement and a security check of AED 5500 for the agency. ",
      },
      {
        listItem:
          "Security amount will be refunded back once the applicant is exited from the country.",
      },
      {
        listItem: "If the applicant doesn’t have a guarantor in the UAE then the applicant needs to pay AED 2000. ",
      },
      {
        listItem:
          "The amount will be refunded when the applicant gets exited from UAE or when their visa status gets changed.",
      },
    ],
    titleFourAfterInfo: "",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "Who can be benefitted from the UAE country inside visa service? ",
        ans: "This service can be accessed by different types of visa holders such as tourist visa, multiple entry visa, residence visa, employment visa etc. who wish to stay back in Dubai without exiting the country.",
      },
      {
        id: "2",
        que: "30 days inside country visa change is possible?",
        ans: "Yes.  Anyone can apply for 30 days inside the country visa change.",
      },
    ],
  },


  {
    id: "UAE Inside the Country Visa - 90 Days",
    maintitle:
      "UAE Inside the Country Visa - 90 Days",
    title: "UAE Inside the Country Visa - 90 Days",
    info: "Heptavision Tourism provides 90 days inside visa change in UAE that allows you to extend your visa inside UAE for 90 days, allowing you to stay in the country without going through that long process of exiting and entering again.",
    titleOne: "Criterias needed for UAE inside country visa",
    titleOneInfo:
      "Processing of your Dubai visa process starts once clear scanned copies of your following documents are received: ",
    titleOneCheckList: [
      {
        listItem: "Make sure that you are entering Dubai legally.",
      },
      {
        listItem: "You needed a visa as an on -arrival visa, 90 days inside country tourist visa or 90 days multiple entry visa",
      },
      {
        listItem: "Residence visa",
      },
      {
        listItem:
          "Visa is valid",
      },
      {
        listItem: "Be sure that you haven’t violated any rules and conditions based on your present visa.",
      },
      {
        listItem: "Make sure that you are not involved in any crimes which may deny or reject the visa extension.",
      },
    ],
    titleTwo: "Step by step procedures to get UAE inside country visa",
    titleTwoCheckList: [
      {
        listItem:
          "Contact the visa specialist and you can brief your requirements.",
      },
      {
        listItem:
          "Submit essential documents before your visa expires.",
      },
      {
        listItem:
          "Fill up the visa application form.",
      },
      {
        listItem:
          "You can send the documents either by email or by walking directly to the authority.",
      },
      {
        listItem:
          "Share your personal details as phone number, address, email id etc.",
      },
      {
        listItem:
          "After verifying the documents visa will be forwarded for processing.",
      },
      {
        listItem:
          "Need to pay AED 1899 in order to process the visa extension.",
      },
      {
        listItem:
          "If your visa gets ready you will be informed through email id and call.",
      },
    ],
    titleThree: "Applicants whose Residence visa or UAE visa got cancelled",
    titleThreeCheckList: [
      {
        listItem:
          "Copy of the cancelled residence or employment visa.",
      },
      {
        listItem:
          "copy of back and front pages of passport.",
      },
      {
        listItem:
          "Passport size photographs should be submitted which have been taken recently on white background.",
      },
      {
        listItem:
          "Submit Residence visa, Emirates id and passport copies of the guarantor.",
      },
    ],
    titleFour: "Other needed documents",
    titleFourInfo:
      "",
    titleFourCheckList: [
      {
        listItem:
          "Valid passport .",
      },
      {
        listItem: "The documents such as passport and residence visa by guarantor should have three months validity.",
      },
      {
        listItem:
          "If the applicant has a guarantor then he / she should need to submit the previous month bank statement and a security check of AED 5500 for the agency. ",
      },
      {
        listItem:
          "Security amount will be refunded back once the applicant is exited from the country.",
      },
      {
        listItem: "If the applicant doesn’t have a guarantor in the UAE then the applicant needs to pay AED 2000. ",
      },
      {
        listItem:
          "The amount will be refunded when the applicant gets exited from UAE or when their visa status gets changed.",
      },
    ],
    titleFourAfterInfo: "",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "Who can be benefitted from the UAE country inside visa service? ",
        ans: "This service can be accessed by different types of visa holders such as tourist visa, multiple entry visa, residence visa, employment visa etc. who wish to stay back in Dubai without exiting the country.",
      },
      {
        id: "2",
        que: "90 days inside country visa change is possible?",
        ans: "Yes.  Anyone can apply for 90 days inside the country visa change.",
      },
    ],
  },


  {
    id: "Inside Country Visa Change",
    maintitle:
      "Inside Country Visa Change",
    title: "Inside Country Visa Change",
    info: "UAE visa change is becoming a lengthy process.People who are on vacation in Dubai  for 30 days/90 days visa also require changing in the status of their stay at Dubai. This is the main advantage of this type of visa.  We can provide both 30 days and 90 days UAE inside country visa change packages are accessible at affordable cost.",
    titleOne: "Advantages of inside country visa change Dubai",
    titleOneInfo:
      "",
    titleOneCheckList: [
      {
        listItem: "You can change the status without leaving the country.",
      },
      {
        listItem: "No need to wait in the airport for long hours.",
      },
      {
        listItem: "No need to depend on other places to travel.",
      },
    ],
    titleTwo: "Documents required for UAE Inside country visa / Documents required from residence to tourist visa / from a visitor who is on visa on arrival to tourist visa",
    titleTwoCheckList: [
      {
        listItem:
          "Scanned colored passport copy front and back",
      },
      {
        listItem:
          "Scanned white background photo",
      },
      {
        listItem:
          "Last tourist/residence visa copy (For Visitors who are on visa on arrival : On arrival visa/entry stamp)",
      },
    ],
    titleThree: " A guarantor can be a relative or friend who is currently working/ resident in UAE  ",
    titleThreeCheckList: [
      {
        listItem:
          "Scanned colored passport copy",
      },
      {
        listItem:
          "Scanned resident visa copy with minimum 3months validity",
      },
      {
        listItem:
          "Scanned Emirates I.D copy",
      },
    ],
    titleFour: "Terms & Conditions",
    titleFourInfo:
      "",
    titleFourCheckList: [
      {
        listItem:
          "Prices are non-refundable.",
      },
      {
        listItem: "Age restrictions are applicable for certain nationalities.",
      },
      {
        listItem:
          "Visa application will be processed only after completing the document and clearing the payment.",
      },
      {
        listItem:
          "Documents submitted are not to be forged, if so strict actions will be taken against the concerned.",
      },
      {
        listItem: "If you have overstay fines then it must be paid directly at the immigration counter.",
      },
      {
        listItem:
          "If the Tickets is once booked it cannot be cancelled or Changed and it’s non-refundable.",
      },
    ],
    titleFourAfterInfo: "",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "Is it possible to get a new visit visa/tourist visa without exit?",
        ans: "Yes it is possible .",
      },
      {
        id: "2",
        que: "How long does this visa processing take?",
        ans: "This usually takes nearly four working days for the processing of the visa.",
      },
    ],
  },

  {
    id: "Airport to Airport Visa Change",
    maintitle:
      "Airport to Airport Visa Change",
    title: "30 Days Airport to Airport Visa Change",
    info: "We provide visa services in Dubai with 100% guaranteed. Our visa specialists will help you to get the UAE visa on time without any delay . We provide the best visa service with  professional staff to assist you throughout your entire application process.  This kind of visa is for single-entry and single-exit. A traveler can use 30 Days Airport to Airport Visa Change for a one-time holiday purpose only. 30 Days Airport to Airport Visa Change is valid for 58 days from the date of issue.",
    titleOne: "Documents required from tourist to tourist visa:",
    titleOneInfo:
      "",
    titleOneCheckList: [
      {
        listItem: "Scanned colored passport copy front and back",
      },
      {
        listItem: "Scanned white background photo",
      },
      {
        listItem: "Last tourist visa copy",
      },
    ],
    titleTwo: "Documents required from residence to tourist visa:",
    titleTwoCheckList: [
      {
        listItem: "Scanned colored passport copy front and back",
      },
      {
        listItem: "Scanned white background photo",
      },
      {
        listItem: "Last residence visa copy",
      },
    ],
    titleThree: "Guarantor’s Document:",
    titleThreeCheckList: [
      {
        listItem:
          " A guarantor can be a relative or friend who is currently working/ resident in UAE who can be the point of contact in case of emergency: ",
      },
      {
        listItem: "Scanned colored passport copy ",
      },
      {
        listItem: "Scanned resident visa copy with minimum 3 months validity ",
      },
      {
        listItem: " Scanned Emirates I.D copy ",
      },
    ],
    titleFour: "",
    titleFourInfo:
      "",
    titleFourCheckList: [
    ],
    titleFourAfterInfo: "",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "Is it possible to get a new visit visa/tourist visa without exit?",
        ans: "Yes it is possible .",
      },
      {
        id: "2",
        que: "How long does this visa processing take?",
        ans: "This usually takes nearly four working days for the processing of the visa.",
      },
    ],
  },


  {
    id: "UAE Inside the Country Visa - 90 Days",
    maintitle:
      "UAE Inside the Country Visa - 90 Days",
    title: "UAE Inside the Country Visa - 90 Days",
    info: "90 Days Airport To Airport Visa change package is for customers who need to change the existing tourist visa to a new tourist visa on the same day. The advantage of the visa change package is that customers do not need to leave the  country. ",
    titleOne: "Documents required from tourist to tourist visa:",
    titleOneInfo:
      "",
    titleOneCheckList: [
      {
        listItem: "Scanned colored passport copy front and back",
      },
      {
        listItem: "Scanned white background photo",
      },
      {
        listItem: "Last tourist visa copy",
      },
    ],
    titleTwo: "Documents required from residence to tourist visa:",
    titleTwoCheckList: [
      {
        listItem: "Scanned colored passport copy front and back",
      },
      {
        listItem: "Scanned white background photo",
      },
      {
        listItem: "• Last residence visa copy ",
      },
    ],
    titleThree: "Guarantor’s Document:",
    titleThreeCheckList: [
      {
        listItem:
          " A guarantor can be a relative or friend who is currently working/ resident in UAE who can be the point of contact in case of emergency: ",
      },
      {
        listItem: "Scanned colored passport copy ",
      },
      {
        listItem: "Scanned resident visa copy with minimum 3 months validity ",
      },
      {
        listItem: " Scanned Emirates I.D copy ",
      },
    ],
    titleFour: "",
    titleFourInfo:
      "",
    titleFourCheckList: [
    ],
    titleFourAfterInfo: "",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "Is it possible to get a new visit visa/tourist visa without exit?",
        ans: "Yes it is possible .",
      },
      {
        id: "2",
        que: "How long does this visa processing take?",
        ans: "This usually takes nearly four working days for the processing of the visa.",
      },
    ],
  },

  {
    id: "Visa Change By Bus",
    maintitle:
      "Visa Change By Bus",
    title: "Visa Change By Bus",
    info: "Heptavision Tourism provides this package for individuals who are looking for renew their existing tourist visa to a new Tourist visa or employment visa. The package has to be availed a few days before your last day as we have to apply for your Oman visa which will take 1-2 working days. Once the Oman visa has been issued, we will organize your transport to Oman by bus. ",
    titleOne: "Package includes for Visa Change by Bus",
    titleOneInfo:
      "",
    titleOneCheckList: [
      {
        listItem: "Return bus ticket to Oman",
      },
      {
        listItem: "Oman visa",
      },
      {
        listItem: "UAE tourist visa",
      },
      {
        listItem: "Free accommodation for one day",
      },
      {
        listItem: "Border fees",
      },
    ],
    titleTwo: "Documents required from tourist to tourist visa:",
    titleTwoCheckList: [
      {
        listItem: "Scanned colored passport copy first page and last page",
      },
      {
        listItem: "Passport should be valid for a minimum of 6 months",
      },
      {
        listItem: "Scanned colored passport size photo ",
      },
      {
        listItem: "Last tourist visa copy",
      },
    ],
    titleThree: "Documents required from residence to tourist visa",
    titleThreeCheckList: [
      {
        listItem:
          "Scanned colored passport copy first page and last page",
      },
      {
        listItem: "Passport should be valid for a minimum of 6 months",
      },
      {
        listItem: "Scanned colored passport size photo",
      },
      {
        listItem: "Last residence visa copy",
      },
    ],
    titleFour: "Guarantor’s Document For Visa change by Bus",
    titleFourInfo:
      "A guarantor can be a relative or friend who is currently working/ resident in UAE who can be the point of contact in case of emergency:",
    titleFourCheckList: [
      {
        listItem:
          "Scanned colored passport copy",
      },
      {
        listItem: "Scanned copy for resident visa is required with valid for at least 3 months",
      },
      {
        listItem: "Scanned Emirates I.D copy",
      },
    ],
    titleFourAfterInfo: "",
    faqTitle: "Answers to Some of Your Fequently Asked Questions",
    faqList: [
      {
        id: "1",
        que: "Is it possible to get a new visit visa/tourist visa without exit?",
        ans: "Yes it is possible .",
      },
      {
        id: "2",
        que: "How long does this visa processing take?",
        ans: "This usually takes nearly four working days for the processing of the visa.",
      },
    ],
  },
];
export default VisaCardDetailsData;
