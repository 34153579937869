import { Container, Typography } from "@mui/material";
import React from "react";
import ReviewCard from "./ReviewCard";
import { Box } from "@mui/system";

const Review = () => {
  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pb: 6,
        }}
      >
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "1.2rem",
            fontFamily: "poppins",
          }}
          variant="h3"
          component="h2"
          align="center"
          color="rgba(0, 0, 0, 0.87);"
        >
          CLIENT TESTIMONIALS
        </Typography>
        <Typography
          sx={{
            width:["18rem","10rem","30rem","50rem"],
            fontWeight: "700",
            fontSize: "2.5rem",
            fontFamily: "poppins",
            mb: 4,
            py:1
          }}
          variant="h3"
          component="h2"
          align="center"
          color="rgba(0, 0, 0, 0.87);"
        >
          What Customers Saying About Heptavision Tourism
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center",gap:2,flexWrap:["wrap","wrap","nowrap","nowrap"] }}>
          <ReviewCard />
          {/* <ReviewCard />
          <ReviewCard /> */}
        </Box>
      </Container>
    </>
  );
};

export default Review;
