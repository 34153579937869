import React from 'react'
import Cards from '../modules/components/about/Cards'
import Career from '../modules/components/about/Career'
import Experience from '../modules/components/about/Experience'
import Header from '../modules/components/about/Header'
import MiddleSection from '../modules/components/about/MiddleSection'
import Mission from '../modules/components/about/Mission'
import TimeLine from '../modules/components/about/TimeLine'
// import Reviews from '../modules/components/about/Reviews'

export default function AboutUs() {
  return (
    <>
      <Header/>
      <Mission/>
      <Cards/>
      <MiddleSection/>
      <Experience/>
      <Career/>
      {/* <TimeLine/> */}
      {/* <Reviews/> */}
    </>
  )
}
