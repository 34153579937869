import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Box from "@mui/material/Box";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function WhatsAppQuery() {
  const handleClick = () => {
    window.open(
      "https://wa.me/971529819347?text=Hi,%20I%20am%20interested%20in%20your%20services.%20 Please send me more informatione.",
      "_blank"
    );
  };

  return (
    <Box sx={{ position: "fixed", zIndex: "999", bottom: 0, right: 0 }}>
      <WhatsAppIcon
        sx={{
          fontSize: "60px",
          margin: "1rem",
          backgroundColor: "#28D146",
          borderRadius: "1rem",
          color: "white",
          p: "0.2rem",
        }}
        onClick={handleClick}
      />
    </Box>
  );
}
