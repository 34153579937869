import React from "react";
import staycation from "../../../assets/staycation/staycation.svg";
import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";

export default function Header({title}) {
  return (
    <>
      <Box sx={{ bgcolor: "#F0F0F4" }}>
        <Container>
          <Box
            sx={{
              height: ["30rem", "30rem", "30rem", "30rem"],
              display: {
                xs: "block",
                sm: "block",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box sx={{width:"100%", pt: [15, 15, 8, 8] }}>
              <Typography
                align="center"
                variant="h3"
                color="#242F65"
                fontWeight="700"
                fontFamily="poppins"
                fontSize="2.2rem"
              >
                {title}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                pt: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{width:"13rem"}}>
                <img src={staycation} alt="img" width={"100%"} />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
