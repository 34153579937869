import React from "react";
import { Card, CardMedia } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/system";
import { Info, InfoCaption, InfoTitle } from "@mui-treasury/components/info";
import { useGalaxyInfoStyles } from "@mui-treasury/styles/info/galaxy";
import { useCoverCardMediaStyles } from "@mui-treasury/styles/cardMedia/cover";

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: "1rem",
    boxShadow: "none",
    position: "relative",
    width: 280,
    height: 400,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)"
    },

    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      width: "100%",
      height: "64%",
      bottom: 0,
      zIndex: 1,
      background: "linear-gradient(to top, #000, rgba(0,0,0,0))",
    },
  },
  content: {
    position: "absolute",
    zIndex: 2,
    bottom: 0,
    width: "90%",
  },
}));

function BookNowCard(props) {
  const mediaStyles = useCoverCardMediaStyles({ bgPosition: "top" });
  const styles = useStyles();

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box>
          <Card className={styles.card}>
            <CardMedia classes={mediaStyles} image={props.data.img} />
            <Box py={3} px={2} className={styles.content}>
              <Info useStyles={useGalaxyInfoStyles}>
                <InfoTitle style={{ fontFamily: "poppins" }}>
                  {" "}
                  {props.data.title}
                </InfoTitle>
                <InfoCaption style={{ fontFamily: "poppins" }}>
                  {props.data.price}
                </InfoCaption>
              </Info>
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
}

export default BookNowCard;
