import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/material";
import VisaSectionCard from "./VisaSectionCard";
import productCurvyLines from "../../../assets/home/VisaServices/productCurvyLines.png";

function VisaSection() {
  return (
    <Box
      sx={{
        py: ["8rem"],
        // backgroundColor: "#fff4f8",
        // backgroundImage: `url(${productCurvyLines})`,
        
      }}
    >
      <Container>
        <Box sx={{}}>
          {/* <Grid container spacing={2}>
            // <Grid item xs={12} md={3} lg={3}> */}
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: ["2rem", "2rem", "3rem", "3rem"],
                    color:"#2c4058"
                  }}
                  variant="h3"
                  component="h2"
                  align="center"
                  color="rgba(0, 0, 0, 0.87);"
                  m={0}
                  fontFamily={"poppins"}
                >
                  Visa Services
                 
                </Typography>
                <hr
            style={{
              height: "4px",
              width: "60px",
              margin: " 10px auto 0",
              backgroundColor: "#EF7D26",
              display: "block",
              border: "none",
            }}
          ></hr>
                <Typography
                  variant="body1"
                  gutterBottom={true}
                  component="p"
                  align="center"
                  fontFamily={"poppins"}
                  color={"#313131"}
                  fontSize={"1rem"}
                  mt={2}
                  p={4}
                >
                  We the 'Heptavision company' in UAE are all set to assist you for your complete holiday trip with your loved ones. Our visa experts are dedicated to helping achieve our clients ,travel goals by dealing with the different services involved such as airport transfers services, hotel booking in Dubai  & Visa Services in Dubai. We claim the simplest and fastest visa service to your ease.So,no need to worry more,be ready for Dubai tour!!
                </Typography>
              </Box>
            {/* </Grid> */}
            {/* <Grid */}
              {/* item
              xs={12}
              md={9}
              lg={9}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            > */}
              <VisaSectionCard />
            {/* </Grid>
          </Grid> */}
        </Box>
      </Container>
    </Box>
  );
}

export default VisaSection;
