import React from "react";
import VisaService from "../modules/components/visa/VisaService";
import { useLocation } from "react-router-dom";

export default function Visa() {
  const location = useLocation();

  return (
    <>
      <VisaService id={location.state} />
    </>
  );
}
