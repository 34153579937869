import React from "react";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  actionArea: {
    transition: "0.2s",
    "&:hover": {
      color: "black",
    },
    "&:focus": {
      color: "orange",
    },
  },
  menuBox: {
    marginLeft: "-9rem",
    transition: "0.3sec",
  },
  mobileMenu: {},
}));

export default function HoverButton() {
  const styles = useStyles();
  return (
    <Button
      sx={{mt: "8px"}}
    >
      <Typography
        className={styles.actionArea}
        key="VISA Services"
        sx={{
          fontFamily: "poppins",
          fontWeight: "400",
          color: "gray",
          mx: 1,
        }}
      >
        VISA Services
      </Typography>
    </Button>
  );
}
