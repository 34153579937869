import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Typography } from "@mui/material";
import { border } from "@mui/system";
import Box from "@mui/material/Box";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import VisaCardDetails from "./VisaCardDetails";
const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: "auto",
    borderRadius: spacing(2), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.3)",
    position: "relative",
    width: "100%",
    marginLeft: "auto",
    overflow: "initial",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    [breakpoints.up("md")]: {
      flexDirection: "row",
    },
    transition: "0.5",
    // "&:hover":{
    //   border:"2px solid orange",
    //   transform:"scale(2)"
    // }
  },

  media: {
    width: "88%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: spacing(-3),
    height: "14rem",
    paddingBottom: "0%",
    borderRadius: spacing(2),
    backgroundColor: "#fff",
    position: "relative",
    [breakpoints.up("md")]: {
      width: "100%",
      marginLeft: spacing(-3),
      marginTop: 0,
      transform: "translateX(-8px)",
    },
    "&:after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)",
      borderRadius: spacing(2),
      opacity: 0.5,
    },
  },
  content: {
    width: "100%",
    padding: 0,
  },
  btn: {
    backgroundColor: "orange",
    // color:"#fff",
    padding: "0.5rem 1rem",
    margin: "1rem 0",
    border: "1px solid #fff",
    fontFamily: "poppins",
    fontWeight: "600",
  },
}));

function VisaCard(props) {
  const styles = useStyles();

  return (
    <Box
      sx={{
        mt: 8,
        width: [280, 280, 500, 500],
        height: [380, 380, 200, 200],
      }}
    >
      <Card className={styles.root}>
        <CardMedia className={styles.media} image={"AbuDhabi.jpg"} />
        <CardContent className={styles.content}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: ["center"],
              alignItems: ["center", "center", "start", "start"],
              pl: ["0rem", "0rem", "1rem", "1rem"],
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontWeight: "600",
                fontSize: "1.3rem",
                overflow: "hidden",
                pt: 2,
              }}
            >
              {props.title}
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "1.2rem",
                pt: 1,
              }}
            >
              {props.price}
            </Typography>
            
              <Link to="/visa/visadetails" state={props.title}>
                <Button className={styles.btn}>Read more</Button>
              </Link>

          </Box>
        </CardContent>
      </Card>
      
    </Box>
  );
}

export default VisaCard;
