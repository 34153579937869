import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Cards from "./Cards";
import { Container } from "@mui/system";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, Navigate  } from "react-router-dom";
import CardData from "./tourData/CardData";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import axios from "axios";

export default function PackageCards() {
  const location = useLocation();

  const data = CardData.find((e) => e.id === location.state);

  const [data1, setData1] = useState({});

  const handleChange = (key, value) => {
    setData1((old) => {
      return { ...old, [key]: value };
    });
  };

  const sendEmail = async () => {
    try {
      let response = await axios(`${process.env.REACT_APP_API}/api/sendemail`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data1,
      });
      alert("Messege Sent, Our team will contact you soon");

      // if(response.data.message === "Email sent") {
      //   setData({
      //     name: "",
      //     email: "",
      //     contact: "",
      //     emailBody: "",
      //   })
      // }
      //console.log(data)
    } catch (e) {
      alert("Failed to load Data" + e);
    }
  };

  const optionArray = [
    {
      value: "UAE VISA",
    },
    {
      value: "International VISA",
    },
    {
      value: "UAE Tours",
    },
    {
      value: "Staycations",
    },
    {
      value: "Holiday Packages",
    },
  ];

  const linkData = [
    {
      title: "UAE City Tours",
    },
    {
      title: "Desert Safari Tours",
    },
    {
      title: "Dhow Cruise Tours",
    },
    {
      title: "Sightseeing Tours",
    },
    {
      title: "Attraction Tours",
    },
    {
      title: "Attraction Tickets",
    },
    {
      title: "Combo Tours",
    },
    {
      title: "Special Tours",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header title={location.state} />

      <Box sx={{ bgcolor: "#F0F0F4" }}>
        <Container>
          <Box
            sx={{
              // height: ["15rem", "20rem", "17rem", "17rem"],
              display: {
                xs: "block",
                sm: "block",
                md: "flex",
                lg: "flex",
                xl: "flex",
                width: "100%",
              },
              justifyContent: "start",
              alignItems: "center",
            }}
          ></Box>
        </Container>
      </Box>
      <Box>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={8}>
              {data.CardData.map((curEle) => {
                return (
                  <Cards
                    title={curEle.title}
                    description={curEle.description}
                    price={curEle.price}
                  />
                );
              })}
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={4}
              bgcolor={"#EFF2F5"}
              sx={{ mt: "5rem" }}
            >
              <Box
                sx={{
                  minHeight: "16rem",
                  backgroundColor: "#145398",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "poppins",
                    fontWeight: "600",
                    pt: 2,
                  }}
                  align="center"
                  variant="h5"
                  component="h2"
                >
                  Contact Us
                </Typography>

                <ListItem sx={{ padding: "0px", ml: 2, mt: 2 }}>
                  <ListItemIcon>
                    <CallIcon sx={{ fontSize: "36px", color: "orange" }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography="true"
                    align="left"
                    primary={"043271009"}
                    sx={{
                      fontSize: "20px",
                      color: "white",
                      fontFamily: "poppins",
                      fontWeight: "400",
                    }}
                  />
                </ListItem>

                <ListItem sx={{ padding: "0px", ml: 2, mt: 2 }}>
                  <ListItemIcon>
                    <WhatsAppIcon sx={{ fontSize: "36px", color: "orange" }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography="true"
                    align="left"
                    primary={"+971 52 981 9347"}
                    sx={{
                      fontSize: "20px",
                      color: "white",
                      fontFamily: "poppins",
                      fontWeight: "400",
                    }}
                  />
                </ListItem>

                <ListItem sx={{ padding: "0px", ml: 2, mt: 2 }}>
                  <ListItemIcon>
                    <MarkEmailReadIcon
                      sx={{ fontSize: "36px", color: "orange" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography="true"
                    align="left"
                    primary={"info@heptavisiontours.com"}
                    sx={{
                      fontSize: "20px",
                      color: "white",
                      fontFamily: "poppins",
                      fontWeight: "400",
                    }}
                  />
                </ListItem>
              </Box>

              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  gap: 2,
                  my: 4,
                  pr: 2,
                }}
              >
                <Typography
                  align="center"
                  bgcolor="#EF7D24"
                  color="#fff"
                  fontWeight="600"
                  fontFamily="poppins"
                  fontSize="1.5rem"
                  py="1rem"
                >
                  Enquire Now
                </Typography>
                <TextField
                  value={data1.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  sx={{ bgcolor: "#fff" }}
                />
                <TextField
                  value={data1.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  sx={{ bgcolor: "#fff" }}
                />
                <TextField
                  value={data1.phone}
                  onChange={(e) => handleChange("phone", e.target.value)}
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  sx={{ bgcolor: "#fff" }}
                />
                <TextField
                  select
                  value={data1.sub}
                  onChange={(e) => handleChange("sub", e.target.value)}
                  id="outlined-basic"
                  label="Select"
                  helperText="Please select your subject"
                  variant="outlined"
                  sx={{ bgcolor: "#fff" }}
                >
                  {optionArray.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  value={data1.message}
                  onChange={(e) => handleChange("message", e.target.value)}
                  id="outlined-basic"
                  label="Message"
                  variant="outlined"
                  sx={{ bgcolor: "#fff" }}
                />
                <Box sx={{ width: "100%" }}>
                  <Button
                    onClick={sendEmail}
                    variant="contained"
                    sx={{
                      bgcolor: "#242F65",
                      width: "100%",
                      py: 1,
                      borderRadius: "0",
                    }}
                  >
                    Send Message
                  </Button>
                </Box>

                {linkData.map((item, index) => {
                  return (
                    <Link to={"/tours/packagecards"} state={item.title}>
                      <Typography
                        sx={{
                          bgcolor: "#EF7D26",
                          width: "95%",
                          py: 1,
                          borderRadius: "0",
                          margin: "auto",
                          color: "white",
                          textDecoration: "underline",
                          fontSize: "1rem",
                          fontFamily: "poppins",
                          fontWeight: "600",
                        }}
                        variant="body2"
                        align="center"
                      >
                        {item.title}
                      </Typography>
                    </Link>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
