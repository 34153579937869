import image1 from "../../../../assets/tours/packages/image1.jpg";
import image2 from "../../../../assets/tours/packages/image2.jpg";
import image3 from "../../../../assets/tours/packages/image3.jpg";
import image4 from "../../../../assets/tours/packages/image4.jpg";
import image5 from "../../../../assets/tours/packages/image5.jpg";
import image6 from "../../../../assets/tours/packages/image6.jpg";
import image7 from "../../../../assets/tours/packages/image7.jpg";
import image8 from "../../../../assets/tours/packages/image8.jpg";

const images = [
    {
      url: `${image1}`,
      title: "UAE City Tours",
      width: "25%",
    },
    {
      url: `${image2}`,
      title: "Desert Safari Tours",
      width: "25%",
    },
    {
      url: `${image3}`,
      title: "Dhow Cruise Tours",
      width: "25%",
    },
    {
      url: `${image4}`,
      title: "Sightseeing Tours",
      width: "25%",
    },
    {
      url: `${image5}`,
      title: "Attraction Tours",
      width: "25%",
    },
    {
      url: `${image6}`,
      title: "Attraction Tickets",
      width: "25%",
    },
    {
      url: `${image7}`,
      title: "Combo Tours",
      width: "25%",
    },
    {
      url: `${image8}`,
      title: "Special Tours",
      width: "25%",
    },
  ];

export default images;