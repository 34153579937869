import { Box } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import image1 from "../../../assets/home/Holiday/Holiday1.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 250,
    borderRadius: 16,
    transition: "0.2s",
    backgroundColor: "#fff",
    border: "1px solid #EF7D24",

    "&:hover": {
      transform: "scale(1.1)",
      border: "none",
      backgroundColor: "#fff",
      boxShadow: "0 6px 12px 0 #999",
    },
  },
  btn: {
    backgroundColor: "#fff",
    "&hover": {
      backgroundColor: "#EF7D24",
      color: "#fff",
    },
  },
}));

function HolidayCard(props) {
  const style = useStyles();
  return (
    <>
      <Box
        className={style.root}
        sx={{
          width: "18rem",
          height: "28rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}
      >
        <Box sx={{ p: 2 }}>
          <img src={props.img} alt="img" width="100%" />
        </Box>
        <Box>
          <Typography
            variant="h2"
            align="center"
            sx={{
              fontFamily: "poppins",
              fontSize: "1.2rem",
              fontWeight: "600",
              color: "#444",
              py: "1rem",
            }}
          >
            {props.title}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{
              fontFamily: "poppins",
              fontSize: "1rem",
              lineHeight: "1.6rem",
              pb: "1rem",
              color: "#888",
            }}
          >
            {props.info}
          </Typography>
        </Box>
        <Box>
          <Button className={style.btn} sx={{ px: 2 }}>
            Read More
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default HolidayCard;
