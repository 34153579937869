const VisaSerivceData = [
  {
    id: 1,
    title: "UAE Visa Services",
    headingOne: "FOR INDIVIDUALS AND CORPORATES",
    headingTwo:
      "Heptavision TOURISM – ONE STOP FOR ALL KIND OF VISA ASSISTANCE SERVICES",
    info: "AT Heptavision Tourism we are proud to assist you and your family for a complete travel trip. We know and adhere to the fact that holidays and vacations are a time for a change; to be out and about exploring new places, meeting new people and just soaking in the good times. Our Visa Experts Are Dedicated To Help You In Dealing With All Kinds Of Dubai Visa And Related Services.",
    CardData: [
      {
        title: "14 Days Visa",
        price: "AED 299",
      },
      {
        title: "30 Days Visa",
        price: "AED 349",
      },
      {
        title: "90 Days Visa",
        price: "AED 849",
      },
      {
        title: "30 Days Multi Entry",
        price: "AED 1099",
      },
      {
        title: "90 Days Multi Entry",
        price: "AED 2199",
      },
      {
        title: "Inside Country VISA",
        price: "AED 2199",
      },
    ],
  },
  {
    id: 2,
    title: "International Visa Services In Dubai, United Arab Emirates",
    headingOne: "Heptavision INTERNATIONAL VISA SERVICES",
    headingTwo:
      "INTERNATIONAL VISA SERVICES IN UAE",
    info: "Heptavision Tourism  is known not only for Dubai Visa services but also for providing you with International Tourist visas all over the world! We have a dedicated team with years of experience who can guide you on your next dream destination. Our main goal is to provide you all the assistance you need to successfully get the visa of your desired destination.",
    CardData: [
      {
        title: "Singapore Tourist Visa",
        price: "AED 650",
      },
      {
        title: "Schengen Visa",
        price: "AED 350",
      },
      {
        title: "Turkey Tourist Visa",
        price: "AED 150",
      },
      {
        title: "France visa from UAE",
        price: "AED 300",
      },
      {
        title: "Canada Tourist Visa",
        price: "AED 150",
      },
      {
        title: "USA Visa from UAE",
        price: "AED 150",
      }
    ],
  },

  {
    id: 3,
    title: "Inside Country Visa Change Services In Dubai, UAE",
    headingOne: "HEPTAVISION TOURISM – ONE STOP FOR ALL KIND OF VISA CHANGE SERVICES IN DUBAI",
    headingTwo:
      "Inside Country VISA Change Services In Dubai, UAE",
    info: " UAE visa change is  hectic for  who want to change visa inside the country. This is due to the long procedures that need to be followed .Sometimes people who are on vacation with family for 30 days/90 days visa also require changing in the status of their stay at Dubai. This is the main advantage of this type of visa. ",
    CardData: [
      {
        title: "UAE Inside the Country Visa - 30 Days",
        price: "Starting @ AED 1049",
      },
      {
        title: "UAE Inside the Country Visa - 90 Days",
        price: "Starting @ AED 1099",
      },
    ],
  },

  {
    id: 4,
    title: "VISA Change Service in Dubai",
    headingOne: "HEPTAVISION TOURISM – ONE STOP FOR ALL KIND OF VISA CHANGE SERVICES IN DUBAI",
    headingTwo:
      "INTERNATIONAL VISA SERVICES IN UAE",
    info: " We provide visa change services in Dubai.Our consultants can help you resolve any issues related to visa change services in the UAE. Our expert team will guide you through the process of visa processing.",
    CardData: [
      {
        title: "Inside Country Visa Change",
        price: "Starting @ AED 1049",
      },
      {
        title: "Airport to Airport Visa Change",
        price: "Starting @ AED 1099",
      },
      {
        title: "UAE Inside the Country Visa - 90 Days",
        price: "Starting @ AED 699",
      },
      {
        title: "Visa Change By Bus",
        price: "Starting @ AED 699",
      },
    ],
  },
];

export default VisaSerivceData;
