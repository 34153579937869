import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckList from "./CheckList";
import TourInfoData from "./tourData/TourInfoData"
import Divider from '@mui/material/Divider';
import ImageGallery from "./ImageGallery";

export default function TourInfoComponent({id}) {
    const data = TourInfoData.find((e) => e.id === id);
  return (
    <>
    {data.info.map((item)=>{
        return(

            <Box>
                <Typography
                    color={"orange"}
                    fontFamily={"poppins"}
                    fontWeight={"600"}
                    variant={"h5"}
                    component="h2"
                    // py={}
                >
                    {item.title}
                </Typography>
                <Divider sx={{backgroundColor: 'orange', width: "20%"}}/>
                <Typography
                    color={"#555555"}
                    fontFamily={"poppins"}
                    fontWeight={"200"}
                    varient={"h5"}
                    py={2}
                >
                    {item.description}
                </Typography>
            </Box>
        )
    })}

    {data.checkList.map((item)=>{
        return(
            <Box>
                <CheckList checkList={item}/>
            </Box>
        )
    })}

      {/* <Box p={2}>
        <CheckList checkList={data.checkList}/>
      </Box> */}

      <Box pt={2}>
        <ImageGallery/>
      </Box>
      
    </>
  );
}
