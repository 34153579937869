import DoneIcon from "@mui/icons-material/Done";

const TourInfoData = [
    {
        id: "Dubai City Tour",
        title: "Dubai City Tour",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Description",
            description: "One of the best ways to explore Dubai is none other than with Dubai City tour. It lets the traveler to see and explore the contrasting sides of the country – the modern side and the historical side as well. The place is famous for modern architecture and luxury that falls vast in the land as structures which is appreciable as a man-made wonder between its desert and the ocean. Along with-it people across the globe also admires the charming cultural heritage of Dubai and remarkable architectural brilliance. Dubai city tour comprises historical, modern and cultural side of Dubai. Roundtrip hotel transfers lets you to take in the region’s mix of old, iconic, and famous landmarks. Hence through this four-hour tour across the desert land of Dubai you can explore the most prominent gems of the place. Heptavision tourism provides a four-hour Dubai city tour package which shows its visitors the both historic and glittering modern side of Dubai. It is also known as Middle east’s gem. This tour will get you to a visit to Dubai Museum which is housed within the Al Fahidi Fort in 18th century. Here you can see the arts and artifacts that are displayed which indicates the ancient history and lifestyle of traditional Arabs. This ideal illustration gives a blend of outstanding Islamic culture and contemporary architecture. Please check your itinerary that you cover the most popular point of interests like Jumeirah Mosque.",
            },
            {
            title: "Itinerary",
            description: "Who don’t love to peep into the glitzy part of Dubai which is impressive part in the tour package? our itinerary similarly accommodates tour to such extra eye-catching attractions which are the points of interest of the town like Burj Al Arab Hotel – the world`s seven-star hotel this is specifically stated for its different silhouette reminiscent of a billowing sail, the Palm Island – the crescent form man-made island, Atlantis Hotel and Burj Khalifa – the towering construction had gained named for its uniqueness in the whole world. You must also see Dubai’s scenic attractions Equally noteworthy points of interest. Jumeirah Beach can be called as well-maintained photo stop where you will be completely immersed in the city’s natural beauty. The Dubai city tour ends with a drive on Sheik Zayed Road that has enforcing skyscrapers covered on its either side and a definitely go for shopping at one of the city’s distinguished malls. If you are having shortage of time, you can also go for Dubai half day city tour too. Heptavision tourism is there here by to cover all the unique requirements of its clients. Regardless whether you are a type of history buff or a freak who love to go for shopping or you are a type of person who love to have a look at the glimpse of city’s main point of tourist attraction.",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Why Should one go for Dubai city tour?",
                        list:[
                            {   
                                listItem: "Dubai city tour price is very much affordable even if it is a professionally guided tour.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "The tour program covers all the main iconic attractions of Dubai including Dubai Museum, The Palm Hotel, Palm Jumeirah, Atlantis, Jumeirah Mosque, and Burj Al Arab. ",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "You have provision to change or add the tourist spots from the itinerary as per your wish.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "With all transfers arranged, you need not worry about how to move from one attraction to another during the tour provided by us.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "We also provide prominent photo stops which allow for ample photo of yours against the favorite Dubai attraction. ",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Dubai city tour is apt for all types of people including solo travelers, first timers, and those who have shortage in time.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Main point of interest in Dubai city tour is that it allows you to experience quite a lot of attractions of the city in a limited time frame.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Personalize your Dubai itinerary to explore Dubai on a private Dubai City Tour.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Take time to cover some of Dubai’s historic corners as you can visit Dubai Museum and further make your trip to Dubai’s souks and Al Bastakiya Quarter or Neighborhood like Al Fahidi Historical.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "We offer you a fun-filled experiences at Dubai along  with entry tickets to Burj Khalifa, VR Park,  IMG Worlds of Adventure,  Laguna Water Park, Aqua venture Water Park and many more.",
                                icon: <DoneIcon />,
                            },

                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Tips to keep in mind",
                        list:[
                            {   
                                listItem: "Please note that transfers are only done from centrally situated hotels or residences in Dubai city. Some of the Places are Sheikh Zayed, Deira, Bur Dubai, Marina etc.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If the pick-up is from places other than centrally located areas then it will be proceeded with on pre request with an additional charge.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Please note that on specific off days and weekends, some of the tourist’s spot might be closed. In such cases, we are not holding any responsibility and compensations are not adjusted.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "You can have a provision of modification in pick-up/drop-off timing on Sharing transfers as per the schedule of your trip by 30 to 60 minutes, depending on traffic conditions and your location.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Please do not expect any change in the timing during Ramadan. Guests can have their lunch in Dubai Mall but you can have it only in the vehicle. Eating food or drinking is not allowed in Dubai during Ramadan in public. Dubai city tour package comes with best offers with Heptavision tourism.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Dubai Half Day City Tour Inclusions",
                        list:[
                            {   
                                listItem: "Pick up from your location (Hotel, Apartment)",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Jumeirah Mosque",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Mall of Emirates",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Dubai Mall",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Dubai aquarium (Drive Through)",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Drop off at your Location (Hotel, Apartment)",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },

    {
        id: "Abu Dhabi City Tour",
        title: "Abu Dhabi City Tour",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Description",
            description: "One of the best ways to explore Dubai is none other than with Dubai City tour. It lets the traveler to see and explore the contrasting sides of the country – the modern side and the historical side as well. The place is famous for modern architecture and luxury that falls vast in the land as structures which is appreciable as a man-made wonder between its desert and the ocean. Along with-it people across the globe also admires the charming cultural heritage of Dubai and remarkable architectural brilliance. Dubai city tour comprises historical, modern and cultural side of Dubai. Roundtrip hotel transfers lets you to take in the region’s mix of old, iconic, and famous landmarks. Hence through this four-hour tour across the desert land of Dubai you can explore the most prominent gems of the place. Heptavision tourism provides a four-hour Dubai city tour package which shows its visitors the both historic and glittering modern side of Dubai. It is also known as Middle east’s gem. This tour will get you to a visit to Dubai Museum which is housed within the Al Fahidi Fort in 18th century. Here you can see the arts and artifacts that are displayed which indicates the ancient history and lifestyle of traditional Arabs. This ideal illustration gives a blend of outstanding Islamic culture and contemporary architecture. Please check your itinerary that you cover the most popular point of interests like Jumeirah Mosque.",
            },
            {
            title: "Itinerary",
            description: "Who don’t love to peep into the glitzy part of Dubai which is impressive part in the tour package? our itinerary similarly accommodates tour to such extra eye-catching attractions which are the points of interest of the town like Burj Al Arab Hotel – the world`s seven-star hotel this is specifically stated for its different silhouette reminiscent of a billowing sail, the Palm Island – the crescent form man-made island, Atlantis Hotel and Burj Khalifa – the towering construction had gained named for its uniqueness in the whole world. You must also see Dubai’s scenic attractions Equally noteworthy points of interest. Jumeirah Beach can be called as well-maintained photo stop where you will be completely immersed in the city’s natural beauty. The Dubai city tour ends with a drive on Sheik Zayed Road that has enforcing skyscrapers covered on its either side and a definitely go for shopping at one of the city’s distinguished malls. If you are having shortage of time, you can also go for Dubai half day city tour too. Heptavision tourism is there here by to cover all the unique requirements of its clients. Regardless whether you are a type of history buff or a freak who love to go for shopping or you are a type of person who love to have a look at the glimpse of city’s main point of tourist attraction.",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Why Should one go for Dubai city tour?",
                        list:[
                            {   
                                listItem: "Dubai city tour price is very much affordable even if it is a professionally guided tour.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "The tour program covers all the main iconic attractions of Dubai including Dubai Museum, The Palm Hotel, Palm Jumeirah, Atlantis, Jumeirah Mosque, and Burj Al Arab. ",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "You have provision to change or add the tourist spots from the itinerary as per your wish.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "With all transfers arranged, you need not worry about how to move from one attraction to another during the tour provided by us.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "We also provide prominent photo stops which allow for ample photo of yours against the favorite Dubai attraction. ",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Dubai city tour is apt for all types of people including solo travelers, first timers, and those who have shortage in time.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Main point of interest in Dubai city tour is that it allows you to experience quite a lot of attractions of the city in a limited time frame.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Personalize your Dubai itinerary to explore Dubai on a private Dubai City Tour.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Take time to cover some of Dubai’s historic corners as you can visit Dubai Museum and further make your trip to Dubai’s souks and Al Bastakiya Quarter or Neighborhood like Al Fahidi Historical.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "We offer you a fun-filled experiences at Dubai along  with entry tickets to Burj Khalifa, VR Park,  IMG Worlds of Adventure,  Laguna Water Park, Aqua venture Water Park and many more.",
                                icon: <DoneIcon />,
                            },

                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Tips to keep in mind",
                        list:[
                            {   
                                listItem: "Please note that transfers are only done from centrally situated hotels or residences in Dubai city. Some of the Places are Sheikh Zayed, Deira, Bur Dubai, Marina etc.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If the pick-up is from places other than centrally located areas then it will be proceeded with on pre request with an additional charge.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Please note that on specific off days and weekends, some of the tourist’s spot might be closed. In such cases, we are not holding any responsibility and compensations are not adjusted.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "You can have a provision of modification in pick-up/drop-off timing on Sharing transfers as per the schedule of your trip by 30 to 60 minutes, depending on traffic conditions and your location.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Please do not expect any change in the timing during Ramadan. Guests can have their lunch in Dubai Mall but you can have it only in the vehicle. Eating food or drinking is not allowed in Dubai during Ramadan in public. Dubai city tour package comes with best offers with Heptavision tourism.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Dubai Half Day City Tour Inclusions",
                        list:[
                            {   
                                listItem: "Pick up from your location (Hotel, Apartment)",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Jumeirah Mosque",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Mall of Emirates",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Dubai Mall",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Dubai aquarium (Drive Through)",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Drop off at your Location (Hotel, Apartment)",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: "Sharjah City Tour - Private",
        title: "Sharjah City Tour - Private",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Description",
            description: "One of the best ways to explore Dubai is none other than with Dubai City tour. It lets the traveler to see and explore the contrasting sides of the country – the modern side and the historical side as well. The place is famous for modern architecture and luxury that falls vast in the land as structures which is appreciable as a man-made wonder between its desert and the ocean. Along with-it people across the globe also admires the charming cultural heritage of Dubai and remarkable architectural brilliance. Dubai city tour comprises historical, modern and cultural side of Dubai. Roundtrip hotel transfers lets you to take in the region’s mix of old, iconic, and famous landmarks. Hence through this four-hour tour across the desert land of Dubai you can explore the most prominent gems of the place. Heptavision tourism provides a four-hour Dubai city tour package which shows its visitors the both historic and glittering modern side of Dubai. It is also known as Middle east’s gem. This tour will get you to a visit to Dubai Museum which is housed within the Al Fahidi Fort in 18th century. Here you can see the arts and artifacts that are displayed which indicates the ancient history and lifestyle of traditional Arabs. This ideal illustration gives a blend of outstanding Islamic culture and contemporary architecture. Please check your itinerary that you cover the most popular point of interests like Jumeirah Mosque.",
            },
            {
            title: "Itinerary",
            description: "Who don’t love to peep into the glitzy part of Dubai which is impressive part in the tour package? our itinerary similarly accommodates tour to such extra eye-catching attractions which are the points of interest of the town like Burj Al Arab Hotel – the world`s seven-star hotel this is specifically stated for its different silhouette reminiscent of a billowing sail, the Palm Island – the crescent form man-made island, Atlantis Hotel and Burj Khalifa – the towering construction had gained named for its uniqueness in the whole world. You must also see Dubai’s scenic attractions Equally noteworthy points of interest. Jumeirah Beach can be called as well-maintained photo stop where you will be completely immersed in the city’s natural beauty. The Dubai city tour ends with a drive on Sheik Zayed Road that has enforcing skyscrapers covered on its either side and a definitely go for shopping at one of the city’s distinguished malls. If you are having shortage of time, you can also go for Dubai half day city tour too. Heptavision tourism is there here by to cover all the unique requirements of its clients. Regardless whether you are a type of history buff or a freak who love to go for shopping or you are a type of person who love to have a look at the glimpse of city’s main point of tourist attraction.",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Why Should one go for Dubai city tour?",
                        list:[
                            {   
                                listItem: "Dubai city tour price is very much affordable even if it is a professionally guided tour.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "The tour program covers all the main iconic attractions of Dubai including Dubai Museum, The Palm Hotel, Palm Jumeirah, Atlantis, Jumeirah Mosque, and Burj Al Arab. ",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "You have provision to change or add the tourist spots from the itinerary as per your wish.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "With all transfers arranged, you need not worry about how to move from one attraction to another during the tour provided by us.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "We also provide prominent photo stops which allow for ample photo of yours against the favorite Dubai attraction. ",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Dubai city tour is apt for all types of people including solo travelers, first timers, and those who have shortage in time.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Main point of interest in Dubai city tour is that it allows you to experience quite a lot of attractions of the city in a limited time frame.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Personalize your Dubai itinerary to explore Dubai on a private Dubai City Tour.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Take time to cover some of Dubai’s historic corners as you can visit Dubai Museum and further make your trip to Dubai’s souks and Al Bastakiya Quarter or Neighborhood like Al Fahidi Historical.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "We offer you a fun-filled experiences at Dubai along  with entry tickets to Burj Khalifa, VR Park,  IMG Worlds of Adventure,  Laguna Water Park, Aqua venture Water Park and many more.",
                                icon: <DoneIcon />,
                            },

                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Tips to keep in mind",
                        list:[
                            {   
                                listItem: "Please note that transfers are only done from centrally situated hotels or residences in Dubai city. Some of the Places are Sheikh Zayed, Deira, Bur Dubai, Marina etc.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If the pick-up is from places other than centrally located areas then it will be proceeded with on pre request with an additional charge.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Please note that on specific off days and weekends, some of the tourist’s spot might be closed. In such cases, we are not holding any responsibility and compensations are not adjusted.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "You can have a provision of modification in pick-up/drop-off timing on Sharing transfers as per the schedule of your trip by 30 to 60 minutes, depending on traffic conditions and your location.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Please do not expect any change in the timing during Ramadan. Guests can have their lunch in Dubai Mall but you can have it only in the vehicle. Eating food or drinking is not allowed in Dubai during Ramadan in public. Dubai city tour package comes with best offers with Heptavision tourism.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Dubai Half Day City Tour Inclusions",
                        list:[
                            {   
                                listItem: "Pick up from your location (Hotel, Apartment)",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Jumeirah Mosque",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Mall of Emirates",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Dubai Mall",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Dubai aquarium (Drive Through)",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Drop off at your Location (Hotel, Apartment)",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },


    {
        id: "Dubai Desert Safari",
        title: "Dubai Desert Safari",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Description",
            description: "Dubai is the best place to visit for adventurous activities like camel trek through dessert.Dessert safari  vary in terms of duration and what activities are included.",
            },
            {
            title: "Add-ons in Dubai Desert safari",
            description: "Desert safari with quad bike or add this ATV ride as an optional upgrade . We arrange a stay at our own campsite in Al Awir as well as a Hatta Mountain Safari and a Red Dune Safari that takes guests to the alluring red sands of the Al-Lahbab region.",
            },
            {
                title: "Dubai Desert Safari Itinerary",
                description: "Heptavision tourism arrange the best desert safari  within your budget.",
                }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Desert Safari Inclusion",
                        list:[
                            {   
                                listItem: "Pick-up and drop off at your  hotel/ location",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Transportation by 4WD air-conditioned Land Cruiser",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Dune bashing (30-45 minutes)",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Sandboarding (upon request)",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Sunset photo stop",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Gaymat (sweets), Gahwa (coffee) & Dates",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Henna painting for ladies",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Few minutes camel ride (can be repeated)",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Shisha pipe (hubble-bubble flavour) permitted at shisha area only",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Shared camp",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Tanoura and Belly dance",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited soft drinks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Barbecue meal with vegetarian and non-vegetarian options",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cold mineral water in the vehicle during the transfer",
                                icon: <DoneIcon />,
                            },

                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Dubai Evening Desert Safari Timings",
                        list:[
                            {   
                                listItem: "Duration: 7-8 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
        ]
    },

    {
        id: "Helicopter Ride Dubai",
        title: "Helicopter Ride Dubai",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Description",
            description: "Get an amazing aerial view of Dubai.Book a helicopter ride and take off from the city for views of Burj Khalifa ,Burj AL Arab, Jumeirah Beach coastline . Don't miss an unforgettable experience taking in aerial view of the amazing Dubai skyline and its nice coastline from high up in the sky.",
            },
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Helicopter Ride Inclusion",
                        list:[
                            {   
                                listItem: "On the 22 minute ride, we fly over the Atlantis Hotel, the historic Dubai Creek, the Burj Al Arab Hotel, the Burj Khalifa, the Dubai Marina, the Madinat Jumeirah, the Palm Island, the World Islands, Dubai Downtown.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "On the 12 minutes ride ,we fly over the Sheikh Rashid Palace, the Burj Khalifa, Downtown Dubai, Port Rashid, Corniche, Jabeel Palace and Dubai Creek.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "On the 40 minutes  ride , we fly over  Ras al Khor Wildlife Sanctuary, Meydan Race Course, Burj Khalifa, the JW Marriott, Emirates Tower 2, the Dubai Mall, the Burj Al Arab, the Palm Jumeirah, and the iconic Atlantis Hotel.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "On the 15 minutes ride , we fly over Dubai's iconic manmade island to the tallest tower ,  Atlantis ,Burj Khalifa,Aquaventure Park,  Burj Al Arab, the Jumeirah Beach Coastline and the World Islands from its south.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "We offer  pick up and drop facilities.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Helicopter Ride Important Information",
                        list:[
                            {   
                                listItem: "Acknowledge the confirmation number sent to your mail id and contact number after booking when asked for Insurance is mandatory under the regulations of UAE Civil Aviation Authorisation for everyone.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "A passport is mandatory",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Any consumption of food or beverage and smoking is prohibited during the ride.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Only for children above 2.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Pregnant women are allowed as they are into 32 weeks or less of pregnancy.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "The routes depend on safety and traffic regulations.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "No refund",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: " Rescheduling for late arrivals.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: " Airport pick up.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Language of communication- English,Hindi",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Booking Policy",
                        list:[
                            {   
                                listItem: "No cancellation charges for cancellations within 72  hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Your refund shall be credited to your bank account within 7 working days if you are eligible for one",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "No concessions are available for children",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Conclusion",
                        list:[
                            {   
                                listItem: "Helicopter ride  in Dubai makes for a truly unforgettable experience in life.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Helicopter Ride Timings",
                        list:[
                            {   
                                listItem: "Duration: DEPENDS ON TICKET SELECTION",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 72 Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
        ]
    },


    {
        id: "6 Emirates Tour",
        title: "6 Emirates Tour",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "6 Emirates Tour Overview",
            description: "Heptavision Tourism gives you the chance to visit the best UAE attractions on a six Emirates tour. This special six Emirates holiday package will be the most enjoyable trip of your life. we’ll provide you with a visit to the most popular and  historical places. Reach out to us for an Emirates trip in Dubai to plan the most exciting part of your visit. Heptavision tourism offers you all the best services.",
            },
            {
                title: "Itinerary",
                description: "Heptavision tourism offers the best six emirates tours within your budget. If you want to see the 6 emirates then the best time is at night  as the temperature is comfortable. We will provide various offers and discounts ,so hurry up and book the trip.",
                },
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Included",
                        list:[
                            {   
                                listItem: "A pickup facility either from your residence or hotel in Dubai.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Sharjah Corniche & Qanat Al Qasba.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Views of Fishmarket & Central Souq.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Drive through Quran roundabout & Ajman beach.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "RAK Queen Palace & the Bidiya Mosque.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Fujairah Museum.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Friday Market.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Khorfkhan Beach.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Drive by the side of mountains.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Fujairah Fort.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "A drop facility will be available.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Timings",
                        list:[
                            {   
                                listItem: "Duration: 10 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
        ]
    },

    {
        id: "Ekart Zabeel Dubai",
        title: "Ekart Zabeel Dubai",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Description",
            description: "If you love to race then Hurry up and Book the race . Its  track is complete with indoor and outdoor circuits that feature high-tech karts. You can enjoy high speeds on the 1.2 km track . Take a ride on over 10 karts at the go-karting center Kartdrome in Dubai, UAE.This indoor circuit has a high-speed tunnel, small bridge. If you’re no racing veteran, then you might want to check out rental sessions.",
            },
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Karting Dubai Inclusion",
                        list:[
                            {   
                                listItem: "Timed session",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Jumpsuit & helmet available",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Lap time recorded & shared",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "Free Cancellations within 48 hours prior to the Tour departure time.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If you are eligible for Refund your Amount will be returned back to your account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
        ]
    },

    {
        id: "Dhow Cruise Marina",
        title: "Dhow Cruise Marina",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Description",
            description: "It will be a unique experience having a look around in the traditional wooden dhow by viewing a fascinating part of modern Dubai. Dinner on a slow cruise away from daily routine amid the breath-taking waters of Dhow cruise Creek, with a buffet , is an experience recognized by incredible milieu, nice hospitality, and great services with perfect arrangements of Soft beverages and refreshments with  access to a completely cooled lower deck and the outside upper deck.",
            },
            {
                title: "Itinerary",
                description: "Heptavision tourism provides the best Dhow cruise marina tour within your budget. We also provide discounts on cruise marina tours."
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Why Should You Go Cruise?",
                        list:[
                            {   
                                listItem: "This is one of the best experiences in Dubai Marina.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "This is a two-hour cruise aboard a traditional wooden vessel, known as dhow. In the old days, it played an important role to boost the region’s economy and was used for pearl farming and fishing.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: " You can find some time to chill out around the water.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "You can go for an air-conditioned lower deck or partly open upper deck.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Enjoy the royal style buffet dining accompanied by starters, main course specialties, and finger licking desserts.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Both vegetarian and non-vegetarian foods are served on the cruise",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "You can also watch the traditional entertainment show and performance of Tanura artists onboard and a funny puppet show.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Kids will love this experience around the water.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Dhow Cruise Inclusion",
                        list:[
                            {   
                                listItem: "Cruising to Marina",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Welcome Drinks, Kahwa & Dates",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Mineral Water & Variety of Soft Drinks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "International Buffet Dinner: Continental",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Variety of Vege & Non-Veg Dishes",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Upper Deck with Open Air Seating",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Lower Deck with Air Conditioned Seating",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "English, Arabian & Indian Background Music",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited Refreshment Drinks Available",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Live Arabic Tanoura Dance Show",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Dhow Cruise in Marina Dubai Timings",
                        list:[
                            {   
                                listItem: "Duration: 3 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
        ]
    },

    {
        id: "Dhow Cruise Creek",
        title: "Dhow Cruise Creek Dubai Tour Overview",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Description",
            description: "We offer you a 90-minutes dhow cruise Creek along with the buffet meal and  entertaining program. Dinner on a slow cruise away from daily routine amid the breath-taking waters of Dhow cruise Creek, with a buffet , is an experience recognized by incredible milieu, nice hospitality, and great services with perfect arrangements of Soft beverages and refreshments with  access to a completely cooled lower deck and the outside upper deck.",
            },
            {
                title: "Itinerary",
                description: "Heptavision tourism provides the best Dhow cruise creek tour within your budget. We also provide discounts on cruise creek."
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Why Should You Go Cruise?",
                        list:[
                            {   
                                listItem: "This is one of the best experiences in Dubai Creek.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "This is a two-hour cruise aboard a traditional wooden vessel, known as dhow. In the old days, it played an important role to boost the region’s economy and was used for pearl farming and fishing.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: " You can find some time to chill out around the water.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "You can go for an air-conditioned lower deck or partly open upper deck.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Enjoy the royal style buffet dining accompanied by starters, main course specialties, and finger licking desserts. ",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Both vegetarian and non-vegetarian foods are served on the cruise",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "You can also watch the traditional entertainment show and performance of Tanura artists onboard and a funny puppet show.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Kids will love this experience around the water.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Inclusions",
                        list:[
                            {   
                                listItem: "We offer you a 90 minutes cruise near the Deira creek area and Bur Dubai.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "We also offer our special welcome drinks, water, tea & coffee.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "International buffet dinner with a wide choice of vegetarian and non-vegetarian dishes.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Separate Washroom facilities for men and women ",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Tanura show and soft background music",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Puppet show",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Pick up & Drop Off Facility.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Dhow cruise creek Timings",
                        list:[
                            {   
                                listItem: "Duration: 3 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
        ]
    },

    {
        id: "Dubai Canal Cruise",
        title: "Dubai Canal Water Cruise Overview",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Description",
            description: "The most famous and the newest attraction  is Dubai Canal Cruise in Dubai. Heptavision Tourism offers you a special tour in which you can enjoy a cruise ride with views of the most beautiful Dubai skyline and the  tallest building from the newly opened canal.",
            },
            {
                title: "Itinerary",
                description: "Heptavision tourism provides the best Canal Water cruise marina tour within your budget. We also provide discounts on canal cruise  tours."
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Canal Cruise Inclusion",
                        list:[
                            {   
                                listItem: "Pick from your Apartment, Hotel, Resident",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "2 Hours Cruising in Downtown Dubai",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: " Fully Air Conditioned Lower Deck and Breezy Open Air Deck",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Access to Lower Deck and Open Air",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Upper Deck ",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Red Carpet Welcome with Drinks, Dates & Coffee",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Arabic Sweets & Fresh Fruits",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cold Drink, Soft Juice, Water Bottle",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "5 Star International Buffet including live stations,",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Arabic appetizers, grilled meats, fresh salads, delicious Arabic sweets",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Tanura / Egyptian Dance Show by Professional artist",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Family Entertainment",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Drop back your location",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },


            {
                item:[
                    {
                        title: "Canal Cruise Timings",
                        list:[
                            {   
                                listItem: "Duration: 3 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
        ]
    },

    {
        id: "Musandam Dibba Tour",
        title: "Musandam Dibba Tour",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Musandam Dibba Tour Overview",
            description: "Musandam Dibba tour is a wonderful place for nature lovers.We offer you the best Musandam sea safari trip packages at a reasonable price and make your trip memorable, enjoyable.Our Musandam sea safari tour packages are full of activities like swimming, snorkeling, hand line fishing and also you can watch marine life like dolphins and fish.",
            },
            {
                title: "Itinerary",
                description: "Heptavision tourism provides the best Musandam dibba tour within your budget. We also provide discounts on Musandam tours."
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Musandam Dibba Tour Inclusions",
                        list:[
                            {   
                                listItem: "Pickup from your hotel or residence in Dubai.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Full-Day Cruising.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Refreshment/ Soft Drinks/ Water during the tour.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Snorkeling with equipment.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Fishing with equipment.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Banana Boat ride.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Lunch.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Visa charges.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Drop back to your hotel or residence in Dubai.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },


            {
                item:[
                    {
                        title: "Canal Cruise Timings",
                        list:[
                            {   
                                listItem: "Duration: 13 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
        ]
    },

    {
        id: "Dubai City Tour + Burj Khalifa",
        title: "Dubai City Tour + Burj Khalifa",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Dubai city tour with Burj Khalifa Overview",
            description: "Heptavision Tourism provides  an great view of the stunning skyscraper through its exclusive SkyHigh Tower tours. Located in the sophisticated Downtown Dubai, this attraction is ideal for first-time visitors to Dubai. On a guided Dubai city tour, visit  Dubai Museum, drive along Sheik Zayed Road, and see iconic sites like Burj Al Arab, Palm Jumeirah, Atlantis The Palm, and Jumeirah Mosque.",
            },
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Dubai City Tour With Burj Khalifa Combo Ticket Includes",
                        list:[
                            {   
                                listItem: "Ticket to Burj Khalifa",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Dubai city Tour with Guide",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Pick up and Drop Facility",
                                icon: <DoneIcon />,
                            },
                            
                        ]
                    }
                ]
            },


            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "Cancellation within 24 hours before the start of the trip will not be charged any fees.",
                                icon: <DoneIcon />,
                            },
                        ]
                    },
                ]
            },

            {
                item:[
                    {
                        title: "Child Policy",
                        list:[
                            {   
                                listItem: "Children under 3 years old are considered Infants and admission is free.",
                                icon: <DoneIcon />,
                            },
                            {   
                                listItem: "Children between 3 and 10 years old are considered children and Charged the child rate.",
                                icon: <DoneIcon />,
                            },{   
                                listItem: "Children over 10 years old are considered as adults and charged at adult rates.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },

            {
                item:[
                    {
                        title: "Dubai City Tour With Burj Khalifa Timings",
                        list:[
                            {   
                                listItem: "Duration: 4 Hours",
                                icon: <DoneIcon />,
                            },
                            {   
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {   
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {   
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            
        ]
    },

    {
        id: "Ekart Zabeel Dubai",
        title: "Ekart Zabeel Dubai",
        adultRate: "AED 89 (Per Person)",
        childRate: "AED 79 (Per Person)",
        duration: "4 Hours",
        pickup: "09:30 - 10:00 AM",
        dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "Description",
            description: "If you love to race then Hurry up and Book the race . Its  track is complete with indoor and outdoor circuits that feature high-tech karts. You can enjoy high speeds on the 1.2 km track . Take a ride on over 10 karts at the go-karting center Kartdrome in Dubai, UAE.This indoor circuit has a high-speed tunnel, small bridge. If you’re no racing veteran, then you might want to check out rental sessions.",
            },
        ],
        checkList: [

            {
                item:[
                    {
                        title: "Karting Dubai Inclusion",
                        list:[
                            {   
                                listItem: "Timed session",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Jumpsuit & helmet available",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Lap time recorded & shared",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "Free Cancellations within 48 hours prior to the Tour departure time.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If you are eligible for Refund your Amount will be returned back to your account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
        ]
    },


    {
        id: "IMG World Ticket",
        title: "IMG World Ticket",
        // adultRate: "AED 89 (Per Person)",
        // childRate: "AED 79 (Per Person)",
        // duration: "4 Hours",
        // pickup: "09:30 - 10:00 AM",
        // dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "IMG World Overview",
            description: "one of the newest attractions for families and groups traveling to Dubai. Being the world’s largest indoor theme park, IMG World features over 1.5 million square feet of thrilling attractions and activities. It is the region’s first mega themed attraction, featuring diverse adventure zones like Marvel, Lost Valley, Cartoon Network, IMG Boulevard, and Novo cinemas.",
            },
            {
            title: "Dubai's Indoor Theme Parks Highlights",
            description: "IMG Worlds of Adventure is Dubai’s first amusement park with mega indoor entertainment destinations. Heptavision Tourism Offers visit to the Biggest Temperature Controlled Park, Spare a day to Unlimited access to the Mesmerizing rides and attractions like Avengers Battle of Ultron Hulk Epsilon Base 3DSpider – Man Doc Ock’s RevengeThor Thunder SpinAvengers Flight of the Quinjets LOST VALLEY Territory Predator Dino CARTOON NETWORK: The Powerpuff Girls – Mojo Jojo’s Rampage! Ben 10 5D Hero Time – The BOULEVARD: The Haunted Hotel",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "IMG World Included",
                        list:[
                            {   
                                listItem: "Entry to the IMG World Of Adventures.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Entry ticket to one of the world’s biggest indoor theme parks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "See live entertainment shows",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "IMG World Timings",
                        list:[
                            {   
                                listItem: "Duration: 9 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "All Cancellations made 24 hours prior to the Tour departure time NO charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If Cancellation made within 24 hours to your Tour departure time 100% charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If eligible for Refund your Amount will be returned back to your Account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Child Policy",
                        list:[
                            {   
                                listItem: "Children below 3 years and under 1.05 meters height will be considered as infant and entry will be free of cost.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "2 meters are considered children and a child rate is charged.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Children over 1.2 meters tall are considered adults and the adult rate will be charged.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },



    {
        id: "Dubai Mall Aquarium",
        title: "Dubai Mall Aquarium",
        // adultRate: "AED 89 (Per Person)",
        // childRate: "AED 79 (Per Person)",
        // duration: "4 Hours",
        // pickup: "09:30 - 10:00 AM",
        // dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "IMG World Overview",
            description: "one of the newest attractions for families and groups traveling to Dubai. Being the world’s largest indoor theme park, IMG World features over 1.5 million square feet of thrilling attractions and activities. It is the region’s first mega themed attraction, featuring diverse adventure zones like Marvel, Lost Valley, Cartoon Network, IMG Boulevard, and Novo cinemas.",
            },
            {
            title: "Dubai's Indoor Theme Parks Highlights",
            description: "IMG Worlds of Adventure is Dubai’s first amusement park with mega indoor entertainment destinations. Heptavision Tourism Offers visit to the Biggest Temperature Controlled Park, Spare a day to Unlimited access to the Mesmerizing rides and attractions like Avengers Battle of Ultron Hulk Epsilon Base 3DSpider – Man Doc Ock’s RevengeThor Thunder SpinAvengers Flight of the Quinjets LOST VALLEY Territory Predator Dino CARTOON NETWORK: The Powerpuff Girls – Mojo Jojo’s Rampage! Ben 10 5D Hero Time – The BOULEVARD: The Haunted Hotel",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "IMG World Included",
                        list:[
                            {   
                                listItem: "Entry to the IMG World Of Adventures.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Entry ticket to one of the world’s biggest indoor theme parks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "See live entertainment shows",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "IMG World Timings",
                        list:[
                            {   
                                listItem: "Duration: 9 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "All Cancellations made 24 hours prior to the Tour departure time NO charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If Cancellation made within 24 hours to your Tour departure time 100% charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If eligible for Refund your Amount will be returned back to your Account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Child Policy",
                        list:[
                            {   
                                listItem: "Children below 3 years and under 1.05 meters height will be considered as infant and entry will be free of cost.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "2 meters are considered children and a child rate is charged.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Children over 1.2 meters tall are considered adults and the adult rate will be charged.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },


    {
        id: "Ski Dubai Ticket",
        title: "Dubai Mall Aquarium",
        // adultRate: "AED 89 (Per Person)",
        // childRate: "AED 79 (Per Person)",
        // duration: "4 Hours",
        // pickup: "09:30 - 10:00 AM",
        // dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "IMG World Overview",
            description: "one of the newest attractions for families and groups traveling to Dubai. Being the world’s largest indoor theme park, IMG World features over 1.5 million square feet of thrilling attractions and activities. It is the region’s first mega themed attraction, featuring diverse adventure zones like Marvel, Lost Valley, Cartoon Network, IMG Boulevard, and Novo cinemas.",
            },
            {
            title: "Dubai's Indoor Theme Parks Highlights",
            description: "IMG Worlds of Adventure is Dubai’s first amusement park with mega indoor entertainment destinations. Heptavision Tourism Offers visit to the Biggest Temperature Controlled Park, Spare a day to Unlimited access to the Mesmerizing rides and attractions like Avengers Battle of Ultron Hulk Epsilon Base 3DSpider – Man Doc Ock’s RevengeThor Thunder SpinAvengers Flight of the Quinjets LOST VALLEY Territory Predator Dino CARTOON NETWORK: The Powerpuff Girls – Mojo Jojo’s Rampage! Ben 10 5D Hero Time – The BOULEVARD: The Haunted Hotel",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "IMG World Included",
                        list:[
                            {   
                                listItem: "Entry to the IMG World Of Adventures.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Entry ticket to one of the world’s biggest indoor theme parks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "See live entertainment shows",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "IMG World Timings",
                        list:[
                            {   
                                listItem: "Duration: 9 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "All Cancellations made 24 hours prior to the Tour departure time NO charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If Cancellation made within 24 hours to your Tour departure time 100% charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If eligible for Refund your Amount will be returned back to your Account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Child Policy",
                        list:[
                            {   
                                listItem: "Children below 3 years and under 1.05 meters height will be considered as infant and entry will be free of cost.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "2 meters are considered children and a child rate is charged.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Children over 1.2 meters tall are considered adults and the adult rate will be charged.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },


    {
        id: "Dolphinarium Tickets",
        title: "Dolphinarium Tickets",
        // adultRate: "AED 89 (Per Person)",
        // childRate: "AED 79 (Per Person)",
        // duration: "4 Hours",
        // pickup: "09:30 - 10:00 AM",
        // dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "IMG World Overview",
            description: "one of the newest attractions for families and groups traveling to Dubai. Being the world’s largest indoor theme park, IMG World features over 1.5 million square feet of thrilling attractions and activities. It is the region’s first mega themed attraction, featuring diverse adventure zones like Marvel, Lost Valley, Cartoon Network, IMG Boulevard, and Novo cinemas.",
            },
            {
            title: "Dubai's Indoor Theme Parks Highlights",
            description: "IMG Worlds of Adventure is Dubai’s first amusement park with mega indoor entertainment destinations. Heptavision Tourism Offers visit to the Biggest Temperature Controlled Park, Spare a day to Unlimited access to the Mesmerizing rides and attractions like Avengers Battle of Ultron Hulk Epsilon Base 3DSpider – Man Doc Ock’s RevengeThor Thunder SpinAvengers Flight of the Quinjets LOST VALLEY Territory Predator Dino CARTOON NETWORK: The Powerpuff Girls – Mojo Jojo’s Rampage! Ben 10 5D Hero Time – The BOULEVARD: The Haunted Hotel",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "IMG World Included",
                        list:[
                            {   
                                listItem: "Entry to the IMG World Of Adventures.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Entry ticket to one of the world’s biggest indoor theme parks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "See live entertainment shows",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "IMG World Timings",
                        list:[
                            {   
                                listItem: "Duration: 9 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "All Cancellations made 24 hours prior to the Tour departure time NO charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If Cancellation made within 24 hours to your Tour departure time 100% charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If eligible for Refund your Amount will be returned back to your Account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Child Policy",
                        list:[
                            {   
                                listItem: "Children below 3 years and under 1.05 meters height will be considered as infant and entry will be free of cost.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "2 meters are considered children and a child rate is charged.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Children over 1.2 meters tall are considered adults and the adult rate will be charged.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },


    {
        id: "Lego land Ticket",
        title: "Lego land Ticket",
        // adultRate: "AED 89 (Per Person)",
        // childRate: "AED 79 (Per Person)",
        // duration: "4 Hours",
        // pickup: "09:30 - 10:00 AM",
        // dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "IMG World Overview",
            description: "one of the newest attractions for families and groups traveling to Dubai. Being the world’s largest indoor theme park, IMG World features over 1.5 million square feet of thrilling attractions and activities. It is the region’s first mega themed attraction, featuring diverse adventure zones like Marvel, Lost Valley, Cartoon Network, IMG Boulevard, and Novo cinemas.",
            },
            {
            title: "Dubai's Indoor Theme Parks Highlights",
            description: "IMG Worlds of Adventure is Dubai’s first amusement park with mega indoor entertainment destinations. Heptavision Tourism Offers visit to the Biggest Temperature Controlled Park, Spare a day to Unlimited access to the Mesmerizing rides and attractions like Avengers Battle of Ultron Hulk Epsilon Base 3DSpider – Man Doc Ock’s RevengeThor Thunder SpinAvengers Flight of the Quinjets LOST VALLEY Territory Predator Dino CARTOON NETWORK: The Powerpuff Girls – Mojo Jojo’s Rampage! Ben 10 5D Hero Time – The BOULEVARD: The Haunted Hotel",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "IMG World Included",
                        list:[
                            {   
                                listItem: "Entry to the IMG World Of Adventures.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Entry ticket to one of the world’s biggest indoor theme parks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "See live entertainment shows",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "IMG World Timings",
                        list:[
                            {   
                                listItem: "Duration: 9 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "All Cancellations made 24 hours prior to the Tour departure time NO charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If Cancellation made within 24 hours to your Tour departure time 100% charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If eligible for Refund your Amount will be returned back to your Account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Child Policy",
                        list:[
                            {   
                                listItem: "Children below 3 years and under 1.05 meters height will be considered as infant and entry will be free of cost.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "2 meters are considered children and a child rate is charged.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Children over 1.2 meters tall are considered adults and the adult rate will be charged.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },


    {
        id: "Burj Khalifa Ticket",
        title: "Burj Khalifa Ticket",
        // adultRate: "AED 89 (Per Person)",
        // childRate: "AED 79 (Per Person)",
        // duration: "4 Hours",
        // pickup: "09:30 - 10:00 AM",
        // dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "IMG World Overview",
            description: "one of the newest attractions for families and groups traveling to Dubai. Being the world’s largest indoor theme park, IMG World features over 1.5 million square feet of thrilling attractions and activities. It is the region’s first mega themed attraction, featuring diverse adventure zones like Marvel, Lost Valley, Cartoon Network, IMG Boulevard, and Novo cinemas.",
            },
            {
            title: "Dubai's Indoor Theme Parks Highlights",
            description: "IMG Worlds of Adventure is Dubai’s first amusement park with mega indoor entertainment destinations. Heptavision Tourism Offers visit to the Biggest Temperature Controlled Park, Spare a day to Unlimited access to the Mesmerizing rides and attractions like Avengers Battle of Ultron Hulk Epsilon Base 3DSpider – Man Doc Ock’s RevengeThor Thunder SpinAvengers Flight of the Quinjets LOST VALLEY Territory Predator Dino CARTOON NETWORK: The Powerpuff Girls – Mojo Jojo’s Rampage! Ben 10 5D Hero Time – The BOULEVARD: The Haunted Hotel",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "IMG World Included",
                        list:[
                            {   
                                listItem: "Entry to the IMG World Of Adventures.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Entry ticket to one of the world’s biggest indoor theme parks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "See live entertainment shows",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "IMG World Timings",
                        list:[
                            {   
                                listItem: "Duration: 9 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "All Cancellations made 24 hours prior to the Tour departure time NO charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If Cancellation made within 24 hours to your Tour departure time 100% charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If eligible for Refund your Amount will be returned back to your Account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Child Policy",
                        list:[
                            {   
                                listItem: "Children below 3 years and under 1.05 meters height will be considered as infant and entry will be free of cost.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "2 meters are considered children and a child rate is charged.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Children over 1.2 meters tall are considered adults and the adult rate will be charged.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },


    {
        id: "Wild wadi water park",
        title: "Wild wadi water park",
        // adultRate: "AED 89 (Per Person)",
        // childRate: "AED 79 (Per Person)",
        // duration: "4 Hours",
        // pickup: "09:30 - 10:00 AM",
        // dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "IMG World Overview",
            description: "one of the newest attractions for families and groups traveling to Dubai. Being the world’s largest indoor theme park, IMG World features over 1.5 million square feet of thrilling attractions and activities. It is the region’s first mega themed attraction, featuring diverse adventure zones like Marvel, Lost Valley, Cartoon Network, IMG Boulevard, and Novo cinemas.",
            },
            {
            title: "Dubai's Indoor Theme Parks Highlights",
            description: "IMG Worlds of Adventure is Dubai’s first amusement park with mega indoor entertainment destinations. Heptavision Tourism Offers visit to the Biggest Temperature Controlled Park, Spare a day to Unlimited access to the Mesmerizing rides and attractions like Avengers Battle of Ultron Hulk Epsilon Base 3DSpider – Man Doc Ock’s RevengeThor Thunder SpinAvengers Flight of the Quinjets LOST VALLEY Territory Predator Dino CARTOON NETWORK: The Powerpuff Girls – Mojo Jojo’s Rampage! Ben 10 5D Hero Time – The BOULEVARD: The Haunted Hotel",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "IMG World Included",
                        list:[
                            {   
                                listItem: "Entry to the IMG World Of Adventures.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Entry ticket to one of the world’s biggest indoor theme parks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "See live entertainment shows",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "IMG World Timings",
                        list:[
                            {   
                                listItem: "Duration: 9 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "All Cancellations made 24 hours prior to the Tour departure time NO charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If Cancellation made within 24 hours to your Tour departure time 100% charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If eligible for Refund your Amount will be returned back to your Account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Child Policy",
                        list:[
                            {   
                                listItem: "Children below 3 years and under 1.05 meters height will be considered as infant and entry will be free of cost.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "2 meters are considered children and a child rate is charged.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Children over 1.2 meters tall are considered adults and the adult rate will be charged.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },


    {
        id: "Atlantis Aquaventure & Lost Chambers",
        title: "Atlantis Aquaventure & Lost Chambers",
        // adultRate: "AED 89 (Per Person)",
        // childRate: "AED 79 (Per Person)",
        // duration: "4 Hours",
        // pickup: "09:30 - 10:00 AM",
        // dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "IMG World Overview",
            description: "one of the newest attractions for families and groups traveling to Dubai. Being the world’s largest indoor theme park, IMG World features over 1.5 million square feet of thrilling attractions and activities. It is the region’s first mega themed attraction, featuring diverse adventure zones like Marvel, Lost Valley, Cartoon Network, IMG Boulevard, and Novo cinemas.",
            },
            {
            title: "Dubai's Indoor Theme Parks Highlights",
            description: "IMG Worlds of Adventure is Dubai’s first amusement park with mega indoor entertainment destinations. Heptavision Tourism Offers visit to the Biggest Temperature Controlled Park, Spare a day to Unlimited access to the Mesmerizing rides and attractions like Avengers Battle of Ultron Hulk Epsilon Base 3DSpider – Man Doc Ock’s RevengeThor Thunder SpinAvengers Flight of the Quinjets LOST VALLEY Territory Predator Dino CARTOON NETWORK: The Powerpuff Girls – Mojo Jojo’s Rampage! Ben 10 5D Hero Time – The BOULEVARD: The Haunted Hotel",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "IMG World Included",
                        list:[
                            {   
                                listItem: "Entry to the IMG World Of Adventures.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Entry ticket to one of the world’s biggest indoor theme parks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "See live entertainment shows",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "IMG World Timings",
                        list:[
                            {   
                                listItem: "Duration: 9 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "All Cancellations made 24 hours prior to the Tour departure time NO charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If Cancellation made within 24 hours to your Tour departure time 100% charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If eligible for Refund your Amount will be returned back to your Account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Child Policy",
                        list:[
                            {   
                                listItem: "Children below 3 years and under 1.05 meters height will be considered as infant and entry will be free of cost.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "2 meters are considered children and a child rate is charged.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Children over 1.2 meters tall are considered adults and the adult rate will be charged.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },


    {
        id: "Dubai Parks and Resorts",
        title: "Dubai Parks and Resorts",
        // adultRate: "AED 89 (Per Person)",
        // childRate: "AED 79 (Per Person)",
        // duration: "4 Hours",
        // pickup: "09:30 - 10:00 AM",
        // dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "IMG World Overview",
            description: "one of the newest attractions for families and groups traveling to Dubai. Being the world’s largest indoor theme park, IMG World features over 1.5 million square feet of thrilling attractions and activities. It is the region’s first mega themed attraction, featuring diverse adventure zones like Marvel, Lost Valley, Cartoon Network, IMG Boulevard, and Novo cinemas.",
            },
            {
            title: "Dubai's Indoor Theme Parks Highlights",
            description: "IMG Worlds of Adventure is Dubai’s first amusement park with mega indoor entertainment destinations. Heptavision Tourism Offers visit to the Biggest Temperature Controlled Park, Spare a day to Unlimited access to the Mesmerizing rides and attractions like Avengers Battle of Ultron Hulk Epsilon Base 3DSpider – Man Doc Ock’s RevengeThor Thunder SpinAvengers Flight of the Quinjets LOST VALLEY Territory Predator Dino CARTOON NETWORK: The Powerpuff Girls – Mojo Jojo’s Rampage! Ben 10 5D Hero Time – The BOULEVARD: The Haunted Hotel",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "IMG World Included",
                        list:[
                            {   
                                listItem: "Entry to the IMG World Of Adventures.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Entry ticket to one of the world’s biggest indoor theme parks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "See live entertainment shows",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "IMG World Timings",
                        list:[
                            {   
                                listItem: "Duration: 9 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "All Cancellations made 24 hours prior to the Tour departure time NO charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If Cancellation made within 24 hours to your Tour departure time 100% charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If eligible for Refund your Amount will be returned back to your Account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Child Policy",
                        list:[
                            {   
                                listItem: "Children below 3 years and under 1.05 meters height will be considered as infant and entry will be free of cost.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "2 meters are considered children and a child rate is charged.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Children over 1.2 meters tall are considered adults and the adult rate will be charged.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },


    {
        id: "Ferrari world Abu Dhabi Ticket",
        title: "Ferrari world Abu Dhabi Ticket",
        // adultRate: "AED 89 (Per Person)",
        // childRate: "AED 79 (Per Person)",
        // duration: "4 Hours",
        // pickup: "09:30 - 10:00 AM",
        // dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "IMG World Overview",
            description: "one of the newest attractions for families and groups traveling to Dubai. Being the world’s largest indoor theme park, IMG World features over 1.5 million square feet of thrilling attractions and activities. It is the region’s first mega themed attraction, featuring diverse adventure zones like Marvel, Lost Valley, Cartoon Network, IMG Boulevard, and Novo cinemas.",
            },
            {
            title: "Dubai's Indoor Theme Parks Highlights",
            description: "IMG Worlds of Adventure is Dubai’s first amusement park with mega indoor entertainment destinations. Heptavision Tourism Offers visit to the Biggest Temperature Controlled Park, Spare a day to Unlimited access to the Mesmerizing rides and attractions like Avengers Battle of Ultron Hulk Epsilon Base 3DSpider – Man Doc Ock’s RevengeThor Thunder SpinAvengers Flight of the Quinjets LOST VALLEY Territory Predator Dino CARTOON NETWORK: The Powerpuff Girls – Mojo Jojo’s Rampage! Ben 10 5D Hero Time – The BOULEVARD: The Haunted Hotel",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "IMG World Included",
                        list:[
                            {   
                                listItem: "Entry to the IMG World Of Adventures.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Entry ticket to one of the world’s biggest indoor theme parks",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "See live entertainment shows",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "IMG World Timings",
                        list:[
                            {   
                                listItem: "Duration: 9 Hours",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Departure Point: Hotel/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Reporting Point: Hotel Lobby/Residence",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Cancellation: 24Hrs Prior",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Cancellation Policy",
                        list:[
                            {   
                                listItem: "All Cancellations made 24 hours prior to the Tour departure time NO charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If Cancellation made within 24 hours to your Tour departure time 100% charges will be applicable.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "If eligible for Refund your Amount will be returned back to your Account within 7 working days.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            },
            {
                item:[
                    {
                        title: "Child Policy",
                        list:[
                            {   
                                listItem: "Children below 3 years and under 1.05 meters height will be considered as infant and entry will be free of cost.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "2 meters are considered children and a child rate is charged.",
                                icon: <DoneIcon />,
                            },
                            {
                                listItem: "Children over 1.2 meters tall are considered adults and the adult rate will be charged.",
                                icon: <DoneIcon />,
                            },
                        ]
                    }
                ]
            }
        ]
    },


    {
        id: "New Dubai City Tour",
        title: "New Dubai City Tour",
        // adultRate: "AED 89 (Per Person)",
        // childRate: "AED 79 (Per Person)",
        // duration: "4 Hours",
        // pickup: "09:30 - 10:00 AM",
        // dropoff: "01:30 - 02:00 PM",
        info: [
            {
            title: "",
            description: "",
            },
            {
            title: "",
            description: "",
            }
        ],
        checkList: [

            {
                item:[
                    {
                        title: "",
                        list:[
                            {   
                                listItem: "",
                                icon: "",
                            },
                            // {
                            //     listItem: "Entry ticket to one of the world’s biggest indoor theme parks",
                            //     icon: <DoneIcon />,
                            // },
                            // {
                            //     listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                            //     icon: <DoneIcon />,
                            // },
                            // {
                            //     listItem: "See live entertainment shows",
                            //     icon: <DoneIcon />,
                            // },
                            // {
                            //     listItem: "Unlimited access to 4 zones including the exclusive dinosaur zone.",
                            //     icon: <DoneIcon />,
                            // },
                        ]
                    }
                ]
            },
            // {
            //     item:[
            //         {
            //             title: "IMG World Timings",
            //             list:[
            //                 {   
            //                     listItem: "Duration: 9 Hours",
            //                     icon: <DoneIcon />,
            //                 },
            //                 {
            //                     listItem: "Departure Point: Hotel/Residence",
            //                     icon: <DoneIcon />,
            //                 },
            //                 {
            //                     listItem: "Reporting Point: Hotel Lobby/Residence",
            //                     icon: <DoneIcon />,
            //                 },
            //                 {
            //                     listItem: "Cancellation: 24Hrs Prior",
            //                     icon: <DoneIcon />,
            //                 },
            //             ]
            //         }
            //     ]
            // },
            // {
            //     item:[
            //         {
            //             title: "Cancellation Policy",
            //             list:[
            //                 {   
            //                     listItem: "All Cancellations made 24 hours prior to the Tour departure time NO charges will be applicable.",
            //                     icon: <DoneIcon />,
            //                 },
            //                 {
            //                     listItem: "If Cancellation made within 24 hours to your Tour departure time 100% charges will be applicable.",
            //                     icon: <DoneIcon />,
            //                 },
            //                 {
            //                     listItem: "If eligible for Refund your Amount will be returned back to your Account within 7 working days.",
            //                     icon: <DoneIcon />,
            //                 },
            //             ]
            //         }
            //     ]
            // },
            // {
            //     item:[
            //         {
            //             title: "Child Policy",
            //             list:[
            //                 {   
            //                     listItem: "Children below 3 years and under 1.05 meters height will be considered as infant and entry will be free of cost.",
            //                     icon: <DoneIcon />,
            //                 },
            //                 {
            //                     listItem: "2 meters are considered children and a child rate is charged.",
            //                     icon: <DoneIcon />,
            //                 },
            //                 {
            //                     listItem: "Children over 1.2 meters tall are considered adults and the adult rate will be charged.",
            //                     icon: <DoneIcon />,
            //                 },
            //             ]
            //         }
            //     ]
            // }
        ]
    },
]

export default TourInfoData;