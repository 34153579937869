import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Typography from "@mui/material/Typography";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import EmailIcon from "@mui/icons-material/Email";
import { Container } from "@mui/system";
const data = [
  {
    icon: <LocationOnIcon sx={{ fontSize: "5rem", color: "#EF7D26", p: 2 }} />,
    title: "Office Address",
    body: "Al Fahidi St-Al Souq Al Kabeer\n-Dubai - S26-Building No.R305,\nBehind Al Mussala Post Office,\nNear Dubai Museum-Bur Dubai. \n- Dubai",
  },
  {
    icon: <ContactPageIcon sx={{ fontSize: "5rem", color: "#EF7D26", p: 2 }} />,
    title: "Contact Info",
    body: `043271009  \n +971 52 981 9347 \n+971 4 327 1009`,
  },
  {
    icon: <EmailIcon sx={{ fontSize: "5rem", color: "#EF7D26", p: 2 }} />,
    title: "Email Address",
    body: "visa@heptavisiontours.com \n enquiry@heptavisiontours.com \ninfo@heptavisiontours.com",
  },
];
export default function Cards() {
  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row", "row"],
          gap: 4,
          justifyContent: "center",
          alignItems:"center",
          pt: 16,
          pb:[2,2,10,12]
        }}
      >
        {data.map((curelem) => {
          return (
            <Box
              sx={{
                bgcolor: "#fff",
                height:400,
                width: "fit-content",
                border: "1px solid #EF7D26",
                mb: 2,
                width: "16rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 2,
              }}
            >
              {curelem.icon}
              <Typography
                variant="h2"
                align="center"
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "600",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                  color: "#2c4058",
                  py: 1,
                }}
              >
                {curelem.title}
              </Typography>
              <pre>
              <Typography
                variant="h2"
                align="center"
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "400",
                  fontSize: "1rem",
                  lineHeight: "2rem",
                  color: "#2c4058",
                  py: 1,
                }}
              >
                {curelem.body}
              </Typography>
              </pre>
            </Box>
          );
        })}
      </Container>
    </>
  );
}
